import React, { Component } from 'react'
import { isFirefox } from "react-device-detect";
import { DragSource, DropTarget } from 'react-dnd'

import DragItem from './DragItem'


const targetSource = {
    drop(props, monitor, component) {
        return {
            name: props.name,
        }
    }
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
    }
}

export class DropTargetArea extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { connectDropTarget, hovered, containedItems, name, translate } = this.props

        return connectDropTarget(
            <div className={`drop-target ${hovered ? 'target-hovered' : ''} ${name} ${isFirefox ? 'is-firefox' : ''}`}>
                {
                    containedItems?.length ? (
                        containedItems.map((item, index) => {
                            return (
                                <>
                                    <DragItem
                                        canBeUsed={true}
                                        inDocflow={true}
                                        key={index}
                                        item={item}
                                        fields={this.props.companyWorkers}
                                        handleItemDrop={this.props.handleItemDrop}
                                        moveItem={this.props.moveItem}
                                        moveNewItem={this.props.moveNewItem}
                                        removeAllPseudoElements={this.props.removeAllPseudoElements}
                                        startDraggingDocflowElement={this.props.startDraggingDocflowElement}
                                        index={index}
                                        selectedFields={[]}
                                        listName={name}
                                        translate={translate}
                                        editItemClick={this.props.editItemClick}
                                        removeItemClick={this.props.removeItemClick}
                                    />
                                    {
                                        index !== containedItems?.length - 1 ? (
                                            <span className="docflow-arrow"></span>
                                        ) : null
                                    }
                                </>
                            )
                        })
                    ) : (
                        <div className="no-elements">
                            {translate('Przerzuć tutaj kolejne elementy Twojej ścieżki')}
                        </div>
                    )
                }
            </div>
        )
    }
}

export default DropTarget('item', targetSource, collect)(DropTargetArea);