import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import md5 from 'md5'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { CompanyActions } from '../../../actions/CompanyActions'
import { AlertActions } from '../../../actions/AlertActions'
import SubscriptionTransactionErrorPopup from '../../../components/SubscriptionTransactionErrorPopup'
import SubscriptionTransactionSuccessPopup from '../../../components/SubscriptionTransactionSuccessPopup'
import ChangeSubscriptionTyprPopup from '../../../components/ChangeSubscriptionTyprPopup'
import SettingsTabsBar from '../../../components/SettingsTabsBar'

import { socket } from "../../../App"
import espagoConfig from '../../../helpers/espagoConfig.json'
import arrayObjectIndexOf from '../../../helpers/indexOfObject';
import getFullErrorInfo from '../../../helpers/subscriptionTransactionHelpers'
import { emailRegex } from '../../../helpers/regexList'
import ReactTooltip from 'react-tooltip'

import '../../../css/SubscriptionPage.css'

const token = Buffer.from(`${espagoConfig.app_id}:${espagoConfig.password}`).toString('base64')
const ADDRESS_REGEX = /^[a-zA-Z0-9\s\/\-]{1,}, [0-9]{2}-[0-9]{3}[a-zA-Z0-9\s]{1,}/

export class Subscription extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCompaniesList: false,
            subscriptionPlan: '',
            totalCost: '0.00',
            employeeFee: '0.00',
            periodStart: moment().format('DD-MM-YYYY'),
            periodEnd: moment().format('DD-MM-YYYY'),
            subscriptionExpired: false,
            subscriptionExpireDate: moment().format('DD-MM-YYYY'),
            clientEmail: '',
            clientName: '',
            address: '',
            zipCode: '',
            city: '',
            countryCode: 'PL',
            termsCheckbox: false,
            showAfterPaymentThanks: false,
            showCancelSubPopup: false,
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("successCallbackFromIframe", this.successCallbackFromIframeHandle, false)
        window.addEventListener("errorCallbackFromIframe", this.errorCallbackFromIframeHandle, false)
        window.addEventListener("closeCallbackFromIframe", this.closeCallbackFromIframeHandle, false)
        //window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //ReactGA.pageview(window.location.pathname + window.location.search)

        const { userProjects, currentCompany, userCompanies } = this.props
        if (currentCompany && currentCompany.company) {
            this.calculateSubscriptionCosts()
            var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject')) || -1
            var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
            if (projectIndex > -1 && currentCompany.id !== userProjects[projectIndex].company_id) {
                var companyIndex = arrayObjectIndexOf(userCompanies, userProjects[projectIndex].company_id, 'company_id')
                console.log(companyIndex)
                if (companyIndex > -1) {
                    if (userCompanies[companyIndex].user_role === 'ADMIN') {
                        this.props.setCurrentCompany(userCompanies[companyIndex])
                        this.props.getCompanysubscriptionTransactions(userProjects[projectIndex].company_id)
                    }
                }
            } else {
                this.props.getCompanysubscriptionTransactions(currentCompany.company_id)
            }
        }

        //P24
        if (socket) {
            socket.on('companySubscriptionUpdate', this.hideAfterPaymentPopupSuccess)
        }

        if (socket) {
            socket.on('companySubscriptionTransactionFailed', this.hideAfterPaymentPopupError)
        }

        //ESPAGO
        if (socket) {
            socket.on('companySubscriptionTransactionStatusUpdate', this.handlePaymentStatusUpdate)
        }

        const espagoScript1 = document.createElement('script');
        espagoScript1.type = 'text/javascript';
        espagoScript1.src = "https://js.espago.com/espago-1.3.js"

        const espagoScript2 = document.createElement('script');
        espagoScript2.type = 'text/javascript'
        espagoScript2.async = true
        espagoScript2.setAttribute("data-id", "EspagoFrameScript")
        espagoScript2.setAttribute("data-key", espagoConfig.public_key)
        espagoScript2.setAttribute("data-live", "true")
        espagoScript2.src = "https://js.espago.com/iframe.js"
        espagoScript2.setAttribute("data-target", "espago_form")
        espagoScript2.setAttribute("data-success", "onSuccessCallback")
        espagoScript2.setAttribute("data-error", "onErrorCallback")
        espagoScript2.setAttribute("data-onclose", "onCloseCallback")
        espagoScript2.setAttribute("data-button", "Zapłać")

        const onSuccessCallback = document.createElement('script');
        onSuccessCallback.type = 'text/javascript'
        onSuccessCallback.innerHTML = "function onSuccessCallback(data){console.log(data);const event = new CustomEvent('successCallbackFromIframe', {detail: {token: data}}); window.dispatchEvent(event)}"
        const onErrorCallback = document.createElement('script');
        onErrorCallback.type = 'text/javascript'
        onErrorCallback.innerHTML = "function onErrorCallback(data){const event = new CustomEvent('errorCallbackFromIframe', {detail: {err: data}}); window.dispatchEvent(event)}"
        const onCloseCallback = document.createElement('script');
        onCloseCallback.type = 'text/javascript'
        onCloseCallback.innerHTML = "function onCloseCallback(){const event = new CustomEvent('closeCallbackFromIframe', {}); window.dispatchEvent(event)}"

        espagoScript1.onload = () => {
            let DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        }
        espagoScript2.onload = () => {
            let DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        }

        onSuccessCallback.onload = () => {
            let DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        }
        onErrorCallback.onload = () => {
            let DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        }
        onCloseCallback.onload = () => {
            let DOMContentLoaded_event = document.createEvent("Event");
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        }

        document.body.appendChild(espagoScript1)
        document.body.appendChild(espagoScript2)
        document.body.appendChild(onSuccessCallback)
        document.body.appendChild(onErrorCallback)
        document.body.appendChild(onCloseCallback)
    }

    componentDidUpdate(prevProps) {
        var { currentCompany } = this.props
        if (currentCompany && prevProps.currentCompany !== currentCompany) {
            this.props.getCompanysubscriptionTransactions(currentCompany.company_id)
            if (currentCompany.company) {
                this.calculateSubscriptionCosts()
            }
        }
    }

    componentWillUnmount() {
        //window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("successCallbackFromIframe", this.successCallbackFromIframeHandle, false)
        window.removeEventListener("errorCallbackFromIframe", this.errorCallbackFromIframeHandle, false)
        window.removeEventListener("closeCallbackFromIframe", this.closeCallbackFromIframeHandle, false)
        if (socket) {
            socket.off('companySubscriptionUpdate', this.hideAfterPaymentPopupSuccess)
        }

        if (socket) {
            socket.off('companySubscriptionTransactionFailed', this.hideAfterPaymentPopupError)
        }

        if (socket) {
            socket.off('companySubscriptionTransactionStatusUpdate', this.handlePaymentStatusUpdate)
        }
    }

    calculateSubscriptionCosts = () => {
        const { currentCompany } = this.props
        if (currentCompany.company) {
            var subTypes = ['FREE', 'SOLO', 'BASIC', 'PRO', 'PRO+']
            var companyInstance = currentCompany.company
            var subscriptionType = 'ENTERPRISE'
            if (companyInstance.subscription_type === 'TRIAL') subscriptionType = companyInstance.next_subscription_type
            else if (subTypes.includes(companyInstance.subscription_type)) subscriptionType = companyInstance.subscription_type
            else subscriptionType = companyInstance.subscription_type

            var employeeFee = 50, totalCost = 0
            var periodStart = moment().format('DD-MM-YYYY'), periodEnd = moment().add(1, 'month').format('DD-MM-YYYY'), subscriptionExpireDate
            var additionalEmployeesFee = 0, hasAdditionalEmployees = false

            if (companyInstance.subscription_expire_date && moment(companyInstance.subscription_expire_date) > moment()) {
                periodStart = moment(companyInstance.subscription_expire_date).utc().add(1, 'days').format('DD-MM-YYYY')
                periodEnd = moment(companyInstance.subscription_expire_date).utc().add(1, 'month').format('DD-MM-YYYY')
                subscriptionExpireDate = moment(companyInstance.subscription_expire_date).utc().format('DD-MM-YYYY')
            } else {
                this.setState({
                    subscriptionExpired: true,
                })
                subscriptionExpireDate = moment(companyInstance.subscription_expire_date).utc().format('DD-MM-YYYY')
            }

            if (subscriptionType === 'ENTERPRISE') {
                totalCost = companyInstance.members_count * 50

                if (companyInstance.discount) {
                    totalCost = totalCost * (100 - companyInstance.discount) / 100
                    employeeFee = employeeFee * (100 - companyInstance.discount) / 100
                }
            } else if (subscriptionType === 'FREE') {
                totalCost = 0

                if (companyInstance.members_count > 1) {
                    additionalEmployeesFee = (companyInstance.members_count - 3) * 49
                    hasAdditionalEmployees = true
                    totalCost += additionalEmployeesFee
                }
            } else if (subscriptionType === 'SOLO') {
                totalCost = 69

                if (companyInstance.members_count > 1) {
                    additionalEmployeesFee = (companyInstance.members_count - 3) * 49
                    hasAdditionalEmployees = true
                    totalCost += additionalEmployeesFee
                }
            } else if (subscriptionType === 'BASIC') {
                totalCost = 199

                if (companyInstance.members_count > 3) {
                    additionalEmployeesFee = (companyInstance.members_count - 3) * 49
                    hasAdditionalEmployees = true
                    totalCost += additionalEmployeesFee
                }
            } else if (subscriptionType === 'PRO') {
                totalCost = 299

                if (companyInstance.members_count > 5) {
                    additionalEmployeesFee = (companyInstance.members_count - 5) * 49
                    hasAdditionalEmployees = true
                    totalCost += additionalEmployeesFee
                }
            } else if (subscriptionType === 'PRO+') {
                totalCost = 599

                if (companyInstance.members_count > 10) {
                    additionalEmployeesFee = (companyInstance.members_count - 10) * 49
                    hasAdditionalEmployees = true
                    totalCost += additionalEmployeesFee
                }
            } else if (['ENTERPRISE-50', 'SOLO(k)', 'BASIC(k)', 'PRO(k)', 'PRO+(k)'].includes(subscriptionType)) {
                totalCost = 49
                employeeFee = 0
            }

            this.setState({
                totalCost: totalCost.toFixed(2),
                employeeFee: employeeFee.toFixed(2),
                hasAdditionalEmployees: hasAdditionalEmployees,
                additionalEmployeesFee: additionalEmployeesFee,
                subscriptionType: subscriptionType,
                subscriptionExpireDate: subscriptionExpireDate,
                periodStart: periodStart,
                periodEnd: periodEnd,
                sessionId: localStorage.getItem('token').substring(0, 30) + moment().format('DDMMYYYYHHmmssSSS'),
                txDescription: `Abonament ${subscriptionType} na EasyDocs w ${companyInstance.code} za okres od ${moment(periodStart, 'DD-MM-YYYY').format('DD.MM.YYYY')} do ${moment(periodEnd, 'DD-MM-YYYY').format('DD.MM.YYYY')}`
            })
        }
    }

    successCallbackFromIframeHandle = (e) => {
        console.log('text from component')
        console.log('success from iframe')
        console.log(e)
        console.log(e.detail)

        const { periodStart, periodEnd, totalCost, employeeFee, clientEmail, clientName, address, zipCode, city, countryCode, txDescription, termsCheckbox, sessionId, subscriptionType } = this.state
        const { currentCompany, user } = this.props
        var amountToPay = parseFloat(totalCost) * 1.23
        const token = Buffer.from(`${espagoConfig.app_id}:${espagoConfig.password}`).toString('base64')

        var requestOptions = {}
        requestOptions.method = 'post'
        requestOptions.body = JSON.stringify({
            description: txDescription,
            amount: parseFloat(amountToPay.toFixed(2)),
            currency: "PLN",
            card: e.detail.token,
            channel: "elavon_cc",
            positive_url: `https://app.easydocs.pl/dashboard/subscription-success/${currentCompany.company_id}`,
            negative_url: `https://app.easydocs.pl/dashboard/subscription-failure/${currentCompany.company_id}`,
            reference_number: user.id + '_' + currentCompany.company_id + '_' + moment().format('YY-MM-DD'),
            locale: localStorage.getItem("language"),
            email: clientEmail,
            cof: "storing",
            recurring_freq: 30,
            authentication_ind: "02"
        })
        requestOptions.headers = {
            'Authorization': `Basic ${token}`,
            'Accept': 'application/vnd.espago.v3+json',
            'Content-Type': 'application/json',
        }

        var additionalInfo = {
            periodStart: periodStart,
            periodEnd: periodEnd,
            package: currentCompany.company.members_limit > 1 ? 'MSP' : 'JDG',
            employeeFee: employeeFee,
            usersCount: currentCompany.company.members_count,
            clientName: clientName,
            address: address,
            zipCode: zipCode,
            city: city,
            countryCode: countryCode,
            netValue: parseFloat(totalCost)
        }

        this.setState({
            showPaymentForm: false,
            showPaymentProcessingPopup: true
        })
        this.props.makeEspagoPayment(requestOptions, currentCompany.company_id, additionalInfo, (finishedPayment, paymentStatus, errorInfo, transactionId) => {
            if (finishedPayment) {
                if (paymentStatus === 'executed') {
                    this.setState({
                        showPaymentForm: false,
                        showPaymentProcessingPopup: false,
                        showAfterPaymentThanks: true,
                        subscriptionEndDate: moment(periodEnd, 'DD-MM-YYYY')
                    })


                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'purchase',
                        ecommerce: {
                            customerBillingEmail: clientEmail,
                            customerBillingFirstName: user.first_name,
                            customerBillingLastName: user.last_name,
                            currency: 'PLN',
                            value: parseFloat(totalCost),
                            tax: parseFloat(totalCost) * 0.23,
                            transaction_id: transactionId,
                            items: [{
                                item_id: subscriptionType === 'BASIC' ? "Varian_2" : subscriptionType === 'PRO' ? 'Varian_3' : subscriptionType === 'PRO+' ? 'Varian_4' : subscriptionType === 'SOLO' ? 'Varian_1' : 'Varian_5',
                                item_name: subscriptionType,
                                coupon: "Poleć Znajomemu",
                                currency: "PLN",
                                discount: 0,
                                price: parseFloat(totalCost),
                                quantity: 1
                            }]
                        }
                    });
                } else {
                    this.setState({
                        showPaymentForm: false,
                        showPaymentProcessingPopup: false,
                        showAfterPaymentError: true,
                        paymentError: errorInfo
                    })
                }
            } else {
                this.setState({
                    paymentRequiresAdditionalConfirmation: true
                })
            }
        })
    }

    errorCallbackFromIframeHandle = (e) => {
        console.log('text from component')
        console.log('error from iframe')
        console.log(e)
        console.log(e.detail)
    }

    closeCallbackFromIframeHandle = () => {
        console.log('text from component')
        console.log('you closses iframe')
    }

    handlePaymentStatusUpdate = data => {
        console.log('got socket notification: companySubscriptionTransactionStatusUpdate')
        console.log(data)

        if (data.status === 'executed') {
            this.setState({
                showPaymentForm: false,
                showPaymentProcessingPopup: false,
                showAfterPaymentThanks: true,
                subscriptionEndDate: data.subscriptionEndDate
            })

            const { totalCost, subscriptionType } = this.state
            const { user } = this.props

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    customerBillingEmail: user.email,
                    customerBillingFirstName: user.first_name,
                    customerBillingLastName: user.last_name,
                    currency: 'PLN',
                    value: parseFloat(totalCost),
                    tax: parseFloat(totalCost) * 0.23,
                    transaction_id: data.transactionId,
                    items: [{
                        item_id: subscriptionType === 'BASIC' ? "Varian_2" : subscriptionType === 'PRO' ? 'Varian_3' : subscriptionType === 'PRO+' ? 'Varian_4' : subscriptionType === 'SOLO' ? 'Varian_1' : 'Varian_5',
                        item_name: subscriptionType,
                        coupon: "Poleć Znajomemu",
                        currency: "PLN",
                        discount: 0,
                        price: parseFloat(totalCost),
                        quantity: 1
                    }]
                }
            });
        } else {
            this.setState({
                showPaymentForm: false,
                showPaymentProcessingPopup: false,
                showAfterPaymentError: true,
                paymentError: { responseCode: data.responseCode }
            })
        }

        this.props.getCompanysubscriptionTransactions(this.props.currentCompany.company_id)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showCompaniesList) {
                this.setState({
                    showCompaniesList: false
                })
            } else if (this.state.showPaymentForm && this.refs.paymentFormCloseButtonRef && this.refs.paymentFormCloseButtonRef.style.display !== 'none') {
                this.setState({
                    showPaymentForm: false
                })
            } else if (this.state.showChangeCardPopup) {
                this.setState({
                    showChangeCardPopup: false
                })
            } else if (this.state.showCancelSubPopup) {
                this.setState({
                    showCancelSubPopup: false
                })
            } else if (this.state.showAfterPaymentThanks || this.state.showAfterPaymentError) {
                this.hideAfterPaymentPopup()
            } else if (this.state.showChangeSubscriptionTypePopup) {
                this.setState({
                    showChangeSubscriptionTypePopup: false
                })
            }
        }
    }

    onInputChange = e => {
        e.preventDefault()
        var { name, value } = e.target

        if (name === 'cardNumber') {
            var onlyDigits = value.replace(/[^0-9]/gm, '')
            value = `${onlyDigits.slice(0, 4)} ${onlyDigits.slice(4, 8)} ${onlyDigits.slice(8, 12)} ${onlyDigits.slice(12, 16)}`.trim()
        } else if (name === 'cardDate') {
            var { cardDate } = this.state
            if (cardDate.length < value.length) {
                var onlyDigits = value.replace(/[^0-9]/gm, '')
                value = `${onlyDigits.slice(0, 2)}/${onlyDigits.slice(2, 6)}`
                if (value.substr(value.length - 1) === '/' && value.length < 3) {
                    value = value.slice(0, -1)
                }
            }
        }

        this.setState({ [name]: value })
    }

    toggleCompaniesList = () => {
        this.setState({
            showCompaniesList: !this.state.showCompaniesList
        })
    }

    changeCurrentCompany = (e, index) => {
        e.preventDefault()
        console.log(index)

        this.props.setCurrentCompany(this.props.userCompanies[index])

        this.setState({
            currentProject: {},
            showCompaniesList: false,
        })
    }

    closeAllSugestionLists = (e) => {
        if (this.state.showCompaniesList && !this.hasAncestor(e.target, "companySwitcher")) {
            this.setState({
                showCompaniesList: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    generateSign = objectToSign => {
        //PRZELEWY 24 START
        // var sign = new jsSHA("SHA-384", 'TEXT', { encoding: "UTF8" })
        // objectToSign.crc = p24Config.crc
        // sign.update(JSON.stringify(objectToSign))

        // return sign.getHash('HEX')
        //PRZELEWY 24 END

        //ESPAGO START
        var sign = md5(`${espagoConfig.app_id}|sale|${objectToSign.session_id}|${objectToSign.amount}|${objectToSign.currency}|${espagoConfig.checksum_key}`)
        console.log(sign)
        return sign
        //ESPAGO END
    }

    handlePayClick = (subscriptionType, hasPaidFirstSubscription) => {
        if (!hasPaidFirstSubscription) {
            this.setState({
                showPaymentForm: true
            })
            var clientEmail, clientName, address, zipCode, city, companyName, companyVatId
            const { user, currentCompany } = this.props
            clientEmail = user.email
            clientName = user.first_name + ' ' + user.last_name

            if (currentCompany.company) {
                companyName = currentCompany.company.name
                companyVatId = currentCompany.company.vat_id
            }

            if (currentCompany.company.address && ADDRESS_REGEX.test(currentCompany.company.address)) {
                console.log('here')
                var addressElements = currentCompany.company.address.split(',')
                if (addressElements.length === 2) {
                    address = addressElements[0]
                    zipCode = addressElements[1].match(/[0-9]{2}-[0-9]{3}/)
                    if (zipCode) {
                        zipCode = zipCode[0]
                    }
                    city = addressElements[1].replace(zipCode, '').trim()
                }
            }

            this.setState({
                clientEmail: clientEmail,
                clientName: clientName,
                address: address,
                zipCode: zipCode,
                city: city,
                companyName: companyName,
                companyVatId: companyVatId
            })

            // var amountToPay = parseInt(parseFloat(this.state.totalCost) * 1.23 * 100)
            // var sign = this.generateSign({sessionId: this.state.sessionId, ts: p24Config.merchantId, amount: amountToPay, currency: "PLN"})
            // this.setState({
            //     sign: sign
            // })
        } else {
            this.setState({
                showCancelSubPopup: true
            })
        }
        // } else {
        //     const token = Buffer.from(`${p24Config.merchantId}:${p24Config.apiKey}`).toString('base64')

        //     var requestOptions  = {}
        //     requestOptions.method = 'get'
        //     requestOptions.headers = {
        //         Authorization: `Basic ${token}`,
        //         Accept: "application/json"
        //     }

        //     console.log(requestOptions.headers)

        //     fetch(`https://sandbox.przelewy24.pl/api/v1/card/info/306008305`, requestOptions)
        //     .then(response => response.json()).then(res => {
        //         console.log(res)
        //         // if(response && response.responseCode === 0) {
        //         //     return {success: true, res: response.data}
        //         // } else {
        //         //     return {success: false}
        //         // }
        //     }).catch(err => {
        //         console.log("error when verifieing transaction")
        //         console.log(err)

        //         // return {success: false}
        //     })
        // }
    }

    payWithNewCard = () => {
        this.setState({
            showChangeCardPopup: false,
            showPaymentForm: true
        })
        var clientEmail, clientName, address, zipCode, city, companyName, companyVatId
        const { user, currentCompany } = this.props
        clientEmail = user.email
        clientName = user.first_name + ' ' + user.last_name

        if (currentCompany.company) {
            console.log(currentCompany.company)
            companyName = currentCompany.company.name
            companyVatId = currentCompany.company.vat_id
        }

        if (currentCompany.company.address && ADDRESS_REGEX.test(currentCompany.company.address)) {
            console.log('here')
            var addressElements = currentCompany.company.address.split(',')
            if (addressElements.length === 2) {
                address = addressElements[0]
                zipCode = addressElements[1].match(/[0-9]{2}-[0-9]{3}/)
                if (zipCode) {
                    zipCode = zipCode[0]
                }
                city = addressElements[1].replace(zipCode, '').trim()
            }
        }

        this.setState({
            clientEmail: clientEmail,
            clientName: clientName,
            address: address,
            zipCode: zipCode,
            city: city,
            companyName: companyName,
            companyVatId: companyVatId
        })

        // var amountToPay = parseInt(parseFloat(this.state.totalCost) * 1.23 * 100)
        // var sign = this.generateSign({sessionId: this.state.sessionId, merchantId: p24Config.merchantId, amount: amountToPay, currency: "PLN"})
        // this.setState({
        //     sign: sign
        // })
    }

    submitPayment = (e) => {
        e.preventDefault()
        const { periodStart, periodEnd, totalCost, clientEmail, clientName, address, zipCode, city, countryCode, txDescription, termsCheckbox, sessionId, sign } = this.state
        const { currentCompany } = this.props
        var amountToPay = parseInt(parseFloat(totalCost) * 1.23 * 100)

        var valid = true
        if (!termsCheckbox) {
            this.props.alertWarn("You have to accept terms of service.")
            valid = false
        }

        if (!address) {
            this.props.alertWarn("Street is required.")
            valid = false
        }

        if (!zipCode) {
            this.props.alertWarn("Zip code is required.")
            valid = false
        }

        if (!city) {
            this.props.alertWarn("City is required.")
            valid = false
        }

        if (!clientEmail) {
            this.props.alertWarn(this.context.t('E-mail address is required'))
            valid = false
        } else if (!emailRegex.test(clientEmail)) {
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
            valid = false
        }

        if (!clientName) {
            this.props.alertWarn(this.context.t('First and last name is required'))
            valid = false
        }

        if (valid) {
            //----------------------------------------
            //PRZELEWY 24 START
            // var requestOptions = {}
            // requestOptions.method = 'post'
            // requestOptions.body = JSON.stringify({
            //     merchantId: p24Config.merchantId,
            //     posId: p24Config.posId,
            //     sessionId: sessionId,
            //     amount: amountToPay,
            //     currency: "PLN",
            //     description: txDescription,
            //     email: clientEmail,
            //     client: clientName,
            //     address: address,
            //     zip: zipCode,
            //     city: city,
            //     country: countryCode,
            //     language: "pl",
            //     urlReturn: "https://app.easydocs.pl/dashboard/subscription",
            //     urlStatus: "https://api.app.easydocs.pl/api/subscription-fees/verify",
            //     urlCardPaymentNotification: "https://api.app.easydocs.pl/api/subscription-fees/card-payment-notification",
            //     timeLimit: 10,
            //     channel: 1,
            //     waitForResult: false,
            //     regulationAccept: false,
            //     shipping: 0,
            //     sign: sign,
            //     encoding: "UTF-8",
            // })
            // requestOptions.headers = {
            //     'Authorization': `Basic ${token}`,
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // }

            // this.props.registerSubscriptionPayment({
            //     sessionId: sessionId,
            //     merchantId: p24Config.merchantId,
            //     amount: amountToPay,
            //     currency: 'PLN',
            //     crc: p24Config.crc,
            //     companyId: currentCompany.company_id,
            //     description: txDescription,
            //     email: clientEmail,
            //     client: clientName,
            //     sign: sign,
            //     periodStart: periodStart,
            //     periodEnd: periodEnd,
            //     package: currentCompany.company && currentCompany.company.members_limit > 1 ? 'MSP' : 'SOLO',
            //     packagePrice: parseFloat(totalCost),
            //     usersCount: currentCompany.company ? currentCompany.company.members_count : 0,
            //     usersFee: parseFloat(employeeFee),
            //     extraStorage: 0,
            //     netValue: parseFloat(totalCost),
            //     address: address,
            //     zipCode: zipCode,
            //     city: city,
            //     country: countryCode
            // })

            // fetch('https://sandbox.przelewy24.pl/api/v1/transaction/register', requestOptions)
            // .then(response => response.json()).then(res => {
            //     console.log(res);

            //     if(this.refs.paymentPopupCardRef && this.refs.paymentFormCloseButtonRef && this.refs.paymentFormRef && this.refs.creditCardFormRef) {
            //         this.refs.paymentFormCloseButtonRef.style.display = 'none'
            //         this.refs.paymentFormRef.style.display = 'none'
            //         this.refs.creditCardFormRef.style.display = 'block'

            //         // var redirect = document.createElement('a')
            //         // redirect.href = `https://sandbox-go.przelewy24.pl/trnRequest/${res.data.token}`
            //         // redirect.target = '_blank'
            //         // redirect.click()

            //         this.setState({
            //             transactionToken: res.data.token,
            //             redirectURL: `https://sandbox-go.przelewy24.pl/trnRequest/${res.data.token}`
            //         })

            //         // if(this.refs.afterPaymentContainerRef) {
            //         //     this.refs.afterPaymentContainerRef.style.display = 'block'
            //         // }

            //         const script = document.createElement("script");
            //         script.type = 'text/javascript';
            //         script.setAttribute('id', 'p24');
            //         script.async = true
            //         script.src = `https://sandbox.przelewy24.pl/inchtml/ajaxPayment/ajax.js?token=${res.data.token}`;

            //         script.onload = () => {
            //             let DOMContentLoaded_event = document.createEvent("Event");
            //             DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            //             window.document.dispatchEvent(DOMContentLoaded_event);
            //         }

            //         document.body.appendChild(script)
            //     }
            // })
            // .catch((error) => {
            //     console.log(error)
            // });
            //PRZELEWY 24 END
            //----------------------------------------

            //----------------------------------------
            //ESPAGO START
            window.showEspagoFrame()
            //ESPAGO END
            //----------------------------------------
        }
    }

    cancelSubscription = () => {
        this.props.cancelSubscription(this.props.currentCompany.company_id)
        this.setState({
            showCancelSubPopup: false
        })
    }

    // chargeCard = (e) => {
    //     e.preventDefault()
    //     const {cardHolder, cardNumber, cardDate, cardCVV, transactionToken} = this.state
    //     var valid = true

    //     if(!cardHolder) {
    //         this.props.alertWarn("Card holder is required.")
    //         valid = false
    //     }

    //     if(!cardNumber) {
    //         this.props.alertWarn("Card number is required.")
    //         valid = false
    //     } else {
    //         var cardNumberOnlyDigits = cardNumber.replace(/[^0-9]/gm, '')
    //         if(cardNumberOnlyDigits.length !== 16) {
    //             this.props.alertWarn("Card number is not valid. Correct number should have 16 digits.")
    //             valid = false
    //         }
    //     }

    //     if(!cardDate) {
    //         this.props.alertWarn("Card expiry date is required.")
    //         valid = false
    //     } else {
    //         if(!/^[0-9]{2}\/[0-9]{4}$/.test(cardDate)) {
    //             this.props.alertWarn("Card expiry date is not valid. The date should be entered in the MM/YYYY format.")
    //             valid = false
    //         } else if(moment(cardDate, 'MM/YYYY').diff(moment(), 'months') < 0) {
    //             this.props.alertWarn("The card is not valid.")
    //         }
    //     }

    //     if(!cardCVV) {
    //         this.props.alertWarn("Card CVV code is required.")
    //         valid = false
    //     } else if(cardCVV.length !== 3) {
    //         this.props.alertWarn("Invalid CVV code. The code should consist of 3 digits.")
    //         valid = false
    //     }

    //     if(valid) {
    //         var requestOptions = {}
    //         requestOptions.method = 'post'
    //         requestOptions.body = JSON.stringify({
    //             transactionToken: transactionToken,
    //             cardNumber: cardNumber,
    //             cardDate: cardDate.replace('/', ''),
    //             cvv: cardCVV,
    //             clientName: cardHolder
    //         })
    //         requestOptions.headers = {
    //             'Authorization': `Basic ${token}`,
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         }

    //         fetch('https://sandbox.przelewy24.pl/api/v1/card/pay', requestOptions)
    //         .then(response => response.json()).then(res => {
    //             console.log(res);
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         });
    //     }
    // }

    hideAfterPaymentPopupSuccess = () => {
        this.setState({
            showPaymentForm: false,
            showAfterPaymentThanks: true
        })

        if (this.refs.paymentFormCloseButtonRef && this.refs.paymentFormRef) {
            this.refs.paymentFormCloseButtonRef.style.display = 'block'
            this.refs.paymentFormRef.style.display = 'block'
            if (this.refs.afterPaymentContainerRef) {
                this.refs.afterPaymentContainerRef.style.display = 'none'
            }
        }
    }

    hideAfterPaymentPopupError = () => {
        this.setState({
            showPaymentForm: false,
            showAfterPaymentError: true
        })

        if (this.refs.paymentFormCloseButtonRef && this.refs.paymentFormRef) {
            this.refs.paymentFormCloseButtonRef.style.display = 'block'
            this.refs.paymentFormRef.style.display = 'block'
            if (this.refs.afterPaymentContainerRef) {
                this.refs.afterPaymentContainerRef.style.display = 'none'
            }
        }
    }

    hideAfterPaymentPopup = () => {
        this.setState({
            showAfterPaymentThanks: false,
            showAfterPaymentError: false
        })
    }

    getransactionsSortClass = (sortFieldCheck) => {
        const { companySubscriptionTransactionsSortField, companySubscriptionTransactionsSortOrder } = this.props

        if (companySubscriptionTransactionsSortField === sortFieldCheck) {
            if (companySubscriptionTransactionsSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    changePaymentCard = () => {
        const { companySubscriptionTransactions } = this.props

        this.setState({
            showChangeCardPopup: true
        })

        var lastPayment = companySubscriptionTransactions.slice(-1).pop()

        if (lastPayment) {
            var requestOptions = {}
            requestOptions.method = 'get'
            requestOptions.headers = {
                Authorization: `Basic ${token}`,
                Accept: "application/json"
            }
            fetch(`https://sandbox.przelewy24.pl/api/v1/card/info/${lastPayment.order_id}`, requestOptions)
                .then(response => response.json()).then(res => {
                    console.log(res);
                    if (res && res.responseCode === 0) {
                        this.setState({
                            lastCardNumber: res.data.mask.replace(/x/mg, '*')
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    render() {
        const { showCompaniesList, totalCost, employeeFee, hasAdditionalEmployees, additionalEmployeesFee, subscriptionType, periodStart, periodEnd, showPaymentForm, clientEmail, clientName, address, zipCode, city, countryCode, txDescription, termsCheckbox, companyName, companyVatId, sign, redirectURL, showPaymentProcessingPopup, showAfterPaymentThanks, subscriptionEndDate, showAfterPaymentError, paymentError, subscriptionExpireDate, subscriptionExpired, showCancelSubPopup, showChangeCardPopup, lastCardNumber, showChangeSubscriptionTypePopup } = this.state
        const { currentCompany, userCompanies, companySubscriptionTransactions, sendCompanySubscriptionTransactionsRequest, user, tabIsActive } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="subscription-container" onClick={e => this.closeAllSugestionLists(e)}>
                    <SettingsTabsBar />

                    {
                        showPaymentForm ? (
                            <div className="payment-form popup">
                                <div className="card" ref="paymentPopupCardRef">
                                    <div id="closeButton" className="close" ref="paymentFormCloseButtonRef" onClick={() => this.setState({ showPaymentForm: false })}></div>
                                    <form id="paymentForm" ref="paymentFormRef" onSubmit={this.submitPayment}>
                                        <h2>{this.context.t('Details of the transaction')}</h2>
                                        <div className='form-group'>
                                            <input type="text" name="txDescription" id="txDescription" readOnly={true} value={txDescription} placeholder="" autoComplete='off' />
                                            <label htmlFor="txDescription">{this.context.t('Description')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="amountToPay" id="amountToPay" readOnly={true} value={(parseFloat(totalCost) * 1.23).toFixed(2)} placeholder="" autoComplete='off' />
                                            <label htmlFor="amountToPay">{this.context.t('Amount to pay')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="currency" id="currency" readOnly={true} value={'PLN'} placeholder="" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('Currency')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="clientEmail" id="clientEmail" value={clientEmail} placeholder="" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('Email')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="clientName" id="clientName" value={clientName} placeholder="" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('Name and Surname')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="companyName" id="companyName" value={companyName} placeholder="" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="companyName">{this.context.t('Company')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="companyVatId" id="companyVatId" value={companyVatId} placeholder="" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="companyVatId">{this.context.t('VAT-ID')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="city" id="city" value={city} placeholder="Miasto" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('City')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="address" id="address" value={address} placeholder="Przykładowa ulica" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('Street')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="zipCode" id="zipCode" value={zipCode} placeholder="00-000" autoComplete='off' onChange={e => this.onInputChange(e)} />
                                            <label htmlFor="currency">{this.context.t('Zip code')}</label>
                                        </div>
                                        <div className='form-group half'>
                                            <input type="text" name="countryCode" id="countryCode" value={countryCode} readOnly={true} placeholder="" autoComplete='off' />
                                            <label htmlFor="currency">{this.context.t('Country')}</label>
                                        </div>

                                        <div className="agree-to-terms first">
                                            <span className={`checkbox ${termsCheckbox ? 'checked' : ''}`} onClick={() => this.setState({ termsCheckbox: !termsCheckbox })}></span>
                                            <p onClick={() => this.setState({ termsCheckbox: !termsCheckbox })}>
                                                {this.context.t('I accept the ')}<a href="https://easydocs.pl/regulamin" target="_blank">{this.context.t('Terms of Service')}</a>
                                            </p>
                                        </div>

                                        <div className="required-fields">
                                            {this.context.t('* required field')}
                                        </div>

                                        <input type="submit" className={`${!termsCheckbox ? 'inactive' : ''}`} value={this.context.t('I order with an obligation to pay')} />
                                    </form>

                                    <form id="espago_form" ref="espageHiddenFormRef" className="hidden-form"
                                        onSubmit={this.afterIframeAction}
                                    >
                                    </form>

                                    <div className="credit-card-form" ref="creditCardFormRef">
                                        <div className="info">
                                            <h3>{this.context.t('Payments are handled by Espago, by making the payment, you accept ')}
                                                <a href="https://espago.com/files/Regulamin_PSP_Polska_dla_Merchantow_2018.pdf" target="_blank">{this.context.t('the terms and conditions of Espago')}</a>.</h3>
                                            <p>{this.context.t('Subsequent fees will be charged automatically every 30 days from the payment card whitch you will use now.')}</p>
                                            <p className="redirect">{this.context.t('In case of problems with the payment, you can make it directly via Espago by clicking ')}<a href={redirectURL} target="_blank">{this.context.t('here')}</a></p>
                                        </div>
                                        <div className="form">
                                            <div
                                                id="P24FormContainer"
                                                data-sign={sign}
                                                data-successCallback="p24Success"
                                                data-failureCallback="p24Failed"
                                                data-dictionary={
                                                    JSON.stringify({
                                                        cardHolderLabel: this.context.t('Card holder full name'),
                                                        cardNumberLabel: this.context.t('Card number'),
                                                        cvvLabel: this.context.t('CVV code'),
                                                        expDateLabel: this.context.t('Card expiry date'),
                                                        payButtonCaption: this.context.t('Pay')
                                                    })
                                                }
                                            >
                                                <h3>{this.context.t('Payment card details')}</h3>
                                                <div className="disclaimer">
                                                    {this.context.t('The card details are not known to EasyDocs nor are they stored in EasyDocs - it is a direct connection to Espago.')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="after-payment" ref="afterPaymentContainerRef">
                                        <p>{this.context.t('Complete the payment process in the new tab of the Espago website')}</p>
                                        <p className="smaller">{this.context.t('If the tab does not open, click ')}<a href={redirectURL} target="_blank">{this.context.t('here')}</a>{this.context.t(' to finish the payment')}</p>

                                        {/* <div className="finish-button" onClick={() => {this.finishTransaction()}}>{this.context.t('Done')}</div> */}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showAfterPaymentThanks ? (
                            <SubscriptionTransactionSuccessPopup hideAfterPaymentPopup={this.hideAfterPaymentPopup} subscriptionEndDate={subscriptionEndDate} />
                        ) : (
                            null
                        )
                    }

                    {
                        showAfterPaymentError ? (
                            <SubscriptionTransactionErrorPopup paymentError={paymentError} hideAfterPaymentPopup={this.hideAfterPaymentPopup} />
                        ) : (
                            null
                        )
                    }

                    {
                        showPaymentProcessingPopup ? (
                            <div className="payment-waiting popup">
                                <div className="card">
                                    {this.context.t('We are waiting for your payment to be processed by Espago')}
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showCancelSubPopup ? (
                            <div className="sub-calncel popup">
                                <div className="card">
                                    <div className="header">{this.context.t('Are you sure you want to cancel EasyDocs subscription for ')}{currentCompany.company.code}?</div>
                                    <div className="text">{this.context.t('You will be able to use EasyDocs until ')}{subscriptionExpireDate}{this.context.t(' at 23:59:59 Central European Time.')}{this.context.t(' Later, you and your employees will not be able to use EasyDocs services.')}</div>
                                    <div className="action-buttons">
                                        <div className="cancel-button" onClick={() => { this.setState({ showCancelSubPopup: false }) }}>{this.context.t('Close')}</div>
                                        <div className="confirm-button" onClick={() => { this.cancelSubscription() }}>{this.context.t('Cancel subscription')}</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showChangeCardPopup ? (
                            <div className="change-card-popup popup">
                                <div className="card">
                                    <div id="closeButton" className="close" onClick={() => this.setState({ showChangeCardPopup: false })}></div>
                                    <div className="info">
                                        <p>{this.context.t('The card used to make the last payment had the number: ')}{lastCardNumber}</p>
                                        <p>{this.context.t('To change the card from which automatic payments are collected, you need to make a new payment in EasyDocs')}</p>

                                        <div className="pay-button" onClick={() => this.payWithNewCard()}>{this.context.t('Pay for the subscription with a new card')}</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showChangeSubscriptionTypePopup ? (
                            <ChangeSubscriptionTyprPopup currentPlan={subscriptionType} companyId={currentCompany.company_id} close={() => this.setState({ showChangeSubscriptionTypePopup: false })} />
                        ) : (
                            null
                        )
                    }

                    <div className="payment-info-container">
                        <div className="company-switcher" ref="companySelectionRef" id="companySwitcher">
                            <div className="current" onClick={() => this.toggleCompaniesList()}>
                                <h2>{currentCompany && currentCompany.company ? currentCompany.company.code : ''}</h2>
                                <span className={`expend-icon ${showCompaniesList ? 'expended' : ''}`}></span>
                            </div>
                            {
                                showCompaniesList ? (
                                    <div className="companies-list">
                                        <div className="header">
                                            {this.context.t('Choose company')}
                                        </div>
                                        <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                                            <ul>
                                                {
                                                    userCompanies.map((company, i) => {
                                                        if (company.user_role === 'ADMIN') {
                                                            return (
                                                                <li className={`${currentCompany.id === company.id ? 'current-selected' : ''}`} onClick={e => this.changeCurrentCompany(e, i)}>
                                                                    {company.company.code}
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                        {
                            currentCompany && currentCompany.company && !currentCompany.company.has_paid_subscription ? (
                                <div className="period-info-row">
                                    {
                                        subscriptionType !== 'FREE' ? (
                                            <>
                                                {
                                                    subscriptionExpired ? (
                                                        <p>{this.context.t('Your EasyDocs test period has ended on ')}{subscriptionExpireDate}{this.context.t(' at 23:59:59 Central European Time.')}</p>
                                                    ) : (
                                                        <p>{this.context.t('Your EasyDocs test period is ending on ')}{subscriptionExpireDate}{this.context.t(' at 23:59:59 Central European Time.')}</p>
                                                    )
                                                }
                                                <p>{this.context.t('To be able to continue using the system, make the first payment with a payment card in accordance with the table below.')}</p>
                                            </>
                                        ) : null
                                    }

                                    <p>{this.context.t('Your current package is: {n}. You can read more about the packages at ', { n: subscriptionType })}<a href="https://easydocs.pl/cennik" target="_blank" className="pricing-link">https://easydocs.pl/cennik</a> </p>
                                </div>
                            ) : (
                                <div className="period-info-row">
                                    {
                                        subscriptionType !== 'FREE' ? (
                                            <>
                                                {
                                                    subscriptionExpired ? (
                                                        <>
                                                            <p>{this.context.t('Your subscription to EasyDocs was active until ')}{subscriptionExpireDate}{this.context.t(' 23:59:59 Central European Time.')}</p>
                                                            <p>{this.context.t('To be able to use EasyDocs again, make the payment with a payment card in accordance with the table below.')}</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>{this.context.t('Your subscription to EasyDocs is active until ')}{subscriptionExpireDate}{this.context.t(' 23:59:59 Central European Time.')}</p>
                                                            <p>{this.context.t('The fee for the next period will be automatically charged to the payment card with which the first payment was made.')}</p>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : null
                                    }

                                    <p>{this.context.t('Your current package is: {n}. You can read more about the packages at ', { n: subscriptionType })}<a href="https://easydocs.pl/cennik" target="_blank" className="pricing-link">https://easydocs.pl/cennik</a> </p>
                                </div>
                            )
                        }
                        {
                            currentCompany && currentCompany.company ? (
                                <div className="payment-summary-row">
                                    {
                                        ['ENTERPRISE', 'ENTERPRISE-50', 'SOLO(k)', 'BASIC(k)', 'PRO(k)', 'PRO+(k)'].includes(subscriptionType) ? (
                                            <>
                                                <div className="field enterprice">
                                                    <div className="label">{this.context.t('Users count')}</div>
                                                    {currentCompany.company ? currentCompany.company.members_count : ''}
                                                </div>
                                                <div className="field enterprice">
                                                    <div className="label">{this.context.t('Employee fee')}</div>
                                                    {`${employeeFee} PLN`}
                                                </div>
                                                <div className="field enterprice">
                                                    <div className="label">{this.context.t('Net')}</div>
                                                    {`${totalCost} PLN`}
                                                </div>
                                                <div className="field enterprice bold">
                                                    <div className="label">{this.context.t('Subscription fee (gross)')}</div>
                                                    {`${(parseFloat(totalCost) * 1.23).toFixed(2)} PLN`}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="field">
                                                    <div className="label">{this.context.t('Subscription type')}</div>
                                                    {`${subscriptionType} ${subscriptionType === 'FREE' ? '(0 PLN)' : subscriptionType === 'BASIC' ? '(199 PLN)' : subscriptionType === 'PRO' ? '(299 PLN)' : '(599 PLN)'}`}
                                                </div>
                                                <div className="field">
                                                    <div className="label">{this.context.t('Users count')}</div>
                                                    {currentCompany.company ? currentCompany.company.members_count : ''}
                                                </div>
                                                <div className="field">
                                                    <div className="label">{this.context.t('Users limit')}</div>
                                                    {subscriptionType === 'SOLO' ? '1' : subscriptionType === 'BASIC' ? '3' : subscriptionType === 'PRO' ? '5' : '10'}
                                                </div>
                                                <div className="field">
                                                    <div className="label">{this.context.t('Additional employees fee')}</div>
                                                    {`${additionalEmployeesFee} PLN`}
                                                </div>
                                                <div className="field">
                                                    <div className="label">{this.context.t('Net')}</div>
                                                    {`${totalCost} PLN`}
                                                </div>
                                                <div className="field bold">
                                                    <div className="label">{this.context.t('Subscription fee (gross)')}</div>
                                                    {`${(parseFloat(totalCost) * 1.23).toFixed(2)} PLN`}
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className="period-info-row">
                                        <p className="smaller">{this.context.t('Payments are handled by ')} <a href="https://espago.com/" target="_blank">Espago</a></p>
                                        <p className="smaller">{this.context.t('Payments are made by the operator, EasyDocs does not have access to customer payment card details.')}</p>
                                        <p className="smaller">{this.context.t('Subsequent fees will be charged automatically every 30 days from the payment card used to pay for the first subscription.')}</p>

                                        <div className="links">
                                            <a href="https://easydocs.pl/polityka-prywatnosci/" target="_blank">{this.context.t('Privacy Policy ')}</a>
                                            <a href="https://easydocs.pl/regulamin/" target="_blank">{this.context.t('Terms of Service ')}</a>
                                            <a href="https://easydocs.pl/kontakt/" target="_blank">{this.context.t('Contact Data')}</a>
                                        </div>
                                    </div>


                                    <div className="action-buttons">
                                        {
                                            currentCompany.company && currentCompany.company.subscription_type !== 'FREE' ? (
                                                <div className="change-sub-type-button" onClick={() => {
                                                    this.setState({
                                                        showChangeSubscriptionTypePopup: true
                                                    })
                                                }}>
                                                    {this.context.t('Upgrade subscription type')}
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }

                                        {
                                            subscriptionType !== 'FREE' ? (
                                                <div className={`pay-button ${currentCompany.company && currentCompany.company.subscription_type === 'ACTIVE' ? 'set-margin' : ''}`} onClick={() => { this.handlePayClick(currentCompany.company.subscription_type, currentCompany.company.has_paid_subscription) }}>
                                                    {
                                                        currentCompany.company && !currentCompany.company.has_paid_subscription ? (
                                                            this.context.t('Next step')
                                                        ) : (
                                                            currentCompany.company && currentCompany.company.subscription_type === 'CANCELED' ? (
                                                                this.context.t('Renew subscription')
                                                            ) : (
                                                                this.context.t('Cancel subscription')
                                                            )
                                                        )
                                                    }
                                                </div>
                                            ) : null
                                        }

                                    </div>
                                </div>
                            ) : null
                        }


                        {
                            currentCompany && currentCompany.company ? (
                                <div className="payments-history">
                                    <div className="header-row">
                                        {this.context.t('Your payments history:')}
                                        {
                                            sendCompanySubscriptionTransactionsRequest ? (
                                                <div className="loading-transactions">
                                                    <div className="text">{this.context.t('Please wait a second while we are retriving, loading and calculating your data')}</div>
                                                </div>
                                            ) : (
                                                companySubscriptionTransactions && companySubscriptionTransactions.length > 0 ? (
                                                    <div className='transactions-table'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('created')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Transaction date')}</span>
                                                                            <span className={this.getransactionsSortClass('created')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('transaction_number')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Transaction number')}</span>
                                                                            <span className={this.getransactionsSortClass('transaction_number')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('transaction_id')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Transaction id')}</span>
                                                                            <span className={this.getransactionsSortClass('transaction_id')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('period_start')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Service period')}</span>
                                                                            <span className={this.getransactionsSortClass('period_start')}></span>
                                                                        </div>
                                                                    </th>
                                                                    {/* <th onClick={() => this.props.switchTransactionsSortingFilter('package')}>
                                                            <div>
                                                                <span className="column-name">{this.context.t('Package')}</span>
                                                                <span className={this.getransactionsSortClass('package')}></span>
                                                            </div>
                                                        </th>
                                                        <th onClick={() => this.props.switchTransactionsSortingFilter('package_price')}>
                                                            <div>
                                                                <span className="column-name">{this.context.t('Package price')}</span>
                                                                <span className={this.getransactionsSortClass('package_price')}></span>
                                                            </div>
                                                        </th> */}
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('users_count')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Users count')}</span>
                                                                            <span className={this.getransactionsSortClass('users_count')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('users_fee')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Employee fee')}</span>
                                                                            <span className={this.getransactionsSortClass('users_fee')}></span>
                                                                        </div>
                                                                    </th>
                                                                    {/* <th onClick={() => this.props.switchTransactionsSortingFilter('extra_storage')}>
                                                            <div>
                                                                <span className="column-name">{this.context.t('Extra storage')}</span>
                                                                <span className={this.getransactionsSortClass('extra_storage')}></span>
                                                            </div>
                                                        </th> */}
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('net_value')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Net')}</span>
                                                                            <span className={this.getransactionsSortClass('net_value')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('amount_decimal')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Subscription fee (gross)')}</span>
                                                                            <span className={this.getransactionsSortClass('amount_decimal')}></span>
                                                                        </div>
                                                                    </th>
                                                                    <th onClick={() => this.props.switchTransactionsSortingFilter('verified')}>
                                                                        <div>
                                                                            <span className="column-name">{this.context.t('Status')}</span>
                                                                            <span className={this.getransactionsSortClass('verified')}></span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    companySubscriptionTransactions.map((tx, index) => {
                                                                        return (
                                                                            <tr className={`${index % 2 === 0 ? 'even' : 'odd'}`} key={"tx-" + tx.id}>
                                                                                <td className="date">{moment(tx.created).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                                                <td className="transaction-number">{tx.transaction_number}</td>
                                                                                <td className="transaction-number">{tx.transaction_id}</td>
                                                                                <td className="period">{`${moment(tx.period_start).utc().format('DD.MM.YYYY')} do ${moment(tx.period_end).utc().format('DD.MM.YYYY')}`}</td>
                                                                                {/* <td className="package">{tx.package}</td>
                                                                    <td className="package-price">{tx.package_price}</td> */}
                                                                                <td className="users-count">{tx.users_count}</td>
                                                                                <td className="usrs-fee">{tx.users_fee ? parseFloat(tx.users_fee).toFixed(2) : ''}</td>
                                                                                {/* <td className="extra-storage">{tx.extra_storage}</td> */}
                                                                                <td className="net-value">{tx.net_value ? parseFloat(tx.net_value).toFixed(2) : ''}</td>
                                                                                <td className="total">{tx.amount_decimal ? parseFloat(tx.amount_decimal).toFixed(2) : ''}</td>
                                                                                <td className={`status`} data-for={`tx-status-tooltip-${tx.id}`} data-tip='show'>{tx.status}</td>

                                                                                <ReactTooltip id={`tx-status-tooltip-${tx.id}`} place="left" effect="solid" className="default-tooltip tx-status-tooltip">
                                                                                    {getFullErrorInfo({ responseCode: tx.issuer_response_code, status: tx.status }, this.context.t)}
                                                                                </ReactTooltip>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="no-transactions">
                                                        <div className="text">{this.context.t('You don\'t have any transactions yet')}</div>
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }

    }
}



Subscription.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentCompany: state.Company.currentCompany,
    userCompanies: state.Company.companies,
    userProjects: state.User.userProjects || [],
    user: state.User.user,
    overlayPreferance: state.User.overlayPreferance || {},
    companySubscriptionTransactions: state.Company.companySubscriptionTransactions,
    sendCompanySubscriptionTransactionsRequest: state.Company.sendCompanySubscriptionTransactionsRequest,
    companySubscriptionTransactionsSortField: state.Company.companySubscriptionTransactionsSortField,
    companySubscriptionTransactionsSortOrder: state.Company.companySubscriptionTransactionsSortOrder
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    setCurrentCompany: CompanyActions.setCurrentCompany,
    registerSubscriptionPayment: CompanyActions.registerSubscriptionPayment,
    getCompanysubscriptionTransactions: CompanyActions.getCompanysubscriptionTransactions,
    switchTransactionsSortingFilter: CompanyActions.switchTransactionsSortingFilter,
    cancelSubscription: CompanyActions.cancelSubscription,

    makeEspagoPayment: CompanyActions.makeEspagoPayment
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)