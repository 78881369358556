import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions';

export class EditProjectMemebrsWindow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedUsersOnStart: [],
            selectedUsers: []
        }
    }

    componentDidMount() {
        const { currentMembers } = this.props
        this.setState({
            selectedUsersOnStart: currentMembers.map(m => m.id),
            selectedUsers: currentMembers.map(m => m.id)
        })
    }

    userOnClick = userId => {
        const { selectedUsers } = this.state

        if (selectedUsers.includes(userId)) {
            this.setState({
                selectedUsers: selectedUsers.filter(u => u !== userId)
            })
        } else {
            this.setState({
                selectedUsers: [...selectedUsers, userId]
            })
        }
    }

    save = () => {
        const { selectedUsers, selectedUsersOnStart } = this.state
        const { listTypeToEdit, projectId, companyId } = this.props
        var userToAdd = [], userToRemove = []
        selectedUsers.map(u => {
            if (!selectedUsersOnStart.includes(u)) {
                userToAdd.push(u)
            }
        })

        selectedUsersOnStart.map(u => {
            if (!selectedUsers.includes(u)) {
                userToRemove.push(u)
            }
        })

        console.log('userToAdd', userToAdd)
        console.log('userToRemove', userToRemove)
        console.log('listTypeToEdit', listTypeToEdit)

        if (listTypeToEdit === 'DROPZONE_MANAGERS') {
            this.props.saveDropzoneManagers(projectId, companyId, userToAdd, userToRemove, success => { if (success) this.props.close() })
        } else {
            this.props.saveProjectMembers(projectId, companyId, userToAdd, userToRemove, success => { if (success) this.props.close() })
        }
    }

    render() {
        const { listTypeToEdit, availableMemebrsToEdit } = this.props
        const { selectedUsers } = this.state
        return (
            <div className="project-access-users-list popup">
                <div className="popup-card">
                    {
                        listTypeToEdit === 'DROPZONE_MANAGERS' ? (
                            <h3>{this.context.t('Edit dropzone managers')}</h3>
                        ) : (
                            <h3>{this.context.t('Edit project members')}</h3>
                        )
                    }

                    {
                        listTypeToEdit === 'DROPZONE_MANAGERS' ? (
                            <p>{this.context.t('Select users who will have access to all documents uploaded to this binder and will have the ability to configure document workflows.')}</p>
                        ) : (
                            <p>{this.context.t('Select users who will have access to this binder and can be assigned to the binder\'s document workflows.')}</p>
                        )
                    }
                    <div className="users-list">
                        <SimpleBar style={{ maxHeight: '50vh', width: '100%', paddingRight: '10px', marginRight: '-10px' }}>
                            {
                                availableMemebrsToEdit.map(user => {
                                    return (
                                        <div onClick={() => this.userOnClick(user.id)} className={`user ${selectedUsers.includes(user.id) ? 'selected' : ''}`} key={`user-${user.id}`}>
                                            <div className={`profile-pic ${user.photo ? '' : 'default'}`}>
                                                {
                                                    user.photo ? (
                                                        <img src={user.photo} alt="Profile picture" />
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                            <div className="user-info">
                                                {
                                                    user.name ? (
                                                        <>
                                                            <div className='name-and-surname'>{`${user.name}`}</div>
                                                            <div className='email'>{`${user.email}`}</div>
                                                        </>
                                                    ) : (
                                                        <div className='name-and-surname'>{`${user.email}`}</div>
                                                    )
                                                }
                                            </div>
                                            <div className={`checkbox ${selectedUsers.includes(user.id) ? 'selected' : ''}`}></div>
                                        </div>
                                    )
                                })
                            }
                        </SimpleBar>
                    </div>
                    <div className="footer">
                        <div className="button btn-cancel" onClick={() => this.props.close()}>{this.context.t('Cancel')}</div>
                        <div className="button btn-confirm" onClick={() => this.save()}>{this.context.t('Save')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

EditProjectMemebrsWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
    saveDropzoneManagers: CompanyActions.saveDropzoneManagers,
    saveProjectMembers: CompanyActions.saveProjectMembers,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectMemebrsWindow)