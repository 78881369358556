const bajkaPanaKleksaProjects = [
    654,    //PLANETA JUBLAMA
]

const bajkaPanaKleksaLevel1 = [
    {
        "description": "EVENT LEGO",
        "code": "EVENT LEGO",
        "nextLevel": [
            {
                "description": "KRAKÓW",
                "code": "KRAKÓW",
                "nextLevel": [
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT",
                        "nextLevel": [
                            {
                                "description": "KADRA",
                                "code": "KADRA"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            }
                        ]
                    },
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                        "nextLevel": [
                            {
                                "description": "WARSZATY",
                                "code": "WARSZATY"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            },
                            {
                                "description": "POLIGRAFIA",
                                "code": "POLIGRAFIA"
                            }
                        ]
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            },
            {
                "description": "BYDGOSZCZ",
                "code": "BYDGOSZCZ",
                "nextLevel": [
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT",
                        "nextLevel": [
                            {
                                "description": "KADRA",
                                "code": "KADRA"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            }
                        ]
                    },
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                        "nextLevel": [
                            {
                                "description": "WARSZATY",
                                "code": "WARSZATY"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            },
                            {
                                "description": "POLIGRAFIA",
                                "code": "POLIGRAFIA"
                            }
                        ]
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            },
            {
                "description": "SOSNOWIEC",
                "code": "SOSNOWIEC",
                "nextLevel": [
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT",
                        "nextLevel": [
                            {
                                "description": "KADRA",
                                "code": "KADRA"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            }
                        ]
                    },
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                        "nextLevel": [
                            {
                                "description": "WARSZATY",
                                "code": "WARSZATY"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            },
                            {
                                "description": "POLIGRAFIA",
                                "code": "POLIGRAFIA"
                            }
                        ]
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            },
            {
                "description": "WARSZAWA",
                "code": "WARSZAWA",
                "nextLevel": [
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT",
                        "nextLevel": [
                            {
                                "description": "KADRA",
                                "code": "KADRA"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            }
                        ]
                    },
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                        "nextLevel": [
                            {
                                "description": "WARSZATY",
                                "code": "WARSZATY"
                            },
                            {
                                "description": "EKSPOZYCJA",
                                "code": "EKSPOZYCJA"
                            },
                            {
                                "description": "POLIGRAFIA",
                                "code": "POLIGRAFIA"
                            }
                        ]
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }
        ]
    },
    {
        "description": "EVENT",
        "code": "EVENT",
        "nextLevel": [
            {
                "description": "ZEWNĘTRZNY",
                "code": "ZEWNĘTRZNY",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY"
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }, {
                "description": "NOC MUZEÓW",
                "code": "NOC MUZEÓW",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY"
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }, {
                "description": "HELIOS",
                "code": "HELIOS",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY"
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }, {
                "description": "WEWNĘTRZNY",
                "code": "WEWNĘTRZNY",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY"
                    },
                    {
                        "description": "KADRA",
                        "code": "KADRA"
                    },
                    {
                        "description": "TRANSPORT",
                        "code": "TRANSPORT"
                    },
                    {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }
        ]
    },
    {
        "description": "BPK",
        "code": "BPK",
        "nextLevel": [
            {
                "description": "BIURO",
                "code": "BIURO",
                "nextLevel": [
                    {
                        "description": "ARTYKUŁY PAPIERNICZE",
                        "code": "ARTYKUŁY PAPIERNICZE",
                    }, {
                        "description": "TONERY I TUSZE",
                        "code": "TONERY I TUSZE",
                    }, {
                        "description": "SPRZĘT",
                        "code": "SPRZĘT",
                    }, {
                        "description": "DROBNE AKCESORIA",
                        "code": "DROBNE AKCESORIA",
                    }, {
                        "description": "ARTYKUŁY SPOŻYWCZE",
                        "code": "ARTYKUŁY SPOŻYWCZE",
                    }, {
                        "description": "LICENCJE I OPROGRAMOWANIE",
                        "code": "LICENCJE I OPROGRAMOWANIE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "EKSPOZYCJA",
                "code": "EKSPOZYCJA",
                "nextLevel": [
                    {
                        "description": "ZAKUP EKSPOZYCJI",
                        "code": "ZAKUP EKSPOZYCJI",
                    }, {
                        "description": "NAPRAWA EKSPOZYCJI",
                        "code": "NAPRAWA EKSPOZYCJI",
                        "nextLevel": [
                            {
                                "description": "SPRZĘT",
                                "code": "SPRZĘT"
                            }, {
                                "description": "USŁUGI OBCE",
                                "code": "USŁUGI OBCE"
                            }, {
                                "description": "MATERIAŁY",
                                "code": "MATERIAŁY"
                            }, {
                                "description": "NARZĘDZIA",
                                "code": "NARZĘDZIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "WYPOSAŻENIE I DROBNE AKCESORIA",
                        "code": "WYPOSAŻENIE I DROBNE AKCESORIA",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                        "nextLevel": [
                            {
                                "description": "KARTY NA PIECZĄTKI",
                                "code": "KARTY NA PIECZĄTKI"
                            }, {
                                "description": "NAGRODY",
                                "code": "NAGRODY"
                            }, {
                                "description": "VOUCHERY",
                                "code": "VOUCHERY"
                            }, {
                                "description": "BILETY DO SKANOWANIA",
                                "code": "BILETY DO SKANOWANIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "DEKORACJE",
                "code": "DEKORACJE",
                "nextLevel": [
                    {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "UTRZYMANIE CZYSTOŚCI",
                "code": "UTRZYMANIE CZYSTOŚCI",
                "nextLevel": [
                    {
                        "description": "ŚRODKI CZYSTOŚCI",
                        "code": "ŚRODKI CZYSTOŚCI",
                    }, {
                        "description": "AKCESORIA I SPRZĘT",
                        "code": "AKCESORIA I SPRZĘT",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }
                ]
            }, {
                "description": "MARKETING",
                "code": "MARKETING",
                "nextLevel": [
                    {
                        "description": "REKLAMA FACEBOOK",
                        "code": "REKLAMA FACEBOOK",
                    }, {
                        "description": "REKLAMA TIKTOK",
                        "code": "REKLAMA TIKTOK",
                    }, {
                        "description": "REKLAMA GOOGLE ADS",
                        "code": "REKLAMA GOOGLE ADS",
                    }, {
                        "description": "REKLAMA POZOSTAŁE MEDIA",
                        "code": "REKLAMA POZOSTAŁE MEDIA",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }, {
                        "description": "KOSZTY LICENCJI I SUBSKRYPCJI",
                        "code": "KOSZTY LICENCJI I SUBSKRYPCJI",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                    }, {
                        "description": "WSPÓŁPRACA MARKETINGOWA",
                        "code": "WSPÓŁPRACA MARKETINGOWA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "GASTRO",
                "code": "GASTRO",
                "nextLevel": [
                    {
                        "description": "TOWARY",
                        "code": "TOWARY",
                    }, {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "AKCESORIA I SPRZĘT",
                        "code": "AKCESORIA I SPRZĘT",
                    }, {
                        "description": "LICENCJE I OPROGRAMOWANIE",
                        "code": "LICENCJE I OPROGRAMOWANIE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "KADRA",
                "code": "KADRA",
                "nextLevel": [
                    {
                        "description": "ANIMATORZY/PRZEWODNICY",
                        "code": "ANIMATORZY/PRZEWODNICY",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "RECEPCJA",
                        "code": "RECEPCJA",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "SKLEP",
                        "code": "SKLEP",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "GASTRONOMIA",
                        "code": "GASTRONOMIA",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }
                ]
            }, {
                "description": "POZOSTAŁE LICENCJE",
                "code": "POZOSTAŁE LICENCJE",
                "nextLevel": [
                    {
                        "description": "SYSTEM SPRZEDAŻY BILETÓW",
                        "code": "SYSTEM SPRZEDAŻY BILETÓW",
                    }, {
                        "description": "ADMINISTRACYJNE",
                        "code": "ADMINISTRACYJNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "PÓŁKOLONIE",
                "code": "PÓŁKOLONIE",
                "nextLevel": [
                    {
                        "description": "KADRA",
                        "code": "KADRA",
                    }, {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "CATERING ZEWNĘTRZNY",
                        "code": "CATERING ZEWNĘTRZNY",
                    }, {
                        "description": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                        "code": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                    }, {
                        "description": "KOSZTY ZEZWOLEŃ",
                        "code": "KOSZTY ZEZWOLEŃ",
                    }, {
                        "description": "UBEZPIECZENIA",
                        "code": "UBEZPIECZENIA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "WARSZTATY",
                "code": "WARSZTATY",
                "nextLevel": [
                    {
                        "description": "CONCEPT ART.",
                        "code": "CONCEPT ART.",
                    }, {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "DLA BILETU ŁĄCZONEGO",
                        "code": "DLA BILETU ŁĄCZONEGO",
                    }, {
                        "description": "NAUKOWE",
                        "code": "NAUKOWE",
                    }, {
                        "description": "STORRYTELLING",
                        "code": "STORRYTELLING",
                    }
                ]
            }, {
                "description": "URODZINY",
                "code": "URODZINY",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "TOWARY",
                        "code": "TOWARY",
                    }, {
                        "description": "KADRA",
                        "code": "KADRA",
                        "nextLevel": [
                            {
                                "description": "PRZEWODNICY/ANIMATORZY",
                                "code": "PRZEWODNICY/ANIMATORZY",
                            }, {
                                "description": "KAWIARNIA",
                                "code": "KAWIARNIA",
                            }
                        ]
                    }, {
                        "description": "MATERIAŁY NA WARSZTATY",
                        "code": "MATERIAŁY NA WARSZTATY",
                        "nextLevel": [
                            {
                                "description": "SCENARIUSZ 1",
                                "code": "SCENARIUSZ 1",
                            }, {
                                "description": "SCENARIUSZ 2",
                                "code": "SCENARIUSZ 2",
                            }, {
                                "description": "SCENARIUSZ 3",
                                "code": "SCENARIUSZ 3",
                            }
                        ]
                    }
                ]
            }, {
                "description": "WYNAJEM LOKALU",
                "code": "WYNAJEM LOKALU",
            }, {
                "description": "MEDIA",
                "code": "MEDIA",
                "nextLevel": [
                    {
                        "description": "ENERGIA",
                        "code": "ENERGIA",
                    }, {
                        "description": "OGRZEWANIE",
                        "code": "OGRZEWANIE",
                    }, {
                        "description": "WODA",
                        "code": "WODA",
                    }, {
                        "description": "INTERNET",
                        "code": "INTERNET",
                    }
                ]
            }, {
                "description": "USŁUGI OBCE",
                "code": "USŁUGI OBCE",
                "nextLevel": [
                    {
                        "description": "SERWIS URZĄDZEŃ",
                        "code": "SERWIS URZĄDZEŃ",
                    }
                ]
            }, {
                "description": "POZOSTAŁE",
                "code": "POZOSTAŁE",
            }
        ]
    },
    {
        "description": "MK KATOWICE",
        "code": "MK KATOWICE",
        "nextLevel": [
            {
                "description": "BIURO",
                "code": "BIURO",
                "nextLevel": [
                    {
                        "description": "ARTYKUŁY PAPIERNICZE",
                        "code": "ARTYKUŁY PAPIERNICZE",
                    }, {
                        "description": "TONERY I TUSZE",
                        "code": "TONERY I TUSZE",
                    }, {
                        "description": "SPRZĘT",
                        "code": "SPRZĘT",
                    }, {
                        "description": "DROBNE AKCESORIA",
                        "code": "DROBNE AKCESORIA",
                    }, {
                        "description": "ARTYKUŁY SPOŻYWCZE",
                        "code": "ARTYKUŁY SPOŻYWCZE",
                    }, {
                        "description": "LICENCJE I OPROGRAMOWANIE",
                        "code": "LICENCJE I OPROGRAMOWANIE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "EKSPOZYCJA",
                "code": "EKSPOZYCJA",
                "nextLevel": [
                    {
                        "description": "ZAKUP EKSPOZYCJI",
                        "code": "ZAKUP EKSPOZYCJI",
                    }, {
                        "description": "NAPRAWA EKSPOZYCJI",
                        "code": "NAPRAWA EKSPOZYCJI",
                        "nextLevel": [
                            {
                                "description": "SPRZĘT",
                                "code": "SPRZĘT"
                            }, {
                                "description": "USŁUGI OBCE",
                                "code": "USŁUGI OBCE"
                            }, {
                                "description": "MATERIAŁY",
                                "code": "MATERIAŁY"
                            }, {
                                "description": "NARZĘDZIA",
                                "code": "NARZĘDZIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "WYPOSAŻENIE I DROBNE AKCESORIA",
                        "code": "WYPOSAŻENIE I DROBNE AKCESORIA",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                        "nextLevel": [
                            {
                                "description": "KARTY NA PIECZĄTKI",
                                "code": "KARTY NA PIECZĄTKI"
                            }, {
                                "description": "NAGRODY",
                                "code": "NAGRODY"
                            }, {
                                "description": "VOUCHERY",
                                "code": "VOUCHERY"
                            }, {
                                "description": "BILETY DO SKANOWANIA",
                                "code": "BILETY DO SKANOWANIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "DEKORACJE",
                "code": "DEKORACJE",
                "nextLevel": [
                    {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "UTRZYMANIE CZYSTOŚCI",
                "code": "UTRZYMANIE CZYSTOŚCI",
                "nextLevel": [
                    {
                        "description": "ŚRODKI CZYSTOŚCI",
                        "code": "ŚRODKI CZYSTOŚCI",
                    }, {
                        "description": "AKCESORIA I SPRZĘT",
                        "code": "AKCESORIA I SPRZĘT",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }
                ]
            }, {
                "description": "MARKETING",
                "code": "MARKETING",
                "nextLevel": [
                    {
                        "description": "REKLAMA FACEBOOK",
                        "code": "REKLAMA FACEBOOK",
                    }, {
                        "description": "REKLAMA TIKTOK",
                        "code": "REKLAMA TIKTOK",
                    }, {
                        "description": "REKLAMA GOOGLE ADS",
                        "code": "REKLAMA GOOGLE ADS",
                    }, {
                        "description": "REKLAMA POZOSTAŁE MEDIA",
                        "code": "REKLAMA POZOSTAŁE MEDIA",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }, {
                        "description": "KOSZTY LICENCJI I SUBSKRYPCJI",
                        "code": "KOSZTY LICENCJI I SUBSKRYPCJI",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                    }, {
                        "description": "WSPÓŁPRACA MARKETINGOWA",
                        "code": "WSPÓŁPRACA MARKETINGOWA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "GASTRO",
                "code": "GASTRO",
                "nextLevel": [
                    {
                        "description": "TOWARY",
                        "code": "TOWARY",
                    }, {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "AKCESORIA I SPRZĘT",
                        "code": "AKCESORIA I SPRZĘT",
                    }, {
                        "description": "LICENCJE I OPROGRAMOWANIE",
                        "code": "LICENCJE I OPROGRAMOWANIE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "KADRA",
                "code": "KADRA",
                "nextLevel": [
                    {
                        "description": "ANIMATORZY/PRZEWODNICY",
                        "code": "ANIMATORZY/PRZEWODNICY",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "RECEPCJA",
                        "code": "RECEPCJA",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "SKLEP",
                        "code": "SKLEP",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "GASTRONOMIA",
                        "code": "GASTRONOMIA",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }
                ]
            }, {
                "description": "POZOSTAŁE LICENCJE",
                "code": "POZOSTAŁE LICENCJE",
                "nextLevel": [
                    {
                        "description": "SYSTEM SPRZEDAŻY BILETÓW",
                        "code": "SYSTEM SPRZEDAŻY BILETÓW",
                    }, {
                        "description": "ADMINISTRACYJNE",
                        "code": "ADMINISTRACYJNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "PÓŁKOLONIE",
                "code": "PÓŁKOLONIE",
                "nextLevel": [
                    {
                        "description": "KADRA",
                        "code": "KADRA",
                    }, {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "CATERING ZEWNĘTRZNY",
                        "code": "CATERING ZEWNĘTRZNY",
                    }, {
                        "description": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                        "code": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                    }, {
                        "description": "KOSZTY ZEZWOLEŃ",
                        "code": "KOSZTY ZEZWOLEŃ",
                    }, {
                        "description": "UBEZPIECZENIA",
                        "code": "UBEZPIECZENIA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "WARSZTATY",
                "code": "WARSZTATY",
                "nextLevel": [
                    {
                        "description": "CONCEPT ART.",
                        "code": "CONCEPT ART.",
                    }, {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "DLA BILETU ŁĄCZONEGO",
                        "code": "DLA BILETU ŁĄCZONEGO",
                    }, {
                        "description": "NAUKOWE",
                        "code": "NAUKOWE",
                    }, {
                        "description": "STORRYTELLING",
                        "code": "STORRYTELLING",
                    }
                ]
            }, {
                "description": "URODZINY",
                "code": "URODZINY",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "TOWARY",
                        "code": "TOWARY",
                    }, {
                        "description": "KADRA",
                        "code": "KADRA",
                        "nextLevel": [
                            {
                                "description": "PRZEWODNICY/ANIMATORZY",
                                "code": "PRZEWODNICY/ANIMATORZY",
                            }, {
                                "description": "KAWIARNIA",
                                "code": "KAWIARNIA",
                            }
                        ]
                    }, {
                        "description": "MATERIAŁY NA WARSZTATY",
                        "code": "MATERIAŁY NA WARSZTATY",
                        "nextLevel": [
                            {
                                "description": "SCENARIUSZ 1",
                                "code": "SCENARIUSZ 1",
                            }, {
                                "description": "SCENARIUSZ 2",
                                "code": "SCENARIUSZ 2",
                            }, {
                                "description": "SCENARIUSZ 3",
                                "code": "SCENARIUSZ 3",
                            }
                        ]
                    }
                ]
            }, {
                "description": "WYNAJEM LOKALU",
                "code": "WYNAJEM LOKALU",
            }, {
                "description": "MEDIA",
                "code": "MEDIA",
                "nextLevel": [
                    {
                        "description": "ENERGIA",
                        "code": "ENERGIA",
                    }, {
                        "description": "OGRZEWANIE",
                        "code": "OGRZEWANIE",
                    }, {
                        "description": "WODA",
                        "code": "WODA",
                    }, {
                        "description": "INTERNET",
                        "code": "INTERNET",
                    }
                ]
            }, {
                "description": "USŁUGI OBCE",
                "code": "USŁUGI OBCE",
                "nextLevel": [
                    {
                        "description": "SERWIS URZĄDZEŃ",
                        "code": "SERWIS URZĄDZEŃ",
                    }
                ]
            }, {
                "description": "POZOSTAŁE",
                "code": "POZOSTAŁE",
            }
        ]
    },
    {
        "description": "MK WARSZAWA",
        "code": "MK WARSZAWA",
        "nextLevel": [
            {
                "description": "BIURO",
                "code": "BIURO",
                "nextLevel": [
                    {
                        "description": "ARTYKUŁY PAPIERNICZE",
                        "code": "ARTYKUŁY PAPIERNICZE",
                    }, {
                        "description": "TONERY I TUSZE",
                        "code": "TONERY I TUSZE",
                    }, {
                        "description": "SPRZĘT",
                        "code": "SPRZĘT",
                    }, {
                        "description": "DROBNE AKCESORIA",
                        "code": "DROBNE AKCESORIA",
                    }, {
                        "description": "ARTYKUŁY SPOŻYWCZE",
                        "code": "ARTYKUŁY SPOŻYWCZE",
                    }, {
                        "description": "LICENCJE I OPROGRAMOWANIE",
                        "code": "LICENCJE I OPROGRAMOWANIE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "EKSPOZYCJA",
                "code": "EKSPOZYCJA",
                "nextLevel": [
                    {
                        "description": "ZAKUP EKSPOZYCJI",
                        "code": "ZAKUP EKSPOZYCJI",
                    }, {
                        "description": "NAPRAWA EKSPOZYCJI",
                        "code": "NAPRAWA EKSPOZYCJI",
                        "nextLevel": [
                            {
                                "description": "SPRZĘT",
                                "code": "SPRZĘT"
                            }, {
                                "description": "USŁUGI OBCE",
                                "code": "USŁUGI OBCE"
                            }, {
                                "description": "MATERIAŁY",
                                "code": "MATERIAŁY"
                            }, {
                                "description": "NARZĘDZIA",
                                "code": "NARZĘDZIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "WYPOSAŻENIE I DROBNE AKCESORIA",
                        "code": "WYPOSAŻENIE I DROBNE AKCESORIA",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                        "nextLevel": [
                            {
                                "description": "KARTY NA PIECZĄTKI",
                                "code": "KARTY NA PIECZĄTKI"
                            }, {
                                "description": "NAGRODY",
                                "code": "NAGRODY"
                            }, {
                                "description": "VOUCHERY",
                                "code": "VOUCHERY"
                            }, {
                                "description": "BILETY DO SKANOWANIA",
                                "code": "BILETY DO SKANOWANIA"
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE"
                            }
                        ]
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "DEKORACJE",
                "code": "DEKORACJE",
                "nextLevel": [
                    {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "UTRZYMANIE CZYSTOŚCI",
                "code": "UTRZYMANIE CZYSTOŚCI",
                "nextLevel": [
                    {
                        "description": "ŚRODKI CZYSTOŚCI",
                        "code": "ŚRODKI CZYSTOŚCI",
                    }, {
                        "description": "AKCESORIA I SPRZĘT",
                        "code": "AKCESORIA I SPRZĘT",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }
                ]
            }, {
                "description": "MARKETING",
                "code": "MARKETING",
                "nextLevel": [
                    {
                        "description": "REKLAMA FACEBOOK",
                        "code": "REKLAMA FACEBOOK",
                    }, {
                        "description": "REKLAMA TIKTOK",
                        "code": "REKLAMA TIKTOK",
                    }, {
                        "description": "REKLAMA GOOGLE ADS",
                        "code": "REKLAMA GOOGLE ADS",
                    }, {
                        "description": "REKLAMA POZOSTAŁE MEDIA",
                        "code": "REKLAMA POZOSTAŁE MEDIA",
                    }, {
                        "description": "USŁUGI OBCE",
                        "code": "USŁUGI OBCE",
                    }, {
                        "description": "KOSZTY LICENCJI I SUBSKRYPCJI",
                        "code": "KOSZTY LICENCJI I SUBSKRYPCJI",
                    }, {
                        "description": "POLIGRAFIA",
                        "code": "POLIGRAFIA",
                    }, {
                        "description": "WSPÓŁPRACA MARKETINGOWA",
                        "code": "WSPÓŁPRACA MARKETINGOWA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "KADRA",
                "code": "KADRA",
                "nextLevel": [
                    {
                        "description": "ANIMATORZY/PRZEWODNICY",
                        "code": "ANIMATORZY/PRZEWODNICY",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "RECEPCJA",
                        "code": "RECEPCJA",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }, {
                        "description": "SKLEP",
                        "code": "SKLEP",
                        "nextLevel": [
                            {
                                "description": "SZKOLENIA",
                                "code": "SZKOLENIA",
                            }, {
                                "description": "ODZIEŻ I OBUWIE PRACOWNICZE",
                                "code": "ODZIEŻ I OBUWIE PRACOWNICZE",
                            }, {
                                "description": "ZAPLECZE SOCJALNE",
                                "code": "ZAPLECZE SOCJALNE",
                            }, {
                                "description": "POZOSTAŁE",
                                "code": "POZOSTAŁE",
                            }, {
                                "description": "KOSZTY REKRUTACJI",
                                "code": "KOSZTY REKRUTACJI",
                            }
                        ]
                    }
                ]
            }, {
                "description": "POZOSTAŁE LICENCJE",
                "code": "POZOSTAŁE LICENCJE",
                "nextLevel": [
                    {
                        "description": "SYSTEM SPRZEDAŻY BILETÓW",
                        "code": "SYSTEM SPRZEDAŻY BILETÓW",
                    }, {
                        "description": "ADMINISTRACYJNE",
                        "code": "ADMINISTRACYJNE",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "PÓŁKOLONIE",
                "code": "PÓŁKOLONIE",
                "nextLevel": [
                    {
                        "description": "KADRA",
                        "code": "KADRA",
                    }, {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "CATERING ZEWNĘTRZNY",
                        "code": "CATERING ZEWNĘTRZNY",
                    }, {
                        "description": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                        "code": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                    }, {
                        "description": "KOSZTY ZEZWOLEŃ",
                        "code": "KOSZTY ZEZWOLEŃ",
                    }, {
                        "description": "UBEZPIECZENIA",
                        "code": "UBEZPIECZENIA",
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE",
                    }
                ]
            }, {
                "description": "WARSZTATY",
                "code": "WARSZTATY",
                "nextLevel": [
                    {
                        "description": "CONCEPT ART.",
                        "code": "CONCEPT ART.",
                    }, {
                        "description": "KWARTALNE",
                        "code": "KWARTALNE",
                    }, {
                        "description": "NAUKOWE",
                        "code": "NAUKOWE",
                    }, {
                        "description": "STORRYTELLING",
                        "code": "STORRYTELLING",
                    }
                ]
            }, {
                "description": "URODZINY",
                "code": "URODZINY",
                "nextLevel": [
                    {
                        "description": "MATERIAŁY",
                        "code": "MATERIAŁY",
                    }, {
                        "description": "TOWARY",
                        "code": "TOWARY",
                    }, {
                        "description": "KADRA",
                        "code": "KADRA",
                        "nextLevel": [
                            {
                                "description": "PRZEWODNICY/ANIMATORZY",
                                "code": "PRZEWODNICY/ANIMATORZY",
                            }, {
                                "description": "KAWIARNIA",
                                "code": "KAWIARNIA",
                            }
                        ]
                    }, {
                        "description": "MATERIAŁY NA WARSZTATY",
                        "code": "MATERIAŁY NA WARSZTATY",
                        "nextLevel": [
                            {
                                "description": "SCENARIUSZ 1",
                                "code": "SCENARIUSZ 1",
                            }, {
                                "description": "SCENARIUSZ 2",
                                "code": "SCENARIUSZ 2",
                            }, {
                                "description": "SCENARIUSZ 3",
                                "code": "SCENARIUSZ 3",
                            }
                        ]
                    }
                ]
            }, {
                "description": "WYNAJEM LOKALU",
                "code": "WYNAJEM LOKALU",
            }, {
                "description": "MEDIA",
                "code": "MEDIA",
                "nextLevel": [
                    {
                        "description": "ENERGIA",
                        "code": "ENERGIA",
                    }, {
                        "description": "OGRZEWANIE",
                        "code": "OGRZEWANIE",
                    }, {
                        "description": "WODA",
                        "code": "WODA",
                    }, {
                        "description": "INTERNET",
                        "code": "INTERNET",
                    }
                ]
            }, {
                "description": "USŁUGI OBCE",
                "code": "USŁUGI OBCE",
                "nextLevel": [
                    {
                        "description": "SERWIS URZĄDZEŃ",
                        "code": "SERWIS URZĄDZEŃ",
                    }
                ]
            }, {
                "description": "POZOSTAŁE",
                "code": "POZOSTAŁE",
            }
        ]
    }, {
        "description": "KOLONIE",
        "code": "KOLONIE",
        "nextLevel": [
            {
                "description": "MATERIAŁY",
                "code": "MATERIAŁY"
            },
            {
                "description": "MARKETING",
                "code": "MARKETING"
            },
            {
                "description": "KADRA",
                "code": "KADRA"
            },
            {
                "description": "ZAKWATEROWANIE",
                "code": "ZAKWATEROWANIE"
            },
            {
                "description": "CATERING",
                "code": "CATERING"
            },
            {
                "description": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                "code": "KOSZTY ATRAKCJI ZEWNĘTRZNYCH",
                "nextLevel": [
                    {
                        "description": "BILETY WSTĘPU",
                        "code": "BILETY WSTĘPU"
                    }, {
                        "description": "TRANSPORT UCZESTNIKÓW",
                        "code": "TRANSPORT UCZESTNIKÓW"
                    }
                ]
            },
            {
                "description": "KOSZTY ZEZWOLEŃ",
                "code": "KOSZTY ZEZWOLEŃ",
            },
            {
                "description": "UBEZPIECZENIA",
                "code": "UBEZPIECZENIA"
            },
            {
                "description": "TRANSPORT",
                "code": "TRANSPORT"
            },
            {
                "description": "POZOSTAŁE",
                "code": "POZOSTAŁE"
            }
        ]
    }, {
        "description": "PROJEKT",
        "code": "PROJEKT",
        "nextLevel": [
            {
                "description": "PODCAST",
                "code": "PODCAST"
            }, {
                "description": "SKLEP INTERNETOWY",
                "code": "SKLEP INTERNETOWY",
                "nextLevel": [
                    {
                        "description": "LICENCJE",
                        "code": "LICENCJE"
                    }, {
                        "description": "TOWARY",
                        "code": "TOWARY"
                    }, {
                        "description": "POZOSTAŁE",
                        "code": "POZOSTAŁE"
                    }
                ]
            }
        ]
    }, {
        "description": "OGÓLNE",
        "code": "OGÓLNE",
        "nextLevel": [
            {
                "description": "SPRZEDAŻ",
                "code": "SPRZEDAŻ"
            }, {
                "description": "ZARZĄD",
                "code": "ZARZĄD"
            }, {
                "description": "PALIWO",
                "code": "PALIWO"
            }
        ]
    }
]

const bajkaPanaKleksaMpkSettings = {
    levelsCount: 4,
    hasDependentSublevels: true,
    level1List: bajkaPanaKleksaLevel1,
    level2List: [],
    level3List: [],
    level4List: []
}


module.exports = {
    bajkaPanaKleksaProjects,
    bajkaPanaKleksaMpkSettings
};