import arrayObjectIndexOf from "../helpers/indexOfObject";
import moment from "moment";

export const CompanyConst = {
  IS_OPEN_INVOICE_NUMBER_POPUP: "IS_OPEN_INVOICE_NUMBER_POPUP",
  SEND_CHECK_NIP_REQUEST: 'SEND_CHECK_NIP_REQUEST',
  CHECK_NIP: 'CHECK_NIP',
  DELETE_UNFINISHED_REGISTRATION: 'DELETE_UNFINISHED_REGISTRATION',
  SEND_REGISTER_COMPANY_REQUEST: 'SEND_REGISTER_COMPANY_REQUEST',
  GOT_REGISTER_COMPANY_RESPONSE: 'GOT_REGISTER_COMPANY_RESPONSE',
  GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
  SORT_ALL_COMPANIES: 'SORT_ALL_COMPANIES',
  GET_COMPANIES_DETAILS: 'GET_COMPANIES_DETAILS',
  UPDATE_USER_COMPANY_INFO: 'UPDATE_USER_COMPANY_INFO',
  UPDATE_SUBSCRIPTION_TYPE: 'UPDATE_SUBSCRIPTION_TYPE',
  PROMOTED_USER: 'PROMOTED_USER',
  GET_COMPANY_PROJECTS: 'GET_COMPANY_PROJECTS',
  CHANGE_MY_ROLE_IN_PROJECT: 'CHANGE_MY_ROLE_IN_PROJECT',
  GET_COMPANY_COUNTERPARTIES: 'GET_COMPANY_COUNTERPARTIES',
  SORT_COUNTERPARTIES: 'SORT_COUNTERPARTIES',
  UPDATE_COUNTERPARTY: 'UPDATE_COUNTERPARTY',
  ADD_COUNTERPARTY: 'ADD_COUNTERPARTY',
  ALL_USERS_ASSOCIATED_WITH_COMPANY: 'ALL_USERS_ASSOCIATED_WITH_COMPANY',
  CHANGE_COMPANY_WORKERS_SORTING: 'CHANGE_COMPANY_WORKERS_SORTING',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  CHANGE_MEMBER_ROLE: 'CHANGE_MEMBER_ROLE',
  PROJECT_WORKERS: 'PROJECT_WORKERS',
  PROJECT_WORKERS_EDIT: 'PROJECT_WORKERS_EDIT',
  UPDATE_PROJECT_MEMBERS: 'UPDATE_PROJECT_MEMBERS',
  UPDATE_PROJECT_DROPZONE_MANAGERS: 'UPDATE_PROJECT_DROPZONE_MANAGERS',
  USER_COMPANIES: 'USER_COMPANIES',
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  SEND_GET_MY_REPORTS_REQUEST: 'SEND_GET_MY_REPORTS_REQUEST',

  CHANGE_USERS_ROLE_IN_PROJECT: 'CHANGE_USERS_ROLE_IN_PROJECT',
  REMOVE_USERS_FROM_PROJECT: 'REMOVE_USERS_FROM_PROJECT',

  SEND_INVITE_AND_ASSIGN_MEMBER_REQUEST: 'SEND_INVITE_AND_ASSIGN_MEMBER_REQUEST',
  GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE: 'GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE',
  RESET_INVITE_AND_ASSIGN_MEMBER_MESSAGE: 'RESET_INVITE_AND_ASSIGN_MEMBER_MESSAGE',
  LOAD_NEW_TAGS: 'LOAD_NEW_TAGS',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  CANCE_SUBSCRIPTION: 'CANCE_SUBSCRIPTION',
  GET_SUBSCRIPTION_TRANSACTIONS_SEND: 'GET_SUBSCRIPTION_TRANSACTIONS_SEND',
  GET_SUBSCRIPTION_TRANSACTIONS: 'GET_SUBSCRIPTION_TRANSACTIONS',
  LOAD_NEW_TRANSACTION: 'LOAD_NEW_TRANSACTION',
  SORT_SUBSCRIPTION_TRANSACTIOS: 'SORT_SUBSCRIPTION_TRANSACTIOS',

  ADD_KSEF_INTEGRATION: 'ADD_KSEF_INTEGRATION',
  DELETE_KSEF_INTEGRATION: 'DELETE_KSEF_INTEGRATION',

  DELETE_PROJECT: 'DELETE_PROJECT-COMPANY',
  LOGOUT: 'LOGOUT',
}

const initialState = {
  sendCompanyRegistrationRequest: false,
  gotCompanyRegistrationResponse: false,
  newCompanyRegistrationSuccess: false,
  sendNipUniqnessCheckRequest: false,
  gotNipUniqnessCheckResponse: false,
  isNewNipUnique: false,
  newRegisteredCompany: {
    id: null,
    token: null,
  },
  isNewInvoiceNumberPopupOpen: false,
  newInvoiceNumber: '',
  company: null,
  currentCompany: {},
  promotedUser: false,
  projectWorkers: [],
  companyWorkers: [],
  companyWorkersSortField: 'first_name',
  companyWorkersSortOrder: 'asc',
  inviteAndAssignMemberSendRequest: false,
  inviteAndAssignMemberGotResponse: false,
  inviteAndAssignMemberErrorMsg: "",
  inviteAndAssignMemberError: false,
  hasCompanyWithoutCode: false,


  counterparties: [],
  counterpartiesSortField: "name",
  counterpartiesSortOrder: "asc",

  companySubscriptionTransactions: [],
  sendCompanySubscriptionTransactionsRequest: false,
  companySubscriptionTransactionsSortField: "created",
  companySubscriptionTransactionsSortOrder: "asc",
};

function dynamicComparer(key, order = "asc") {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (!varA) {
      comparison = -1;
    } else if (!varB) {
      comparison = 1;
    } else if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export default (state = initialState, action) => {
  switch (action.type) {

    case CompanyConst.IS_OPEN_INVOICE_NUMBER_POPUP: {
      const { isNewInvoiceNumberPopupOpen, newInvoiceNumber, foundNumberingGap, incorrectMaskForm } = action;
      return { ...state, isNewInvoiceNumberPopupOpen, newInvoiceNumber, foundNumberingGap, incorrectMaskForm };
    }

    case CompanyConst.SEND_CHECK_NIP_REQUEST:
      return {
        ...state,
        ...{
          isNewNipUnique: false,
          gotNipUniqnessCheckResponse: false,
          sendNipUniqnessCheckRequest: true,
        },
      };

    case CompanyConst.CHECK_NIP:
      return {
        ...state,
        ...{
          isNewNipUnique: action.isNewNipUnique,
          gotNipUniqnessCheckResponse: true,
          sendNipUniqnessCheckRequest: false,
          newRegisteredCompany: {
            id: action.newCompanyId,
            token: action.registrationToken,
          },
        },
      };

    case CompanyConst.DELETE_UNFINISHED_REGISTRATION:
      return {
        ...state,
        ...{
          isNewNipUnique: false,
          gotNipUniqnessCheckResponse: false,
          sendNipUniqnessCheckRequest: false,
          newRegisteredCompany: { id: null, token: null },
        },
      };

    case CompanyConst.SEND_REGISTER_COMPANY_REQUEST:
      return {
        ...state,
        ...{
          sendCompanyRegistrationRequest: true,
          newCompanyRegistrationSuccess: false,
          gotCompanyRegistrationResponse: false,
        },
      };

    case CompanyConst.GOT_REGISTER_COMPANY_RESPONSE:
      if (action.success) {
        return {
          ...state,
          ...{
            sendCompanyRegistrationRequest: false,
            newCompanyRegistrationSuccess: true,
            gotCompanyRegistrationResponse: true,
            newRegisteredCompany: { id: null, token: null },
          },
        };
      }
      return {
        ...state,
        ...{
          sendCompanyRegistrationRequest: false,
          newCompanyRegistrationSuccess: false,
          gotCompanyRegistrationResponse: true,
        },
      };

    case CompanyConst.GET_ALL_COMPANIES:
      return {
        ...state,
        ...{
          allCompanies: action.companies,
          companiesSortField: "created",
          companiesSortOrder: "desc",
        },
      };

    case CompanyConst.SORT_ALL_COMPANIES:
      return {
        ...state,
        ...{
          allCompanies: state.allCompanies.sort(
            dynamicComparer(action.sortField, action.sortOrder)
          ),
          companiesSortField: action.sortField,
          companiesSortOrder: action.sortOrder,
        },
      };

    case CompanyConst.UPDATE_USER_COMPANY_INFO:
      if (action.company.tags_list) action.company.tags_list = JSON.parse(action.company.tags_list)
      var userCompanies = JSON.parse(JSON.stringify(state.companies));

      var companyIndex = arrayObjectIndexOf(userCompanies, action.company.company_id, "company_id");
      if (companyIndex > -1) {
        userCompanies[companyIndex].company = action.company.company
      }

      var currentCompany = JSON.parse(JSON.stringify(state.currentCompany));

      if (currentCompany.company_id === action.company.company_id) {
        currentCompany = action.company
      }

      return {
        ...state,
        ...{
          companies: userCompanies,
          currentCompany: currentCompany,
        },
      };

    case CompanyConst.UPDATE_SUBSCRIPTION_TYPE:
      var userCompanies = JSON.parse(JSON.stringify(state.companies));

      var companyIndex = arrayObjectIndexOf(
        userCompanies,
        action.companyId,
        "company_id"
      );
      if (companyIndex > -1) {
        userCompanies[companyIndex].company.subscription_type = action.newPlan;
      }

      var currentCompany = JSON.parse(JSON.stringify(state.currentCompany));

      if (currentCompany.company_id === action.companyId) {
        currentCompany.company.subscription_type = action.newPlan;
      }

      return { ...state, ...{ companies: userCompanies, currentCompany: currentCompany } }

    case CompanyConst.PROMOTED_USER:
      return { ...state, ...{ promotedUser: action.status } };

    case CompanyConst.GET_COMPANY_PROJECTS:
      return { ...state, ...{ companyProjects: action.companyProjects } };

    case CompanyConst.CHANGE_MY_ROLE_IN_PROJECT:
      var companyProjects = state.companyProjects
      var companiesCount = companyProjects.length
      for (var i = 0; i < companiesCount; i++) {
        var projectIndex = arrayObjectIndexOf(companyProjects[i].projects, action.projectId, 'id')
        if (projectIndex > -1) {
          companyProjects[i].projects[projectIndex].accessLevel = action.role
          break;
        }
      }

      return { ...state, ...{ companyProjects: companyProjects } };

    case CompanyConst.GET_COMPANY_COUNTERPARTIES:
      if (
        state.counterpartiesSortField !== "name" ||
        state.counterpartiesSortOrder !== "asc"
      ) {
        return {
          ...state,
          ...{
            counterparties: action.counterparties.sort(
              dynamicComparer(
                state.counterpartiesSortField,
                state.counterpartiesSortOrder
              )
            ),
          },
        };
      } else {
        return { ...state, ...{ counterparties: action.counterparties } };
      }

    case CompanyConst.SORT_COUNTERPARTIES:
      var counterparties = [...state.counterparties];
      var { counterpartiesSortField, counterpartiesSortOrder } = state;

      if (counterpartiesSortField === action.name) {
        if (counterpartiesSortOrder === "asc") {
          counterpartiesSortOrder = "desc";
        } else {
          counterpartiesSortOrder = "asc";
        }
      } else {
        counterpartiesSortField = action.name;
        counterpartiesSortOrder = "asc";
      }
      counterparties.sort(
        dynamicComparer(counterpartiesSortField, counterpartiesSortOrder)
      );

      return {
        ...state,
        ...{
          counterparties: counterparties,
          counterpartiesSortField: counterpartiesSortField,
          counterpartiesSortOrder: counterpartiesSortOrder,
        },
      };

    case CompanyConst.UPDATE_COUNTERPARTY:
      var counterparties = [...state.counterparties];

      var counterpartyIndex = arrayObjectIndexOf(counterparties, action.id, "id");
      if (counterpartyIndex > -1) {
        counterparties[counterpartyIndex].vat_id = action.vatId;
        counterparties[counterpartyIndex].name = action.name;
        counterparties[counterpartyIndex].code = action.code;
        counterparties[counterpartyIndex].address = action.address;
        counterparties[counterpartyIndex].email = action.email;
        counterparties[counterpartyIndex].payment_due = action.paymentDue;
      }

      var currentCompany = state.currentCompany
      var myCompanies = state.companies
      if (currentCompany.company_id === action.companyId && currentCompany.company) {
        var counterpartyIndex = arrayObjectIndexOf(currentCompany.company.counterparties, action.id, "id");
        if (counterpartyIndex > -1) {
          currentCompany.company.counterparties[counterpartyIndex].vat_id = action.vatId;
          currentCompany.company.counterparties[counterpartyIndex].name = action.name;
          currentCompany.company.counterparties[counterpartyIndex].code = action.code;
          currentCompany.company.counterparties[counterpartyIndex].address = action.address;
          currentCompany.company.counterparties[counterpartyIndex].email = action.email;
          currentCompany.company.counterparties[counterpartyIndex].payment_due = action.paymentDue;
        }
      } else {
        var companyIndex = arrayObjectIndexOf(myCompanies, action.companyId, "company_id");
        if (companyIndex > -1 && myCompanies[companyIndex].company) {
          var counterpartyIndex = arrayObjectIndexOf(myCompanies[companyIndex].company.counterparties, action.id, "id");
          if (counterpartyIndex > -1) {
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].vat_id = action.vatId;
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].name = action.name;
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].code = action.code;
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].address = action.address;
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].email = action.email;
            myCompanies[companyIndex].company.counterparties[counterpartyIndex].payment_due = action.paymentDue;
          }
        }
      }

      return { ...state, ...{ counterparties: counterparties, currentCompany: currentCompany, companies: myCompanies } };

    case CompanyConst.ADD_COUNTERPARTY:
      var counterparties = [...state.counterparties];
      counterparties.unshift(action.newCounterparty)

      var currentCompany = state.currentCompany
      var myCompanies = state.companies
      if (currentCompany.company_id === action.companyId && currentCompany.company) {
        currentCompany.company.counterparties.unshift(action.newCounterparty)
      } else {
        var companyIndex = arrayObjectIndexOf(myCompanies, action.companyId, "company_id");
        if (companyIndex > -1 && myCompanies[companyIndex].company) {
          myCompanies[companyIndex].company.counterparties.unshift(action.newCounterparty)
        }
      }

      return { ...state, ...{ counterparties: counterparties, currentCompany: currentCompany, companies: myCompanies } };


    case CompanyConst.ALL_USERS_ASSOCIATED_WITH_COMPANY:
      return {
        ...state,
        ...{
          companyWorkers: action.companyUsers.sort(
            dynamicComparer(
              state.companyWorkersSortField,
              state.companyWorkersSortOrder
            )
          ),
          workersCount: action.count,
          promotedUser: false,
          usersList: action.usersList,
        },
      };

    case CompanyConst.CHANGE_COMPANY_WORKERS_SORTING:
      var { companyWorkersSortField, companyWorkersSortOrder } = state;

      if (companyWorkersSortField === action.field) {
        if (companyWorkersSortOrder === "asc") {
          companyWorkersSortOrder = "desc";
        } else {
          companyWorkersSortOrder = "asc";
        }
      } else {
        companyWorkersSortField = action.field;
        companyWorkersSortOrder = "asc";
      }
      var sortedCompanyWorkers = state.companyWorkers.sort(
        dynamicComparer(companyWorkersSortField, companyWorkersSortOrder)
      );
      return {
        ...state,
        ...{
          companyWorkers: sortedCompanyWorkers,
          companyWorkersSortField: companyWorkersSortField,
          companyWorkersSortOrder: companyWorkersSortOrder,
        },
      };

    case CompanyConst.REMOVE_MEMBER:
      var newCompanyWorkers = state.companyWorkers
      var newProjectWorkers = state.projectWorkers.filter(e => e.id !== action.memberId)
      var companyProjects = state.companyProjects
      var currentCompany = JSON.parse(JSON.stringify(state.currentCompany))
      var companies = JSON.parse(JSON.stringify(state.companies))

      newCompanyWorkers.forEach(c => {
        if (c.company && c.company.id === action.companyId) {
          c.members = c.members.filter(u => u.id !== action.memberId)
        }
      })

      if (currentCompany.company_id === action.companyId && currentCompany.company) {
        currentCompany.company.members_count -= 1;
      }

      var companyIndex = arrayObjectIndexOf(companies, action.companyId, "company_id");
      if (companyIndex > -1) {
        companies[companyIndex].company.members_count -= 1;
      }

      companyProjects.forEach(g => {
        g.projects.forEach(p => {
          p.members = p.members.filter(e => e.id !== action.memberId)
        })
      })

      return {
        ...state,
        ...{
          companyWorkers: newCompanyWorkers,
          projectWorkers: newProjectWorkers || [],
          companyProjects: companyProjects,
          currentCompany: currentCompany,
          companies: companies,
        },
      };

    case CompanyConst.CHANGE_MEMBER_ROLE:
      var companyMembers = state.companyWorkers
      var companiesCount = companyMembers.length
      for (var i = 0; i < companiesCount; i++) {
        console.log(companyMembers[i])
        console.log(companyMembers[i].company)
        if (companyMembers[i].company && companyMembers[i].company.id === action.companyId) {
          var memberIndex = arrayObjectIndexOf(companyMembers[i].members, action.memberId, 'id')
          if (memberIndex > -1) {
            if (action.newRole === 'ACCOUNTANT') {
              companyMembers[i].members[memberIndex].is_accountant = !companyMembers[i].members[memberIndex].is_accountant
            } else {
              companyMembers[i].members[memberIndex].role = action.newRole
            }
            return { ...state, ...{ companyWorkers: companyMembers } }
          } else {
            return state
          }
        }
      }

    case CompanyConst.PROJECT_WORKERS:
      return { ...state, ...{ projectWorkers: action.projectWorkers || [] } };

    case CompanyConst.USER_COMPANIES:
      var hasCompanyWithoutCode = false;
      var companyToUpdate = {};

      action.companies.forEach((c) => {
        if (c.user_role === "ADMIN") {
          if (c.company && !c.company.code) {
            console.log(c.company);
            hasCompanyWithoutCode = true;
            companyToUpdate = {
              id: c.company.id,
              name: c.company.name,
            };
          }
        }
      });
      return {
        ...state,
        ...{
          companies: action.companies,
          hasCompanyWithoutCode: hasCompanyWithoutCode,
          companyToUpdate: companyToUpdate,
        },
      };

    case CompanyConst.SET_CURRENT_COMPANY:
      return {
        ...state,
        ...{
          currentCompany: action.company,
          inviteAndAssignMemberSendRequest: false,
          inviteAndAssignMemberGotResponse: false,
          inviteAndAssignMemberErrorMsg: "",
          inviteAndAssignMemberError: false,
        },
      };

    case CompanyConst.PROJECT_WORKERS_EDIT:
      var projectWorkers = [...state.projectWorkers];
      projectWorkers.filter(
        (x) => !action.userdToDelete.includes((e) => e.id === x.id)
      );

      action.usersToAdd.forEach((e) => {
        projectWorkers.push(e);
      });

      return { ...state, ...{ projectWorkers: projectWorkers || [] } };

    case CompanyConst.UPDATE_PROJECT_MEMBERS:
      var tempCompanyProjects = [...state.companyProjects]
      var groupsCount = tempCompanyProjects.length
      for (var i = 0; i < groupsCount; i++) {
        var projectIndex = arrayObjectIndexOf(tempCompanyProjects[i].projects, action.projectId, 'id')
        if (projectIndex > -1) {
          var companyUsers = tempCompanyProjects[i].company.users || []
          action.usersToAdd.forEach(u => {
            var user = companyUsers.find(e => e.id === u)
            if (user) tempCompanyProjects[i].projects[projectIndex].members.push(user)
          })
          tempCompanyProjects[i].projects[projectIndex].members = tempCompanyProjects[i].projects[projectIndex].members.filter(e => !action.usersToRemove.includes(e.id))
          break
        }
      }
      return { ...state, ...{ companyProjects: tempCompanyProjects } }

    case CompanyConst.UPDATE_PROJECT_DROPZONE_MANAGERS:
      var tempCompanyProjects = [...state.companyProjects]
      var groupsCount = tempCompanyProjects.length
      for (var i = 0; i < groupsCount; i++) {
        var projectIndex = arrayObjectIndexOf(tempCompanyProjects[i].projects, action.projectId, 'id')
        console.log(projectIndex)
        if (projectIndex > -1) {
          var companyUsers = tempCompanyProjects[i].company.users || []
          action.usersToAdd.forEach(u => {
            var user = companyUsers.find(e => e.id === u)
            if (user) tempCompanyProjects[i].projects[projectIndex].dropzoneManagers.push(user)
          })
          tempCompanyProjects[i].projects[projectIndex].dropzoneManagers = tempCompanyProjects[i].projects[projectIndex].dropzoneManagers.filter(e => !action.usersToRemove.includes(e.id))
          break
        }
      }
      return { ...state, ...{ companyProjects: tempCompanyProjects } }

    case CompanyConst.LOGOUT:
      return {
        initialState
      }

    case CompanyConst.REMOVE_USERS_FROM_PROJECT:
      var currentProjectWorkers = [...state.projectWorkers]
      var companyProjects = [...state.companyProjects]

      currentProjectWorkers = currentProjectWorkers.filter(e => !action.users.includes(e.id))

      var groupsCount = companyProjects.length
      for (var i = 0; i < groupsCount; i++) {
        var projectIndex = arrayObjectIndexOf(companyProjects[i].projects, action.projectId, "id")
        if (projectIndex > -1) {
          action.users.forEach(id => {
            companyProjects[i].projects[projectIndex].members = companyProjects[i].projects[projectIndex].members.filter(e => e.id !== id)
          })
          break
        }
      }

      return { ...state, ...{ projectWorkers: currentProjectWorkers || [], companyProjects: companyProjects } }

    case CompanyConst.RESET_INVITE_AND_ASSIGN_MEMBER_MESSAGE:
      return {
        ...state,
        ...{
          inviteAndAssignMemberSendRequest: false,
          inviteAndAssignMemberError: false,
          inviteAndAssignMemberErrorMsg: "",
          inviteAndAssignMemberGotResponse: false,
        },
      };

    case CompanyConst.GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE:
      if (action.user) {
        var { companyWorkers, projectWorkers, companyProjects } = state;
        var currentCompany = JSON.parse(JSON.stringify(state.currentCompany));
        var companies = JSON.parse(JSON.stringify(state.companies));

        console.log(currentCompany);
        console.log(companies);

        companyWorkers.forEach(c => {
          if (c.company && c.company.id === action.companyId) {
            c.members.push({
              id: action.user.id,
              first_name: action.user.first_name,
              last_name: action.user.last_name,
              email: action.user.email,
              profile_pic_link: action.user.profile_pic_link,
              projects: action.user.projects,
              role: "PROJECE_MEMBER",
              created: action.user.created,
            })
          }
        })

        projectWorkers.push({
          id: action.user.id,
          first_name: action.user.first_name,
          last_name: action.user.last_name,
          email: action.user.email,
          profile_pic_link: action.user.profile_pic_link,
          role: "USER1",
        });

        var projectIndex = -1
        var groupsCount = companyProjects.length

        action.projects.forEach(p => {
          for (var i = 0; i < groupsCount; i++) {
            projectIndex = arrayObjectIndexOf(companyProjects[i].projects, p, "id")
            if (projectIndex > -1) {
              companyProjects[i].projects[projectIndex].members.push({
                id: action.user.id,
                first_name: action.user.first_name,
                last_name: action.user.last_name,
                email: action.user.email,
                photo: action.user.profile_pic_link,
                role: 'USER1',
              })
              break
            }
          }
        });

        if (currentCompany.company) {
          currentCompany.company.members_count += 1;
        }

        // var companyIndex = arrayObjectIndexOf(companies, action.companyId, 'company_id')
        // if (companyIndex > -1) {
        //   companies[companyIndex].company.members_count += 1
        // }

        return {
          ...state,
          ...{
            inviteAndAssignMemberSendRequest: false,
            inviteAndAssignMemberError: false,
            inviteAndAssignMemberErrorMsg: "",
            inviteAndAssignMemberGotResponse: true,
            companyWorkers: companyWorkers,
            projectWorkers: projectWorkers || [],
            companyProjects: companyProjects,
            currentCompany: currentCompany,
            companies: companies,
          },
        };
      } else {
        return {
          ...state,
          ...{
            inviteAndAssignMemberSendRequest: false,
            inviteAndAssignMemberError: true,
            inviteAndAssignMemberErrorMsg: action.error,
            inviteAndAssignMemberGotResponse: true,
          },
        };
      }

    case CompanyConst.SEND_INVITE_AND_ASSIGN_MEMBER_REQUEST:
      return {
        ...state,
        ...{
          inviteAndAssignMemberSendRequest: true,
          inviteAndAssignMemberError: false,
          inviteAndAssignMemberErrorMsg: '',
          inviteAndAssignMemberGotResponse: false
        }
      }

    case CompanyConst.CHANGE_USERS_ROLE_IN_PROJECT:
      var currentProjectWorkers = [...state.projectWorkers]
      var usersToAdd = action.users

      currentProjectWorkers.forEach(user => {
        console.log(user.id)
        if (action.users.includes(user.id)) {
          user.role = `USER${action.role}`

          usersToAdd = usersToAdd.filter(e => e !== user.id)
          console.log("usersToAdd")
          console.log(usersToAdd)
        }
      })

      var companyUsersList = state.usersList.find(e => e.company.id === action.companyId)
      if (companyUsersList) companyUsersList = companyUsersList.members

      usersToAdd.forEach(user => {
        var userToAdd = companyUsersList.find(e => e.id === user)
        var newUser = {
          id: user,
          first_name: userToAdd.first_name,
          last_name: userToAdd.last_name,
          email: userToAdd.email,
          role: '',
          profile_pic_link: userToAdd.profile_pic_link
        }
        newUser.role = `USER${action.role}`

        currentProjectWorkers.push(newUser)
      })

      return { ...state, ...{ projectWorkers: currentProjectWorkers || [] } }

    case CompanyConst.LOAD_NEW_TAGS:
      var currentCompany = { ...state.currentCompany };
      var companies = [...state.companies];

      if (
        currentCompany &&
        currentCompany.company_id === action.companyId &&
        currentCompany.company
      ) {
        currentCompany.company.tags_list = JSON.parse(action.newTags);
      }

      var companiesCount = companies.length;
      for (var i = 0; i < companiesCount; i++) {
        if (companies[i].company_id === action.companyId) {
          if (companies[i].company) {
            companies[i].company.tags_list = JSON.parse(action.newTags);
          }
          break;
        }
      }
      return {
        ...state,
        ...{ currentCompany: currentCompany, companies: companies },
      };

    case CompanyConst.UPDATE_SUBSCRIPTION:
      var currentCompany = { ...state.currentCompany };
      var companies = [...state.companies];

      if (currentCompany && currentCompany.company_id === action.companyId && currentCompany.company) {
        currentCompany.company.subscription_expire_date = action.subscriptionExpireDate;
        currentCompany.company.payment_attempt_number = 0
        if (currentCompany.company.subscription_type === "FREE") {
          currentCompany.company.subscription_type = "ACTIVE";
        }
      }

      var companiesCount = companies.length;
      for (var i = 0; i < companiesCount; i++) {
        if (companies[i].company_id === action.companyId) {
          if (companies[i].company) {
            companies[i].company.subscription_expire_date = action.subscriptionExpireDate;
            companies[i].company.payment_attempt_number = 0
            if (companies[i].company.subscription_type === "FREE") {
              companies[i].company.subscription_type = "ACTIVE";
            }
          }
          break;
        }
      }
      return {
        ...state,
        ...{ currentCompany: currentCompany, companies: companies },
      };

    case CompanyConst.CANCE_SUBSCRIPTION:
      var currentCompany = { ...state.currentCompany };
      var companies = [...state.companies];

      if (
        currentCompany &&
        currentCompany.company_id === action.companyId &&
        currentCompany.company
      ) {
        currentCompany.company.subscription_type = "CANCELED";
      }

      var companiesCount = companies.length;
      for (var i = 0; i < companiesCount; i++) {
        if (companies[i].company_id === action.companyId) {
          if (companies[i].company) {
            companies[i].company.subscription_type = "CANCELED";
          }
          break;
        }
      }
      return {
        ...state,
        ...{ currentCompany: currentCompany, companies: companies },
      };

    case CompanyConst.GET_SUBSCRIPTION_TRANSACTIONS_SEND:
      return {
        ...state,
        ...{ sendCompanySubscriptionTransactionsRequest: true },
      };

    case CompanyConst.GET_SUBSCRIPTION_TRANSACTIONS:
      var currentCompany = { ...state.currentCompany };

      if (currentCompany.company_id === action.companyId) {
        return {
          ...state,
          ...{
            companySubscriptionTransactions: action.transactions,
            sendCompanySubscriptionTransactionsRequest: false,
          },
        };
      } else {
        return {
          ...state,
          ...{ sendCompanySubscriptionTransactionsRequest: false },
        };
      }

    case CompanyConst.LOAD_NEW_TRANSACTION:
      var currentCompany = { ...state.currentCompany };

      if (currentCompany.company_id === action.transaction.company_id) {
        var currentTransactions = state.companySubscriptionTransactions;
        currentTransactions.unshift(action.transaction);
        return {
          ...state,
          ...{ companySubscriptionTransactions: currentTransactions },
        };
      }

    case CompanyConst.SORT_SUBSCRIPTION_TRANSACTIOS:
      var companySubscriptionTransactions = [
        ...state.companySubscriptionTransactions,
      ];
      var {
        companySubscriptionTransactionsSortField,
        companySubscriptionTransactionsSortOrder,
      } = state;

      if (companySubscriptionTransactionsSortField === action.field) {
        if (companySubscriptionTransactionsSortOrder === "asc") {
          companySubscriptionTransactionsSortOrder = "desc";
        } else {
          companySubscriptionTransactionsSortOrder = "asc";
        }
      } else {
        companySubscriptionTransactionsSortField = action.field;
        companySubscriptionTransactionsSortOrder = "asc";
      }
      companySubscriptionTransactions.sort(
        dynamicComparer(
          companySubscriptionTransactionsSortField,
          companySubscriptionTransactionsSortOrder
        )
      );

      return {
        ...state,
        ...{
          companySubscriptionTransactions: companySubscriptionTransactions,
          companySubscriptionTransactionsSortField:
            companySubscriptionTransactionsSortField,
          companySubscriptionTransactionsSortOrder:
            companySubscriptionTransactionsSortOrder,
        },
      };

    case CompanyConst.ADD_KSEF_INTEGRATION:
      var currentCompany = { ...state.currentCompany };
      var companies = [...state.companies];
      if (currentCompany.company_id === action.companyId && currentCompany.company) {
        currentCompany.company.ksef_integration_id = action.integrationId
      }
      companies.forEach(c => {
        if (c.company_id === action.companyId && c.company) {
          c.company.ksef_integration_id = action.integrationId
        }
      })
      return { ...state, ...{ currentCompany: currentCompany, companies: companies } }

    case CompanyConst.DELETE_KSEF_INTEGRATION:
      var currentCompany = { ...state.currentCompany };
      var companies = [...state.companies];
      if (currentCompany.company_id === action.companyId && currentCompany.company) {
        currentCompany.company.ksef_integration_id = null
      }
      companies.forEach(c => {
        if (c.company_id === action.companyId && c.company) {
          c.company.ksef_integration_id = null
        }
      })
      return { ...state, ...{ currentCompany: currentCompany, companies: companies } }

    case CompanyConst.DELETE_PROJECT:
      var companyProjects = []
      if (state.companyProjects) {
        companyProjects = [...state.companyProjects];
      }

      var groupsCount = companyProjects.length
      for (var i = 0; i < groupsCount; i++) {
        companyProjects[i].projects = companyProjects[i].projects.filter(p => p.id !== action.projectId)
      }

      return { ...state, ...{ companyProjects: companyProjects } }

    default:
      return state;
  }
};
