import React from 'react';

const getFieldStyle = (type) => {
    const stageStyles = {
        textAlign: 'center',
        width: '90px',
        padding: '5px 0',
        backgroundColor: '#FFFFFF',
        fontSize: '12px',
        color: '#4D5259',
        borderRadius: '4px',
        border: '1px solid #4D5259',
    };

    const eventStyles = {
        textAlign: 'center',
        width: '90px',
        padding: '5px 0',
        backgroundColor: '#694BB0',
        fontSize: '12px',
        color: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #694BB0',
    };

    if (type === 'STAGE') {
        return stageStyles;
    } else if (type === 'EVENT') {
        return eventStyles;
    }
}

const DragPreview = ({ item, translate }) => {
    if (item) {
        return (
            <div style={getFieldStyle(item.itemCategory === 'stage' ? 'STAGE' : 'EVENT')} key={item.dataType}>
                {translate(item.title)}
            </div>
        )
    } else {
        return (<div></div>)
    }
};

export default DragPreview;