const aventineProjects = [
    633,    //AVENTINE
]

const aventineLevel1 = [
    {
        "description": "Legal",
        "code": "Legal"
    },
    {
        "description": "Project",
        "code": "Project"
    },
    {
        "description": "General",
        "code": "General"
    }
]

const aventineLevel2 = [
    {
        "description": "PLRPKMTWAW001 - Jabłonna",
        "code": "PLRPKMTWAW001"
    },
    {
        "description": "PLRPKMTRZW002 - Rzeszow - Jasionka",
        "code": "PLRPKMTRZW002"
    },
    {
        "description": "PLRPKMTJWR008 - Jawor",
        "code": "PLRPKMTJWR008"
    },
    {
        "description": "PLREXBKPEJG022 - Jelenia Góra",
        "code": "PLREXBKPEJG022"
    },
    {
        "description": "PLRPKMTKTZ021 - Krotoszyn",
        "code": "PLRPKMTKTZ021"
    },
    {
        "description": "PLRPKMTKBK019 - Kluczbork",
        "code": "PLRPKMTKBK019"
    },
    {
        "description": "PLRPKMTMLC011 - Mielec",
        "code": "PLRPKMTMLC011"
    },
    {
        "description": "PLRPKMTMSZ020 - Mszana",
        "code": "PLRPKMTMSZ020"
    },
    {
        "description": "PLRPKMTSMA018 - Sokołów Małopolski",
        "code": "PLRPKMTSMA018"
    },
    {
        "description": "PLREXPECZW004 - Chorzow",
        "code": "PLREXPECZW004"
    },
    {
        "description": "PLREXPECZT005 - Częstochowa",
        "code": "PLREXPECZT005"
    },
    {
        "description": "Emilianów",
        "code": "Emilianów"
    },
    {
        "description": "PLREXPBFRP023 - Franowo - Poznan",
        "code": "PLREXPBFRP023"
    },
    {
        "description": "PLREXBLSCL006 - Suchy Las",
        "code": "PLREXBLSCL006"
    },
    {
        "description": "PLRPKMTBIA017 - Białystok",
        "code": "PLRPKMTBIA017"
    },
    {
        "description": "PLRPKMTBBA016 - Bielsko Białe",
        "code": "PLRPKMTBBA016"
    },
    {
        "description": "PLRPKMTJSW003 - Jaroslaw - Krakowska",
        "code": "PLRPKMTJSW003"
    },
    {
        "description": "PLRPKMTKRS015 - Krakow Skotnicka",
        "code": "PLRPKMTKRS015"
    },
    {
        "description": "PLRPKMTRZP014 - Rzeszów - Przemysłowa",
        "code": "PLRPKMTRZP014"
    },
    {
        "description": "PLRPKMTSTA012 - Starachowice",
        "code": "PLRPKMTSTA012"
    },
    {
        "description": "PLREXPEWRW013 - Wrocław",
        "code": "PLREXPEWRW013"
    },
    {
        "description": "PLMAXMTWAW009 - Puławska",
        "code": "PLMAXMTWAW009"
    },
    {
        "description": "PLRPKMTPSW024 - Pszów",
        "code": "PLRPKMTPSW024"
    }
]

const aventineMpkSettings = {
    levelsCount: 2,
    hasDependentSublevels: false,
    level1List: aventineLevel1,
    level2List: aventineLevel2,
    level3List: [],
    level4List: [],
}

module.exports = {
    aventineProjects,
    aventineMpkSettings,
};