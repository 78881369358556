import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner } from 'react-activity'

import { AlertActions } from '../../../actions/AlertActions'
import { DocflowActions } from '../../../actions/DocflowActions'

export class DocflowStageEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    render() {
        const { docflowToArchive } = this.props
        const {
        } = this.state

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow">
                    <h4>
                        {this.context.t('Are you sure you want to archive "{d}" docflow?', { d: docflowToArchive.name })}
                    </h4>

                    <div className="settings-section stage-settings">
                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className={`button save`} onClick={() => this.props.archive()}>
                                {
                                    this.context.t('Archive')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowStageEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
})

const mapDispatchToProps = {
    getProjectMembersForDocflow: DocflowActions.getProjectMembersForDocflow,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowStageEditWindow)