import { combineReducers } from 'redux'
import { i18nReducer } from 'react-redux-i18n';
import User from './UserReducer'
import Company from './CompanyReducer'
import File from './FileReducer'
import Alert from './AlertReducer'
import Event from './EventReducer'
import Messages from './MessagesReducer'
import Transfers from './TransfersReducer'
import ProjectCosts from './ProjectCostsReducer'
import Buttons from './ButtonsReducer'
import Reports from './ReportsReducer'
import ExcelSession from './ExcelSessionReducer'
import QuickActions from './QuickActionsReducer'
import Chat from './ChatReducer'
import Docflow from './DocflowReducer'
import V2_TabsCronroller from './V2_TabsReducer'

import { i18nState } from "redux-i18n"

export default combineReducers({
    i18nState,
    i18n: i18nReducer,
    User,
    Company,
    File,
    Alert,
    Event,
    Messages,
    Transfers,
    ProjectCosts,
    Buttons,
    Reports,
    ExcelSession,
    QuickActions,
    Chat,
    Docflow,
    V2_TabsCronroller
})