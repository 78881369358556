import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ProjectSwitcher from '../ProjectSwitcher'

import arrayObjectIndexOf from '../../helpers/indexOfObject';

import { CompanyActions } from '../../actions/CompanyActions'

export class QuickActionPopupHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showProjectsList: false,
            selectedProject: {
                name: '<expand list>',
                id: null
            }
        }

        this.projectSwitcherRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        const { currentProject } = this.props

        if (currentProject) {
            this.setState({
                selectedProject: {
                    name: currentProject.code,
                    id: currentProject.id
                }
            })
        }
    }

    omponentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        const { showProjectsList } = this.state
        if (showProjectsList && this.projectSwitcherRef && this.projectSwitcherRef.current && !this.projectSwitcherRef.current.contains(event.target)) {
            console.log('HERE')
            this.setState({
                showProjectsList: false
            })
        }
    }

    setCurrentProject = (id, companyId, e) => {
        const { selectedProject } = this.state
        const { userProjects } = this.props

        const project = userProjects.find(p => p.id === id)

        if (selectedProject && selectedProject.id !== id) {
            this.setState({
                selectedProject: { id: id, name: project.code },
                showProjectsList: false
            }, () => {
                this.props.getDocs(id)

                var { userCompanies, currentCompany } = this.props
                if (currentCompany && currentCompany.company_id !== companyId) {
                    var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
                    console.log(companyIndex)
                    if (companyIndex > -1) {
                        if (userCompanies[companyIndex].user_role === 'ADMIN') {
                            this.props.setCurrentCompany(userCompanies[companyIndex])
                        }
                    }
                }
            })
        }
    }

    toggleProjectsList = show => {
        this.setState({
            showProjectsList: show
        })
    }

    render() {
        const { showProjectsList, selectedProject } = this.state
        const { title, type, currentProject, userProjects, user } = this.props
        return (
            <div className='quick-action-popup-header'>
                <h3>
                    {
                        userProjects && userProjects.length > 1 ? (
                            <>
                                <span className="title"> {this.context.t(title)}</span>
                                {
                                    !['docsRequiringAttentionAssistane', 'docflowAssistant'].includes(type) ? (
                                        <span className="project can-switch" onClick={() => this.toggleProjectsList(true)} ref={this.projectSwitcherRef}>
                                            {currentProject.code}
                                            <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`} onClick={() => this.toggleProjectsList(!showProjectsList)}></span>

                                            {
                                                showProjectsList ? (
                                                    <ProjectSwitcher
                                                        closeProjectsList={this.setCurrentProject}
                                                        showHeader={true}
                                                        showAll={true}
                                                        selectedProject={selectedProject}
                                                        multiSelect={false}
                                                        returnSelectedProjects={() => { }}
                                                        showOnlyPaymentProjects={type === 'paymentAssistane'}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                        </span>
                                    ) : null
                                }

                            </>
                        ) : (
                            <>
                                <span className="project">{currentProject.code}:</span>
                                <span className="title"> {this.context.t(title)}</span>
                            </>
                        )
                    }

                </h3>
                {
                    !user.new_easydocs_version ? (
                        <div className="close-button" onClick={() => this.props.close()}></div>
                    ) : null
                }

            </div>
        )
    }
}

QuickActionPopupHeader.contextTypes = {
    t: PropTypes.func
}


const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
})

const mapDispatchToProps = {
    setCurrentCompany: CompanyActions.setCurrentCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickActionPopupHeader)

