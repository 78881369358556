import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TagsComponent from '../TagsComponent';
import OcrPageWaproNettoAccounts from '../wapro/OcrPageWaproNettoAccounts'

import { FileActions } from '../../../actions/FileActions';
import { AlertActions } from '../../../actions/AlertActions';

import MpkListSelector from './MpkListSelector'

const { waProjects, waMpkSettings } = require('../../../helpers/MpkSplit/wineAvenue')
const { aventineProjects, aventineMpkSettings } = require('../../../helpers/MpkSplit/aventine')
const { notelProjects, notelMpkSettings } = require('../../../helpers/MpkSplit/notel')
const { bajkaPanaKleksaProjects, bajkaPanaKleksaMpkSettings } = require('../../../helpers/MpkSplit/bajkaPanaKleksa')


export class MpkSplit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showList: false,
            levelsCount: 0,
            hasDependentSublevels: false,
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        const { projectId } = this.props
        var mpkSettings = {}
        if (waProjects.includes(projectId)) {
            mpkSettings = waMpkSettings
        } else if (aventineProjects.includes(projectId)) {
            mpkSettings = aventineMpkSettings
        } else if (notelProjects.includes(projectId)) {
            mpkSettings = notelMpkSettings
        } else if (bajkaPanaKleksaProjects.includes(projectId)) {
            mpkSettings = bajkaPanaKleksaMpkSettings
        }

        const { isInDocPreview, dataIsSyncesInOcr } = this.props
        if (!isInDocPreview && dataIsSyncesInOcr) {
            this.addFirstMpkSplit()
        }

        this.setState({
            ...mpkSettings
        })

        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { totalNetAmount, fileId, ftvId, dataIsSyncesInOcr } = this.props
        var mpkSplit = [...this.props.mpkSplit]

        console.log(prevProps.fileId, prevProps.totalNetAmount)
        console.log(fileId, totalNetAmount)

        if (!prevProps.dataIsSyncesInOcr && dataIsSyncesInOcr) {
            this.addFirstMpkSplit()
        }

        if (totalNetAmount !== prevProps.totalNetAmount && mpkSplit?.length === 1 && prevProps.dataIsSyncesInOcr && dataIsSyncesInOcr) {
            mpkSplit[0].netAmount = totalNetAmount
            this.props.setMpkSplit(mpkSplit)

            if (mpkSplit[0].id > 0 && this.props.fileId) {
                this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[0].id, mpkSplit[0])
            }
        }
    }

    componentWillUnmount() {
    }

    onNetChange = (e, type, index) => {
        var mpkSplit = [...this.props.mpkSplit]
        var { value } = e.target
        value = value.replace(/[^0-9,.-]/gm, '').replace(',', '.')
        mpkSplit[index].netAmount = value
        this.props.setMpkSplit(mpkSplit)
    }

    selectMpk = (index, level, value) => {
        const { hasDependentSublevels } = this.state
        var mpkSplit = [...this.props.mpkSplit]
        var mpkDescriptionField = `mpkLevel${level}`
        var mpkCodeField = `mpkCodeLevel${level}`
        if (mpkSplit[index][mpkCodeField] !== value.code) {
            mpkSplit[index][mpkDescriptionField] = value.description
            mpkSplit[index][mpkCodeField] = value.code
        } else {
            mpkSplit[index][mpkDescriptionField] = ''
            mpkSplit[index][mpkCodeField] = ''
        }

        if (hasDependentSublevels) {
            if (mpkSplit[index][`mpkCodeLevel${level + 1}`]) {
                mpkSplit[index][`mpkLevel${level + 1}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 1}`] = ''
            }
            this.setState({
                [`level${level + 1}List`]: value.nextLevel || []
            })

            if (mpkSplit[index][`mpkCodeLevel${level + 2}`]) {
                mpkSplit[index][`mpkLevel${level + 2}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 2}`] = ''
                this.setState({
                    [`level${level + 2}List`]: []
                })
            }

            if (mpkSplit[index][`mpkCodeLevel${level + 3}`]) {
                mpkSplit[index][`mpkLevel${level + 3}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 3}`] = ''
                this.setState({
                    [`level${level + 3}List`]: []
                })
            }
        }

        this.props.setMpkSplit(mpkSplit)

        if (mpkSplit[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[index].id, mpkSplit[index])
        }
    }

    setWaproNetAccount = (value, index) => {
        var mpkSplit = [...this.props.mpkSplit]
        mpkSplit[index].waproNetAccount = value
        this.props.setMpkSplit(mpkSplit)

        if (mpkSplit[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[index].id, mpkSplit[index])
        }
    }

    onFieldBlur = index => {
        var mpkSplit = [...this.props.mpkSplit]
        if (mpkSplit[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[index].id, mpkSplit[index])
        }
    }

    addMpkSplit = () => {
        var mpkSplit = [...this.props.mpkSplit]
        mpkSplit.push({
            id: -1,
            netAmount: '',
            mpkLevel1: '',
            mpkCodeLevel1: '',
            mpkLevel2: '',
            mpkCodeLevel2: '',
            mpkLevel3: '',
            mpkCodeLevel3: '',
            mpkLevel4: '',
            mpkCodeLevel4: '',
            waproNetAccount: ''
        })
        var newSplitIndex = mpkSplit.length - 1

        this.props.setMpkSplit(mpkSplit)

        if (this.props.fileId) {
            this.props.addMpkSplitInstance(this.props.fileId, this.props.ftvId, null, splitId => {
                if (splitId) {
                    mpkSplit[newSplitIndex].id = splitId
                    this.props.setMpkSplit(mpkSplit)
                }
            })
        }
    }

    addFirstMpkSplit = () => {
        const { mpkSplit, totalNetAmount, fileId } = this.props
        console.log(mpkSplit?.length)
        if (mpkSplit?.length) {
            console.log('inside if')
            var mpkSplitWineAvenueCopy = []
            mpkSplitWineAvenueCopy.push({
                id: -1,
                netAmount: totalNetAmount || '',
                mpkLevel1: '',
                mpkCodeLevel1: '',
                mpkLevel2: '',
                mpkCodeLevel2: '',
                mpkLevel3: '',
                mpkCodeLevel3: '',
                mpkLevel4: '',
                mpkCodeLevel4: '',
                waproNetAccount: ''
            })
            this.props.setMpkSplit(mpkSplitWineAvenueCopy)
        }

        if (fileId) {
            this.props.addFirstMpkSplitInstance(this.props.fileId, this.props.ftvId, totalNetAmount || '', mpkSplit => {
                console.log('mpkSplit from API', mpkSplit)
                this.props.setMpkSplit(mpkSplit)

                const { hasDependentSublevels, level1List } = this.state
                if (hasDependentSublevels && mpkSplit.length) {
                    var level1Mpk, level2Mpk, level3Mpk
                    level1Mpk = level1List.find(el => el.code === mpkSplit[0].mpkCodeLevel1)
                    if (level1Mpk) level2Mpk = level1Mpk.nextLevel?.find(el => el.code === mpkSplit[0]?.mpkCodeLevel2)
                    if (level2Mpk) level3Mpk = level2Mpk.nextLevel?.find(el => el.code === mpkSplit[0]?.mpkCodeLevel3)

                    this.setState({
                        level2List: level1Mpk?.nextLevel || [],
                        level3List: level2Mpk?.nextLevel || [],
                        level4List: level3Mpk?.nextLevel || []
                    })
                }
            })
        }
    }

    deleteCostSplit = (index) => {
        var mpkSplit = [...this.props.mpkSplit]
        const splitToDelete = mpkSplit[index]
        mpkSplit.splice(index, 1)

        this.props.setMpkSplit(mpkSplit)

        if (splitToDelete.id > 0 && this.props.fileId) {
            this.props.deleteMpkSplitInstance(this.props.fileId, this.props.ftvId, splitToDelete.id)
        }
    }

    showDevideEqually = () => {
        this.setState({
            showDivideEquallyWindow: true,
            divideEquallyInput: ''
        })
    }

    divideEqually = () => {
        const { divideEquallyInput } = this.state
        if (!divideEquallyInput) {
            this.props.alertWarn(this.context.t('You must specify how many parts the cost is to be divided into'))
        } else {
            var divideEquallyNumber = parseFloat(divideEquallyInput)
            if (divideEquallyNumber > 30) {
                this.props.alertWarn(this.context.t('You cannot divide the cost into more than {n} parts', { n: 30 }))
            } else if (divideEquallyNumber < 2) {
                this.props.alertWarn(this.context.t('You must divide it into at least 2 parts'))
            } else {
                const { totalNetAmount } = this.props
                if (totalNetAmount) {
                    var netPart = parseFloat(parseFloat(totalNetAmount) / divideEquallyNumber).toFixed(2)
                    var netDiff = 0
                    if (parseFloat(netPart) * divideEquallyNumber !== parseFloat(totalNetAmount)) {
                        netDiff = parseFloat(totalNetAmount) - parseFloat(netPart) * divideEquallyNumber
                    }

                    var mpkSplit = [...this.props.mpkSplit]
                    for (let i = 0; i < divideEquallyNumber; i++) {
                        if (i === 0) {
                            var firstNetPart = parseFloat(parseFloat(netPart) + parseFloat(netDiff)).toFixed(2)
                            mpkSplit[i].netAmount = firstNetPart
                            this.props.setMpkSplit(mpkSplit)
                            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[i].id, mpkSplit[i])
                        } else if (mpkSplit[i]) {
                            mpkSplit[i].netAmount = netPart
                            this.props.setMpkSplit(mpkSplit)
                            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[i].id, mpkSplit[i])
                        } else {
                            mpkSplit.push({
                                id: -1,
                                netAmount: netPart,
                                mpkLevel1: '',
                                mpkCodeLevel1: '',
                                mpkLevel2: '',
                                mpkCodeLevel2: '',
                                mpkLevel3: '',
                                mpkCodeLevel3: '',
                                mpkLevel4: '',
                                mpkCodeLevel4: '',
                                waproNetAccount: ''
                            })
                            this.props.addMpkSplitInstance(this.props.fileId, this.props.ftvId, {
                                netAmount: netPart,
                            }, splitId => {
                                if (splitId) {
                                    mpkSplit[i].id = splitId
                                    this.props.setMpkSplit(mpkSplit)
                                }
                            })
                        }
                    }

                    if (divideEquallyNumber < mpkSplit.length) {
                        var newMpkSplitAllocation = []
                        for (let j = 0; j < mpkSplit.length; j++) {
                            if (j >= divideEquallyNumber && mpkSplit[j]) {
                                this.props.deleteMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplit[j].id)
                            } else {
                                newMpkSplitAllocation.push(mpkSplit[j])
                            }
                        }
                        this.props.setMpkSplit(newMpkSplitAllocation)
                    }

                    this.setState({
                        showDivideEquallyWindow: false,
                        divideEquallyInput: ''
                    })
                } else {
                    this.props.alertWarn(this.context.t('First, enter the net amount for the entire document'))
                }
            }
        }
    }

    onDivideInputChange = (e) => {
        var { value } = e.target
        value = value.replace(/[^0-9]/gm, '')

        this.setState({
            divideEquallyInput: value
        })
    }

    renderMpkLevelsList = (mpk, index) => {
        const { levelsCount } = this.state
        const { readOnly } = this.props
        var elementsToReturn = []

        for (let i = 1; i <= levelsCount; i++) {
            const levelList = this.state[`level${i}List`]
            if (levelList) {
                elementsToReturn.push(
                    <MpkListSelector
                        key={`mpk-level-${i}`}
                        mpkLevel={i}
                        list={levelList}
                        selectedOption={mpk[`mpkLevel${i}`]}
                        selectOption={value => this.selectMpk(index, i, value)}
                        readOnly={readOnly}
                    />
                )
            }
        }

        return elementsToReturn
    }

    render() {
        const {
            showDivideEquallyWindow, divideEquallyInput
        } = this.state
        const {
            customTitle,
            mpkSplit,
            isInDocPreview, readOnly,
            isAccountantInCurrentCompany, companyId,
            currency, paid
        } = this.props

        console.log('mpkSplit', JSON.stringify(mpkSplit, null, 2))
        console.log('mpkSplit', JSON.stringify(mpkSplit, null, 2))
        console.log('mpkSplit', JSON.stringify(mpkSplit, null, 2))
        console.log('mpkSplit', JSON.stringify(mpkSplit, null, 2))

        return (
            <div className={`mpk-split-container ${currency !== 'PLN' ? 'other-currency' : ''} ${paid ? 'paid' : ''}`}>
                {
                    showDivideEquallyWindow ? (
                        <div className="divide-equally-window popup">
                            <div className="popup-card">
                                <h5>{this.context.t('How many parts should the cost be divided into?')}</h5>
                                <input
                                    type="text"
                                    value={divideEquallyInput}
                                    name={`divideEquallyInput`}
                                    onChange={this.onDivideInputChange}
                                />

                                <div className="action-buttons">
                                    <div className="button cancel" onClick={() => this.setState({ showDivideEquallyWindow: false, divideEquallyInput: '' })}>{this.context.t('Cancel')}</div>
                                    <div className="button save" onClick={() => this.divideEqually()}>{this.context.t('Accept')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="cost-split-header">
                    <h4>{this.context.t(customTitle || 'Split payment into MPK\'s')}:</h4>
                    {
                        !readOnly ? (
                            <>
                                <div className="add-allocation" onClick={() => this.showDevideEqually()}>{this.context.t('Divide into x equal parts')}</div>
                                <div className="add-allocation" onClick={() => this.addMpkSplit()}><span>+</span>{this.context.t('Add MPK')}</div>
                            </>
                        ) : null
                    }
                </div>
                {
                    (mpkSplit || []).map((mpk, index) => {
                        return (
                            <div key={mpk.id > 0 ? `mpk-split-${mpk.id}` : `mpk-split-${index}`} className="cost-split">

                                <div className={`form-group amount ${isAccountantInCurrentCompany ? 'accontant' : ''}`}>
                                    <label>{this.context.t('Net amount')}</label>
                                    <input
                                        type="text"
                                        value={mpk.netAmount}
                                        name={`net-amount-${index}`}
                                        onChange={e => this.onNetChange(e, 'NET', index)}
                                        onBlur={e => this.onFieldBlur(index)}
                                        readOnly={readOnly}
                                    />
                                </div>

                                {
                                    isAccountantInCurrentCompany ? (
                                        <OcrPageWaproNettoAccounts
                                            selectedOption={mpk.waproNetAccount}
                                            select={value => this.setWaproNetAccount(value, index)}
                                            companyId={companyId}
                                            readOnly={readOnly}
                                        />
                                    ) : null
                                }

                                {this.renderMpkLevelsList(mpk, index)}

                                {
                                    mpkSplit.length > 1 && !readOnly ? (
                                        <div className="delete-cost-split" onClick={() => this.deleteCostSplit(index)}><span>-</span>{this.context.t('Delete MPK')}</div>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

MpkSplit.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    addMpkSplitInstance: FileActions.addMpkSplitInstance,
    addFirstMpkSplitInstance: FileActions.addFirstMpkSplitInstance,
    deleteMpkSplitInstance: FileActions.deleteMpkSplitInstance,
    editMpkSplitInstance: FileActions.editMpkSplitInstance
}

export default connect(mapStateToProps, mapDispatchToProps)(MpkSplit)