import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import PropTypes from 'prop-types'

import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'
import throttle from "lodash.throttle"

import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';
import DragItem from '../../../../components/ProjectsPageComponents/DragItem'
import DropTarget from '../../../../components/ProjectsPageComponents/DropTarget'
import DragLayer from '../../../../components/ProjectsPageComponents/DragLayer'
import AvailableInOtherPlanLabel from '../../../../components/AvailableInOtherPlanLabel'
import AdditionalAcceptanceUsersList from '../../../../components/ProjectsPageComponents/AdditionalAcceptanceUsersList'
import AdditionalAcceptanceSettingsPopup from '../../../../components/ProjectsPageComponents/AdditionalAcceptanceSettingsPopup'
import EditProjectMemebrsWindow from '../../../../components/ProjectsPageComponents/EditProjectMemebrsWindow'

import { AlertActions } from '../../../../actions/AlertActions'
import { CompanyActions } from '../../../../actions/CompanyActions'
import { UserActions } from '../../../../actions/UserActions'

import isIterable from "../../../../helpers/isIterable"
import { emailRegex } from '../../../../helpers/regexList'

export class Projects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedFields: [],
            lastSelectedIndex: -1,
            showDeleteProjectPopup: false,
            projectIdToDelete: -1,
            droppedItems: [
                {
                    x: 0,
                    y: 0,
                    items: []
                },
                {
                    x: 1,
                    y: 1,
                    items: []
                },
                {
                    x: 2,
                    y: 2,
                    items: []
                },
                {
                    x: 3,
                    y: 3,
                    items: []
                }
            ],
            returnedOverlyPossitions: false,
            newUserEmail: '',
            showInviteUserMessage: true,
            docsAcceptanceLimitPLN: '',
            docsAcceptanceLimitEUR: '',
            tresholdsHadBeenChanged: false,

            showEditMembersList: false,
            membersToEdit: [],
            projectIdToEdit: -1,
            companyIdToEdit: -1,
            listTypeToEdit: '',
            availableMemebrsToEdit: []
        }
    }

    componentDidMount() {
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        const { currentCompany, currentProject } = this.props
        if (currentCompany && currentCompany.company_id) this.props.getAllCompanyProjects(currentCompany.company_id)
        if (currentProject && currentProject.id) {
            console.log(this.props.currentProject)
            this.props.getProjectWorkers(this.props.currentProject.id)

            this.setState({
                docsAcceptanceLimitPLN: this.props.currentProject.additionalAcceptanceTreshold,
                docsAcceptanceLimitEUR: this.props.currentProject.additionalAcceptanceTresholdEUR,
                tresholdsHadBeenChanged: false
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentProject !== this.props.currentProject) {
            console.log(this.props.currentProject)
            if (this.props.currentProject.id) {
                this.props.getProjectWorkers(this.props.currentProject.id)
                this.setState({
                    docsAcceptanceLimitPLN: this.props.currentProject.additionalAcceptanceTreshold,
                    docsAcceptanceLimitEUR: this.props.currentProject.additionalAcceptanceTresholdEUR,
                    tresholdsHadBeenChanged: false
                })
                console.log(this.props.currentProject)
            }
        } else if (prevProps.projectWorkers !== this.props.projectWorkers || prevProps.companyWorkers !== this.props.companyWorkers) {
            var currentWorkers = [
                {
                    x: 0,
                    y: 0,
                    items: []
                },
                {
                    x: 1,
                    y: 1,
                    items: []
                },
                {
                    x: 2,
                    y: 2,
                    items: []
                },
                {
                    x: 3,
                    y: 3,
                    items: []
                }
            ]

            var currentCompanyWorkers = [...this.props.companyWorkers]
            console.log(this.props.currentProject.companyId)
            var currentCompanyGroup = currentCompanyWorkers.find(e => e.company.id === this.props.currentProject.companyId)
            if (currentCompanyGroup) {
                console.log(currentCompanyGroup)
                currentCompanyWorkers = currentCompanyGroup.members
                if (this.props.projectWorkers) {
                    this.props.projectWorkers.forEach(worker => {
                        if (worker.role === 'USER1') {
                            currentWorkers[1].items.push(worker)
                        } else if (worker.role === 'USER2') {
                            currentWorkers[2].items.push(worker)
                        } else if (worker.role === 'USER3') {
                            currentWorkers[3].items.push(worker)
                        }
                        currentCompanyWorkers = currentCompanyWorkers.filter(e => e.id !== worker.id)
                    })

                    currentWorkers[0].items = currentCompanyWorkers

                    this.setState({
                        droppedItems: currentWorkers
                    })
                }
            }

        }

        if (this.props.currentProject.id && !this.state.returnedOverlyPossitions) {
            const { goBackRef, addMemberRef, dropAreasRef } = this.refs

            if (goBackRef && addMemberRef && dropAreasRef) {
                var holesToDraw = []

                var goBackBox = goBackRef.getBoundingClientRect()
                if (goBackBox) {
                    holesToDraw.push({
                        x: goBackBox.x,
                        y: goBackBox.y,
                        w: goBackBox.width,
                        h: goBackBox.height
                    })
                }

                var addMemberBox = addMemberRef.getBoundingClientRect()
                if (addMemberBox) {
                    holesToDraw.push({
                        x: addMemberBox.x,
                        y: addMemberBox.y,
                        w: addMemberBox.width,
                        h: addMemberBox.height
                    })
                }

                var dropAreasBox = dropAreasRef.getBoundingClientRect()
                if (dropAreasBox) {
                    holesToDraw.push({
                        x: dropAreasBox.x,
                        y: dropAreasBox.y + 15,
                        w: dropAreasBox.width,
                        h: dropAreasBox.height
                    })
                }

                if (holesToDraw.length === 3) {
                    console.log(holesToDraw)
                    this.setState({
                        returnedOverlyPossitions: true,
                    })

                    this.props.returnOverlayPossitions(holesToDraw)
                }
            }
        }
    }

    componentWillMount() {
        this.handleItemSelection(-1, false, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    setOverlaySize = () => {
        const { goBackRef, addMemberRef, dropAreasRef } = this.refs

        if (goBackRef && addMemberRef && dropAreasRef) {
            var holesToDraw = []

            var goBackBox = goBackRef.getBoundingClientRect()
            if (goBackBox) {
                holesToDraw.push({
                    x: goBackBox.x,
                    y: goBackBox.y,
                    w: goBackBox.width,
                    h: goBackBox.height
                })
            }

            var addMemberBox = addMemberRef.getBoundingClientRect()
            if (addMemberBox) {
                holesToDraw.push({
                    x: addMemberBox.x,
                    y: addMemberBox.y,
                    w: addMemberBox.width,
                    h: addMemberBox.height
                })
            }

            var dropAreasBox = dropAreasRef.getBoundingClientRect()
            if (dropAreasBox) {
                holesToDraw.push({
                    x: dropAreasBox.x,
                    y: dropAreasBox.y + 15,
                    w: dropAreasBox.width,
                    h: dropAreasBox.height
                })
            }

            if (holesToDraw.length === 3) {
                console.log(holesToDraw)
                this.setState({
                    returnedOverlyPossitions: true,
                })

                this.props.returnOverlayPossitions(holesToDraw)
            }
        }
    }

    clearItemSelection = () => {
        this.setState({ selectedFields: [], lastSelectedIndex: -1 });
    }

    deleteFromDropzone = (itemId, dropZoneX, dropZoneY) => {
        var dropAreas = this.state.droppedItems
        dropAreas.find(element => element.x === dropZoneX && element.y === dropZoneY).items = dropAreas.find(element => element.x === dropZoneX && element.y === dropZoneY).items.filter(function (value, index, arr) {
            return value.id !== itemId
        })

        this.setState({
            droppedItems: dropAreas
        })
    }

    handleDrop = (dropedItems, xId, yId) => {
        console.log('dropped : ' + dropedItems.length + " to dropZone: (" + xId + ", " + yId + ")")
        console.log(dropedItems)

        if (xId === 0 && yId === 0) {
            var usersToRemoveFromProject = dropedItems.map(e => e.id)
            console.log(usersToRemoveFromProject)
            this.props.removeUsersFromProject(this.props.currentProject.id, usersToRemoveFromProject)

            if (usersToRemoveFromProject.includes(this.props.user.id)) {
                this.props.changeMyRoleInProject(this.props.currentProject.id, '')
            }
        } else {
            var usersToChangeRole = dropedItems.map(e => e.id)
            console.log(usersToChangeRole)
            this.props.changeUsersRoleInProject(this.props.currentProject.id, usersToChangeRole, xId, this.props.currentProject.companyId)

            if (usersToChangeRole.includes(this.props.user.id)) {
                this.props.changeMyRoleInProject(this.props.currentProject.id, 'USER' + xId)
            }
        }
    }

    handleItemSelection = (index, cmdKey, shiftKey) => {
        var selectedFields;
        const fields = this.props.companyWorkers;
        if (index >= 0) {
            const field = fields[index];
            const lastSelectedIndex = index;
            if (!cmdKey && !shiftKey) {
                selectedFields = [field];
            } else if (shiftKey) {
                if (this.state.lastSelectedIndex >= index) {
                    selectedFields = [].concat.apply(this.state.selectedFields,
                        fields.slice(index, this.state.lastSelectedIndex));
                } else {
                    selectedFields = [].concat.apply(this.state.selectedFields,
                        fields.slice(this.state.lastSelectedIndex + 1, index + 1));
                }
            } else if (cmdKey) {
                const foundIndex = this.state.selectedFields.findIndex(f => f.id === field.id);
                // If found remove it to unselect it.
                if (foundIndex >= 0) {
                    selectedFields = [
                        ...this.state.selectedFields.slice(0, foundIndex),
                        ...this.state.selectedFields.slice(foundIndex + 1),
                    ];
                } else {
                    selectedFields = [...this.state.selectedFields, field];
                }
            }
            const finalList = fields ? fields.filter(f => selectedFields.find(a => a.id === f.id)) : [];
            this.setState({ selectedFields: finalList, lastSelectedIndex });
        }
    }

    convertToMembersPhotos = (members, projectId, companyId, listType, availableMemebrsToEdit) => {
        var maxDisplayedPhotos = 3
        var numberOfDisplayedPhotos = 0
        var displayNumberOfAdditionalMembers = false
        var photosToReturn = [], additionalMembersNames = []

        var membersCount = members.length
        var helperDivIndex = membersCount

        if (membersCount > maxDisplayedPhotos) helperDivIndex = maxDisplayedPhotos + 1

        members.forEach((m, i) => {
            if (i < maxDisplayedPhotos) {
                photosToReturn.push(
                    <div className={`member number-${(helperDivIndex - i)} ${m.photo ? '' : 'default'}`} data-for={`project-member-${m.id}-${projectId}-${listType}`} data-tip='show'>
                        {
                            m.photo ? (
                                <img src={m.photo} alt={m.name} />
                            ) : (
                                // <img src={require('../../../../img/default-user-photo.svg')} alt={m.name}/>
                                null
                            )
                        }
                    </div>
                )

                photosToReturn.push(
                    <ReactTooltip id={`project-member-${m.id}-${projectId}-${listType}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                        <p>{m.name ? m.name : m.email}</p>
                    </ReactTooltip>
                )
            } else {
                displayNumberOfAdditionalMembers = true
                additionalMembersNames.push(
                    m.name ? m.name : m.email
                )
            }
        })

        if (displayNumberOfAdditionalMembers) {
            photosToReturn.push(
                <div className={`member number-1 additional-members`} data-for={`additional-project-members-${projectId}`} data-tip='show'>
                    {`+${membersCount - maxDisplayedPhotos}`}
                </div>
            )

            photosToReturn.push(
                <ReactTooltip id={`additional-project-members-${projectId}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                    {
                        additionalMembersNames.map(m => {
                            return (<p>{m}</p>)
                        })
                    }
                </ReactTooltip>
            )
        }

        // photosToReturn.push(
        //     <div className={`member number-0 edit-members`} onClick={() => this.showEditeMembersList(members, projectId, companyId, listType, availableMemebrsToEdit)}></div>
        // )

        return photosToReturn
    }

    showEditeMembersList = (members, projectId, companyId, listType, availableMemebrsToEdit) => {
        this.setState({
            showEditMembersList: true,
            membersToEdit: members,
            projectIdToEdit: projectId,
            companyIdToEdit: companyId,
            listTypeToEdit: listType,
            availableMemebrsToEdit: availableMemebrsToEdit
        })
    }

    hideEditMembersList = () => {
        this.setState({
            showEditMembersList: false,
            membersToEdit: [],
            projectIdToEdit: -1,
            companyIdToEdit: -1,
            listTypeToEdit: '',
            availableMemebrsToEdit: []
        })
    }

    showDeleteProjectPopup = (e, id, name, groupIndex) => {
        this.setState({
            showDeleteProjectPopup: true,
            projectIdToDelete: id,
            projectNameToDelet: name,
            projectToDeleteGroupIndex: groupIndex
        })
    }

    hideDeleteProjectPopup = () => {
        this.setState({
            showDeleteProjectPopup: false,
            projectIdToDelete: -1
        })
    }

    deleteProject = () => {
        const { companyProjects } = this.props
        const { projectToDeleteGroupIndex } = this.state
        if (companyProjects[projectToDeleteGroupIndex] && companyProjects[projectToDeleteGroupIndex].projects.length > 1) {
            this.props.deleteProject(this.state.projectIdToDelete)
            this.setState({
                showDeleteProjectPopup: false,
                projectIdToDelete: -1
            })
        } else {
            this.props.alertWarn(this.context.t('You cannot delete a binder if it is the only binder in the company. Please create new one first'))
        }
    }

    changeCurrentProject = (e, id, groupIndex, companyId) => {
        if (e.target) {
            if (e.target.id && e.target.id.startsWith('deleteProject-')) {
                console.log('delete project click')
            } else {
                this.props.changeCurrentProject(e, id, groupIndex, companyId)
                if (id) {
                    this.props.canShowSingleProjectOverlay(true)
                } else {
                    this.props.canShowSingleProjectOverlay(false)
                }

                if (companyId) {
                    this.props.getAllCompanyWorkers(companyId)
                }
            }
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    inviteNewMember = e => {
        e.preventDefault();
        const newUserEmail = this.state.newUserEmail

        var valid = true
        if (newUserEmail.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(newUserEmail)) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        } else {
            this.props.inviteAndAssignMember([this.props.currentProject.id], newUserEmail, this.props.currentProject.companyId, (success) => {
                if (success) {
                    this.setState({
                        showInviteUserMessage: true,
                        inviteAndAssignMemberGotResponse: true,
                        newUserEmail: ''
                    })
                } else {
                    this.setState({
                        showInviteUserMessage: true,
                        inviteAndAssignMemberGotResponse: true,
                    })
                }
            })
        }
    }

    changeAdditionalDocsAcceptanceTresholds = (projectId, designatedUsers) => {
        if (this.state.tresholdsHadBeenChanged) {
            this.setState({
                waitingForAdditionalAcceptanceTresholdUpdate: true
            })
            this.props.changeAdditionalDocsAcceptanceTresholds(projectId, this.props.currentCompany.company_id, this.state.docsAcceptanceLimitPLN, this.state.docsAcceptanceLimitEUR, success => {
                if (success) this.props.changeAdditionalDocsAcceptanceTresholdsForCurrentProject(this.state.docsAcceptanceLimitPLN, this.state.docsAcceptanceLimitEUR)

                this.setState({
                    waitingForAdditionalAcceptanceTresholdUpdate: false
                })
            })
        }
    }

    onDocsPriceLimitChange = (e, currency) => {
        const { currentProject } = this.props
        var { name, value } = e.target

        value = value.replace(/[^0-9]/g, '')

        if (currency === 'PLN') {
            this.setState({
                docsAcceptanceLimitPLN: value,
                tresholdsHadBeenChanged: value !== currentProject.additionalAcceptanceTreshold
            })
        } else {
            this.setState({
                docsAcceptanceLimitEUR: value,
                tresholdsHadBeenChanged: value !== currentProject.additionalAcceptanceTresholdEUR
            })
        }
    }

    showAdditionalAcceptanceUsersList = (selectedUsers, numberOfRequiredAcceptances, type, tunrOnAfterSave, projectId) => {
        this.setState({
            showAdditionalAcceptanceUsersList: true,
            selectedUsers: selectedUsers || [],
            numberOfRequiredAcceptances: numberOfRequiredAcceptances || 2,
            additionalAcceptanceType: type,
            tunrOnAdditionalAcceptanceAfterSave: tunrOnAfterSave,
            projectToToogleAdditionalAcceptance: projectId,
        })
    }

    selectUserForAdditionalAcceptance = (id) => {
        const { selectedUsers } = this.state

        var tempSelectedUsers = [...selectedUsers]
        tempSelectedUsers.push(id)

        this.setState({
            selectedUsers: tempSelectedUsers
        })
    }

    unselectUserForAdditionalAcceptance = (id) => {
        const { selectedUsers } = this.state

        this.setState({
            selectedUsers: selectedUsers.filter(u => u !== id)
        })
    }

    hideAdditionalAcceptanceUsersList = () => {
        this.setState({
            showAdditionalAcceptanceUsersList: false,
            selectedUsers: [],
            additionalAcceptanceType: '',
            tunrOnAdditionalAcceptanceAfterSave: false,
            projectToToogleAdditionalAcceptance: null,
        })
    }

    saveUsersList = (requiredAcceptanceNumber) => {
        const { selectedUsers, additionalAcceptanceType, projectToToogleAdditionalAcceptance, tunrOnAdditionalAcceptanceAfterSave } = this.state
        console.log('saveUsersList')
        console.log(selectedUsers, additionalAcceptanceType, projectToToogleAdditionalAcceptance, tunrOnAdditionalAcceptanceAfterSave)

        this.setState({
            waitingForAdditionalAcceptanceToggle: true
        })
        if (!tunrOnAdditionalAcceptanceAfterSave) {
            this.props.saveUsersForAdditionalAcceptance(projectToToogleAdditionalAcceptance, this.props.currentCompany.company_id, selectedUsers, additionalAcceptanceType, requiredAcceptanceNumber, success => {
                if (success) {
                    this.hideAdditionalAcceptanceUsersList()
                    this.props.updateUsersAllowedToAcceptDocs(selectedUsers, additionalAcceptanceType)
                }
                this.setState({
                    waitingForAdditionalAcceptanceToggle: false
                })
            })
        } else {
            if (additionalAcceptanceType === 'over') {
                this.props.toggleAdditionalAprovalForDocs(projectToToogleAdditionalAcceptance, this.props.currentCompany.company_id, selectedUsers, requiredAcceptanceNumber, success => {
                    if (success) {
                        this.props.toggleAdditionalAprovalForDocsForCurrentProject()
                        this.props.updateUsersAllowedToAcceptDocs(selectedUsers, additionalAcceptanceType)
                        this.hideAdditionalAcceptanceUsersList()
                    }

                    this.setState({
                        waitingForAdditionalAcceptanceToggle: false
                    })
                })
            } else {
                this.props.toggleAdditionalAprovalForDocsUnderTreshold(projectToToogleAdditionalAcceptance, this.props.currentCompany.company_id, selectedUsers, requiredAcceptanceNumber, success => {
                    if (success) {
                        this.props.toggleAdditionalAprovalForDocsUnderTresholdForCurrentProject()
                        this.props.updateUsersAllowedToAcceptDocs(selectedUsers, additionalAcceptanceType)
                        this.hideAdditionalAcceptanceUsersList()
                    }

                    this.setState({
                        waitingForAdditionalAcceptanceToggle: false
                    })
                })
            }
        }
    }

    toggleAdditionalAprovalForDocsUnderTreshold = project => {
        if (project.hasAdditionalDocsAcceptanceUnderTreshold) {
            this.setState({
                waitingForAdditionalAcceptanceToggle: true
            })
            this.props.toggleAdditionalAprovalForDocsUnderTreshold(project.id, this.props.currentCompany.company_id, [], 0, success => {
                if (success) {
                    this.props.toggleAdditionalAprovalForDocsUnderTresholdForCurrentProject()
                    this.props.updateUsersAllowedToAcceptDocs([], 'under')
                }

                this.setState({
                    waitingForAdditionalAcceptanceToggle: false
                })
            })
        } else {
            this.showAdditionalAcceptanceUsersList(project.usersAllowedToAccaptUnderTreshold, project.usersAllowedToAccaptUnderTreshold, 'under', true, project.id)
        }
    }

    toggleAdditionalAprovalForDocsOverTreshold = project => {
        if (project.hasAdditionalDocsAcceptanceOverTreshold) {
            this.setState({
                waitingForAdditionalAcceptanceToggle: true
            })
            this.props.toggleAdditionalAprovalForDocs(project.id, this.props.currentCompany.company_id, [], 0, success => {
                if (success) {
                    this.props.toggleAdditionalAprovalForDocsForCurrentProject()
                    this.props.updateUsersAllowedToAcceptDocs([], 'over')
                }

                this.setState({
                    waitingForAdditionalAcceptanceToggle: false
                })
            })
        } else {
            this.showAdditionalAcceptanceUsersList(project.usersAllowedToAccaptOverTreshold, project.usersAllowedToAccaptOverTreshold, 'over', true, project.id)
        }
    }

    openAdditionalAcceptancePopup = (projectId) => {
        this.setState({
            showAdditionalAcceptancePopup: true,
            projectToToogleAdditionalAcceptance: projectId
        })
    }

    closeAdditionalAcceptancePopup = () => {
        this.setState({
            showAdditionalAcceptancePopup: false,
            projectToToogleAdditionalAcceptance: -1
        })
    }

    render() {
        var { companyProjects, currentProject, currentCompany, loadingScreen, inviteAndAssignMemberSendRequest, inviteAndAssignMemberError, inviteAndAssignMemberErrorMsg, projectWorkers } = this.props
        const {
            showDeleteProjectPopup,
            newUserEmail, showInviteUserMessage, inviteAndAssignMemberGotResponse,
            showAdditionalAcceptancePopup,
            docsAcceptanceLimitPLN, docsAcceptanceLimitEUR, tresholdsHadBeenChanged, waitingForAdditionalAcceptanceToggle, waitingForAdditionalAcceptanceTresholdUpdate, showAdditionalAcceptanceUsersList, selectedUsers, numberOfRequiredAcceptances, additionalAcceptanceType, projectToToogleAdditionalAcceptance,

            showEditMembersList, membersToEdit, projectIdToEdit, companyIdToEdit, listTypeToEdit, availableMemebrsToEdit
        } = this.state

        if (companyProjects) {
            if (companyProjects.length > 0) {
                return (
                    <div className={`tab ${waitingForAdditionalAcceptanceToggle ? 'cursor-wait' : ''}`}>
                        {
                            showDeleteProjectPopup ? (
                                <div className="popup delete-project-popup">
                                    <div className="popup-card">
                                        <div className="header">
                                            {this.context.t('Are you sure you want to delete this binder?')}
                                        </div>
                                        <div className="text">
                                            {this.context.t('This action cannot be undone and all files and data related to ')}
                                            <span className="bold">{this.state.projectNameToDelet}</span>
                                            {this.context.t(' binder will be permanently deleted from our servers')}
                                        </div>
                                        <div className="action-buttons">
                                            <span className="btn btn-cancel" onClick={e => this.hideDeleteProjectPopup()}>{this.context.t("Cancel")}</span>
                                            <span className="btn btn-confirm" onClick={e => this.deleteProject()}>{this.context.t("Delete")}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            loadingScreen ? (
                                <div className="loading-screen">
                                    <div className="activity-indicator">
                                        <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            showAdditionalAcceptanceUsersList ? (
                                <AdditionalAcceptanceUsersList
                                    projectId={projectToToogleAdditionalAcceptance}
                                    projectWorkers={projectWorkers}
                                    selectedUsers={selectedUsers}
                                    numberOfRequiredAcceptances={numberOfRequiredAcceptances}
                                    additionalAcceptanceType={additionalAcceptanceType}
                                    selectUser={this.selectUserForAdditionalAcceptance}
                                    unselectUser={this.unselectUserForAdditionalAcceptance}
                                    cancel={this.hideAdditionalAcceptanceUsersList}
                                    save={this.saveUsersList}
                                />
                            ) : (
                                null
                            )
                        }

                        {
                            showEditMembersList ? (
                                <EditProjectMemebrsWindow
                                    currentMembers={membersToEdit}
                                    projectId={projectIdToEdit}
                                    companyId={companyIdToEdit}
                                    listTypeToEdit={listTypeToEdit}
                                    availableMemebrsToEdit={availableMemebrsToEdit}
                                    close={this.hideEditMembersList}
                                />
                            ) : null
                        }

                        {
                            !currentProject.id ? (
                                <div className="projects-list table-responsive">

                                    <div className="description">
                                        <p>{this.context.t('In EasyDocs you can have many binders within your company (e.g. if you have two tenement houses for rent, each can be a separate binder).')}</p>
                                        <p>{this.context.t('Your first binder in EasyDocs was created automatically.')}</p>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="name">
                                                    <div>
                                                        {this.context.t('Name')}
                                                    </div>
                                                </th>
                                                <th className="code">
                                                    <div>
                                                        {this.context.t('Code')}
                                                    </div>
                                                </th>
                                                <th className="files">
                                                    <div>
                                                        {this.context.t('Docs')}
                                                    </div>
                                                </th>
                                                <th className="members">
                                                    <div>
                                                        {this.context.t('Members')}
                                                    </div>
                                                </th>
                                                {/* <th className="members">
                                                    <div>
                                                        {this.context.t('Dropzone manager')}
                                                    </div>
                                                </th> */}
                                                <th>

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                companyProjects.map((g, i) => {
                                                    return (
                                                        <>
                                                            <div className="company" key={`company-group-${i}`}>{g.company.code}</div>
                                                            {
                                                                g.projects.map((p, j) => {
                                                                    return (
                                                                        <tr className={`${j % 2 !== 0 ? 'odd' : ''}`} onClick={e => this.changeCurrentProject(e, p.id, i, g.company.id)}>
                                                                            <td className="name">{p.name}</td>
                                                                            <td className="name">{p.code}</td>
                                                                            <td>{p.filesCount}</td>
                                                                            <td className="members">
                                                                                <div className="members">
                                                                                    {this.convertToMembersPhotos(p.members, p.id, g.company.id, 'MEMBERS', g.company.users)}
                                                                                </div>
                                                                            </td>
                                                                            {/* <td className="members">
                                                                                <div className="members">
                                                                                    {this.convertToMembersPhotos(p.dropzoneManagers, p.id, g.company.id, 'DROPZONE_MANAGERS', p.members)}
                                                                                </div>
                                                                            </td> */}
                                                                            <td className="delete-project" id={`deleteProject-${j}`} onClick={(e) => this.showDeleteProjectPopup(e, p.id, p.name, i)}>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="access-management">
                                    <div className="header">
                                        <div className="project-name">
                                            <div className="folder-icon"></div>
                                            <div className="go-back" onClick={e => this.changeCurrentProject(e, null)} ref="goBackRef"></div>
                                            <div className="name">{currentProject.name}</div>
                                        </div>

                                        <div className="nev-member-input">
                                            <label htmlFor="newUserEmail">{this.context.t('Add new member to binder')}</label>
                                            <input type="text" name="newUserEmail" id="newUserEmail" value={newUserEmail} placeholder={this.context.t("New member email")} onChange={e => this.onInputChange(e)} />
                                            <div className={`add-button ${inviteAndAssignMemberSendRequest ? 'loading' : ''} ${emailRegex.test(newUserEmail) ? 'active' : ''}`} onClick={e => this.inviteNewMember(e)}>
                                                {
                                                    inviteAndAssignMemberSendRequest ? (
                                                        <span className="activity-indicator">
                                                            <Spinner size={20} speed={0.8} color={"#69B792"} />
                                                        </span>
                                                    ) : (
                                                        this.context.t('Invite')
                                                    )
                                                }
                                            </div>

                                            {
                                                showInviteUserMessage ? (
                                                    inviteAndAssignMemberGotResponse ? (
                                                        inviteAndAssignMemberError ? (
                                                            <div className="invite-response error">
                                                                <div className="message">
                                                                    {this.context.t(inviteAndAssignMemberErrorMsg)}
                                                                </div>
                                                                <div className="close" onClick={() => this.setState({ showInviteUserMessage: false })}></div>
                                                            </div>
                                                        ) : (
                                                            <div className="invite-response ok">
                                                                <div className="prefix">{this.context.t('Success! ')}</div>
                                                                <div className="message">
                                                                    {this.context.t('Your invitation has been sent')}
                                                                </div>
                                                                <div className="close" onClick={() => this.setState({ showInviteUserMessage: false })}></div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        null
                                                    )
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>

                                        <div className="binder-additional-acceptance">
                                            <div className="configurate-acceptance-button button" onClick={() => this.openAdditionalAcceptancePopup(currentProject.id)}>
                                                {this.context.t('Configure additional acceptance of documents for payment')}
                                            </div>

                                            {
                                                showAdditionalAcceptancePopup ? (
                                                    <AdditionalAcceptanceSettingsPopup
                                                        projectId={projectToToogleAdditionalAcceptance}
                                                        projectWorkers={projectWorkers}
                                                        cancel={this.closeAdditionalAcceptancePopup}
                                                    />
                                                ) : null
                                            }
                                        </div>

                                        {/* <div className="additional-acceptance-setting">
                                            <div className={`text ${waitingForAdditionalAcceptanceToggle ? 'waiting' : ''}`}>
                                                <div className={`checkbox ${currentProject.hasAdditionalDocsAcceptanceUnderTreshold ? 'checked' : ''}`} onClick={() => this.toggleAdditionalAprovalForDocsUnderTreshold(currentProject)}></div>
                                                <div className="label" onClick={() => this.toggleAdditionalAprovalForDocsUnderTreshold(currentProject)}>{this.context.t('Require approval of documents below the quota threshold by designated users')}</div>

                                                {
                                                    currentProject.hasAdditionalDocsAcceptanceUnderTreshold ? (
                                                        <div className="button" onClick={() => this.showAdditionalAcceptanceUsersList(currentProject.usersAllowedToAccaptUnderTreshold, currentProject.numberOfRequiredAcceptancesUnderTreshold, 'under', false, currentProject.id)}>{this.context.t('Show users list')}</div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>

                                            <div className={`text ${waitingForAdditionalAcceptanceToggle ? 'waiting' : ''}`}>
                                                <div className={`checkbox ${currentProject.hasAdditionalDocsAcceptanceOverTreshold ? 'checked' : ''}`} onClick={() => this.toggleAdditionalAprovalForDocsOverTreshold(currentProject)}></div>
                                                <div className="label" onClick={() => this.toggleAdditionalAprovalForDocsOverTreshold(currentProject)}>{this.context.t('Require approval of documents above the quota threshold by designated users')}</div>

                                                {
                                                    currentProject.hasAdditionalDocsAcceptanceOverTreshold ? (
                                                        <div className="button" onClick={() => this.showAdditionalAcceptanceUsersList(currentProject.usersAllowedToAccaptOverTreshold, currentProject.numberOfRequiredAcceptancesOverTreshold, 'over', false, currentProject.id)}>{this.context.t('Show users list')}</div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>

                                            <div className="limits">
                                                <div className="form-group">
                                                    <input type="text" id="docsAcceptanceLimitPLN" name="docsAcceptanceLimitPLN" value={docsAcceptanceLimitPLN} onChange={e => this.onDocsPriceLimitChange(e, 'PLN')} className={`${currentProject.hasAdditionalDocsAcceptanceOverTreshold || currentProject.hasAdditionalDocsAcceptanceUnderTreshold ? '' : 'inactive'}`} />
                                                    <label htmlFor="docsAcceptanceLimitPLN">{this.context.t('The amount threshold for the accepting of documents in PLN')}</label>
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" id="docsAcceptanceLimitEUR" name="docsAcceptanceLimitEUR" value={docsAcceptanceLimitEUR} onChange={e => this.onDocsPriceLimitChange(e, 'EUR')} className={`${currentProject.hasAdditionalDocsAcceptanceOverTreshold || currentProject.hasAdditionalDocsAcceptanceUnderTreshold ? '' : 'inactive'}`} />
                                                    <label htmlFor="docsAcceptanceLimitEUR">{this.context.t('The amount threshold for the acceptance of currency documents')}</label>
                                                </div>

                                                <div className={`btn ${tresholdsHadBeenChanged && (currentProject.hasAdditionalDocsAcceptanceOverTreshold || currentProject.hasAdditionalDocsAcceptanceUnderTreshold) ? 'active' : ''} ${waitingForAdditionalAcceptanceTresholdUpdate ? 'waiting' : ''}`} onClick={() => { this.changeAdditionalDocsAcceptanceTresholds(currentProject.id) }}>{this.context.t('Save')}</div>
                                            </div>
                                        </div> */}

                                    </div>
                                    <DndProvider backend={HTML5Backend}>
                                        <DragLayer />
                                        <div className="user-assignment" ref="dropAreasRef">
                                            <div className="drop-area unassigned">
                                                <div className="header unassigned">
                                                    {this.context.t('No access')}
                                                </div>

                                                <DropTarget
                                                    dropZoneX={0} dropZoneY={0}
                                                    containedItems={this.state.droppedItems.find(e => e.x === 0 && e.y === 0).items}
                                                    deleteFromDropzone={(id, dropZoneX, dropZoneY) => this.deleteFromDropzone(id, dropZoneX, dropZoneY)}
                                                    handleDrop={(itemId, dropZoneX, dropZoneY) => this.handleDrop(itemId, dropZoneX, dropZoneY)}
                                                    areaHeight='calc(100vh - 465px)'

                                                    handleItemDrop={this.handleDrop}
                                                    clearItemSelection={this.clearItemSelection}
                                                    selectedFields={this.state.selectedFields}
                                                    handleSelection={this.handleItemSelection}
                                                />
                                            </div>

                                            <div className="drop-area u-1">
                                                <div className="header u-1">
                                                    {this.context.t('Access level U1 (Employee)')}

                                                    <div className="help-icon" data-for='access-info-u-1' data-tip='custom show'></div>
                                                    <ReactTooltip id='access-info-u-1' className="default-tooltip access-tooltip" place="top" effect='solid'>
                                                        <p>{this.context.t('Users with this access level can: ')}</p>
                                                        <ul>
                                                            <li>
                                                                {this.context.t('upload files and verifi automaticlly generated OCR')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('see only files that they uploaded')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('generate expense report for their expenses')}
                                                            </li>
                                                        </ul>
                                                    </ReactTooltip>
                                                </div>
                                                <DropTarget
                                                    dropZoneX={1} dropZoneY={1}
                                                    containedItems={this.state.droppedItems.find(e => e.x === 1 && e.y === 1).items}
                                                    deleteFromDropzone={(id, dropZoneX, dropZoneY) => this.deleteFromDropzone(id, dropZoneX, dropZoneY)}
                                                    handleDrop={(itemId, dropZoneX, dropZoneY) => this.handleDrop(itemId, dropZoneX, dropZoneY)}
                                                    areaHeight='calc(100vh - 465px)'

                                                    handleItemDrop={this.handleDrop}
                                                    clearItemSelection={this.clearItemSelection}
                                                    selectedFields={this.state.selectedFields}
                                                    handleSelection={this.handleItemSelection}
                                                />
                                            </div>

                                            <div className="drop-area u-2">
                                                <div className="header u-2">
                                                    {this.context.t('Access level U2 (Reception / Accounting assistant)')}

                                                    <div className="help-icon" data-for='access-info-u-2' data-tip='custom show'></div>
                                                    <ReactTooltip id='access-info-u-2' className="default-tooltip access-tooltip" place="top" effect='solid'>
                                                        <p>{this.context.t('Users with this access level can: ')}</p>
                                                        <ul>
                                                            <li>
                                                                {this.context.t('upload files, accept previously generated and verified OCR')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('see files imported by users with U1 and U2 permissions and files sent by e-mail')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('generate all reports')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('generate file to import bank transfers')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('mark documents and payment orders as paid')}
                                                            </li>
                                                        </ul>
                                                    </ReactTooltip>
                                                </div>
                                                <DropTarget
                                                    dropZoneX={2} dropZoneY={2}
                                                    containedItems={this.state.droppedItems.find(e => e.x === 2 && e.y === 2).items}
                                                    deleteFromDropzone={(id, dropZoneX, dropZoneY) => this.deleteFromDropzone(id, dropZoneX, dropZoneY)}
                                                    handleDrop={(itemId, dropZoneX, dropZoneY) => this.handleDrop(itemId, dropZoneX, dropZoneY)}
                                                    areaHeight='calc(100vh - 465px)'

                                                    handleItemDrop={this.handleDrop}
                                                    clearItemSelection={this.clearItemSelection}
                                                    selectedFields={this.state.selectedFields}
                                                    handleSelection={this.handleItemSelection}
                                                />
                                            </div>

                                            <div className=" drop-area u-3">
                                                <div className="header u-3">
                                                    {this.context.t('Access level U3 (Management / Chief accounting)')}

                                                    <div className="help-icon" data-for='access-info-u-3' data-tip='custom show'></div>
                                                    <ReactTooltip id='access-info-u-3' className="default-tooltip access-tooltip" place="top" effect='solid'>
                                                        <p>{this.context.t('Users with this access level can: ')}</p>
                                                        <ul>
                                                            <li>
                                                                {this.context.t('upload files, accept previously generated and verified OCR')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('see all files in binder')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('generate all reports')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('generate file to import bank transfers')}
                                                            </li>
                                                            <li>
                                                                {this.context.t('mark documents and payment orders as paid')}
                                                            </li>
                                                        </ul>
                                                    </ReactTooltip>
                                                </div>
                                                <DropTarget
                                                    dropZoneX={3} dropZoneY={3}
                                                    containedItems={this.state.droppedItems.find(e => e.x === 3 && e.y === 3).items}
                                                    deleteFromDropzone={(id, dropZoneX, dropZoneY) => this.deleteFromDropzone(id, dropZoneX, dropZoneY)}
                                                    handleDrop={(itemId, dropZoneX, dropZoneY) => this.handleDrop(itemId, dropZoneX, dropZoneY)}
                                                    areaHeight='calc(100vh - 465px)'

                                                    handleItemDrop={this.handleDrop}
                                                    clearItemSelection={this.clearItemSelection}
                                                    selectedFields={this.state.selectedFields}
                                                    handleSelection={this.handleItemSelection}
                                                />
                                            </div>
                                        </div>
                                    </DndProvider>
                                </div>
                            )
                        }
                    </div>
                )
            } else {
                return (
                    <div className="no-projects-tab">
                        <div className="no-projects-image"></div>
                        <h2>{this.context.t("Your company doesn't have any active binders.")}</h2>
                    </div>
                )
            }
        } else {
            return (
                <div className="tab"></div>
            )
        }
    }
}

Projects.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentCompany: state.Company.currentCompany,
    companyProjects: state.Company.companyProjects,
    companyWorkers: state.Company.usersList || [],
    projectWorkers: isIterable(state.Company.projectWorkers) ? [...state.Company.projectWorkers] : state.Company.projectWorkers,
    loadingScreen: state.File.loadingScreen || false,
    userCompanies: state.Company.companies,

    inviteAndAssignMemberSendRequest: state.Company.inviteAndAssignMemberSendRequest,
    inviteAndAssignMemberGotResponse: state.Company.inviteAndAssignMemberGotResponse,
    inviteAndAssignMemberError: state.Company.inviteAndAssignMemberError,
    inviteAndAssignMemberErrorMsg: state.Company.inviteAndAssignMemberErrorMsg,
})

const mapDispatchToProps = {
    getAllCompanyWorkers: CompanyActions.getAllCompanyWorkers,
    getProjectWorkers: CompanyActions.getProjectWorkers,
    getAllCompanyProjects: CompanyActions.getAllCompanyProjects,
    changeMyRoleInProject: CompanyActions.changeMyRoleInProject,

    removeUsersFromProject: CompanyActions.removeUsersFromProject,
    changeUsersRoleInProject: CompanyActions.changeUsersRoleInProject,
    toggleAdditionalAprovalForDocs: CompanyActions.toggleAdditionalAprovalForDocs,
    toggleAdditionalAprovalForDocsUnderTreshold: CompanyActions.toggleAdditionalAprovalForDocsUnderTreshold,
    changeAdditionalDocsAcceptanceTresholds: CompanyActions.changeAdditionalDocsAcceptanceTresholds,
    saveUsersForAdditionalAcceptance: CompanyActions.saveUsersForAdditionalAcceptance,

    inviteAndAssignMember: CompanyActions.inviteAndAssignMember,

    deleteProject: UserActions.deleteProject,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)