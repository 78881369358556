import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner } from 'react-activity'

import { AlertActions } from '../../../actions/AlertActions'
import { DocflowActions } from '../../../actions/DocflowActions'

export class DocflowStageEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showBindersList: false,
            selectedBinderId: -1,
            selectedBinderCode: '',
            canCopyToSelectedBinder: true,
            waitingForCopyVerification: false
        }

        this.bindersListWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject } = this.props
        this.setState({
            selectedBinderId: currentProject.id,
            selectedBinderCode: currentProject.code
        })
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        if (this.state.showBindersList && this.bindersListWrapperRef && !this.bindersListWrapperRef.current.contains(event.target)) {
            this.setState({
                showBindersList: false
            })
        }
    }

    toggleBindersList = () => {
        const { showBindersList } = this.state
        this.setState({
            showBindersList: !showBindersList
        })
    }

    selectProject = binder => {
        const { currentProject } = this.props
        this.setState({
            selectedBinderId: binder.id,
            selectedBinderCode: binder.code,
            showBindersList: false,
            waitingForCopyVerification: currentProject.id !== binder.id,
            canCopyToSelectedBinder: currentProject.id === binder.id,
        })

        if (currentProject.id !== binder.id) {
            this.props.getProjectMembersForDocflow(binder.id, members => {
                const { docflowToCopy } = this.props
                var canCopy = true
                docflowToCopy.docflow_users?.map(u => {
                    if (u !== 0 && !members.find(m => m.id === u)) {
                        canCopy = false
                    }
                })
                console.log(docflowToCopy.docflow_users)
                console.log(members.map(m => m.id))
                this.setState({
                    waitingForCopyVerification: false,
                    canCopyToSelectedBinder: canCopy
                })
            })
        }
    }

    copy = () => {
        const { docflowToCopy, currentProject } = this.props
        const {
            selectedBinderId, selectedBinderCode,
            waitingForCopyVerification, canCopyToSelectedBinder
        } = this.state

        if (canCopyToSelectedBinder && !waitingForCopyVerification) {
            this.props.copy(docflowToCopy, selectedBinderId !== currentProject.id, selectedBinderId, selectedBinderCode)
        }
    }

    render() {
        const { availableDocFlowBinders, docflowToCopy } = this.props
        const {
            showBindersList,
            selectedBinderId, selectedBinderCode,
            waitingForCopyVerification, canCopyToSelectedBinder
        } = this.state

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow">
                    <h4>
                        {this.context.t('Select binder to copy "{d}" to', { d: docflowToCopy.name })}
                    </h4>

                    <div className="settings-section stage-settings">
                        <div className={`form-group ${showBindersList ? 'in-front' : ''}`} ref={this.bindersListWrapperRef}>
                            <input type="text" name="" id="" value={selectedBinderCode} onClick={this.toggleBindersList} autoComplete="off" />
                            <label htmlFor="" onClick={this.toggleBindersList}>{this.context.t('Binder')}</label>
                            <span className={`expend-icon ${showBindersList ? 'expended' : ''}`} onClick={this.toggleBindersList}></span>

                            {
                                showBindersList ? (
                                    <ul>
                                        {
                                            availableDocFlowBinders.map(b => {
                                                return (
                                                    <li className={`${selectedBinderId === b.id ? 'selected' : ''}`} onClick={() => this.selectProject(b)}>
                                                        {b.code}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>

                        <div className="action-buttons align-right">
                            {
                                !canCopyToSelectedBinder && !waitingForCopyVerification ? (
                                    <div className="warning">
                                        {this.context.t('Some of the users in the current docflow are not available in the selected binder')}
                                    </div>
                                ) : null
                            }

                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className={`button save ${!canCopyToSelectedBinder && !waitingForCopyVerification ? 'inactive' : ''}`} onClick={() => this.copy()}>
                                {
                                    waitingForCopyVerification ? (
                                        <Spinner color="#fff" size={10} speed={0.8} animating={true} />
                                    ) : (
                                        this.context.t('Copy')
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowStageEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
})

const mapDispatchToProps = {
    getProjectMembersForDocflow: DocflowActions.getProjectMembersForDocflow,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowStageEditWindow)