export const DocflowConst = {
    SENT_GET_PROJECT_DOCFLOWS_REQUEST: 'SENT_GET_PROJECT_DOCFLOWS_REQUEST',
    GET_PROJECT_DOCFLOWS: 'GET_PROJECT_DOCFLOWS'
}

const initialState = {
    projectDocflows: []
}

export default (state = initialState, action) => {
    const { projectDocflows } = state

    switch (action.type) {
        case DocflowConst.SENT_GET_PROJECT_DOCFLOWS_REQUEST:
            var tempDocflows = [...projectDocflows]
            var projectIndex = tempDocflows.findIndex(d => d.projectId === action.projectId)
            if (projectIndex > -1) {
                tempDocflows[projectIndex].loading = true
            } else {
                tempDocflows.push({
                    projectId: action.projectId,
                    loading: true
                })
            }
            return { ...state, ...{ projectDocflows: tempDocflows } }

        case DocflowConst.GET_PROJECT_DOCFLOWS:
            var tempDocflows = [...projectDocflows]
            var projectIndex = tempDocflows.findIndex(d => d.projectId === action.projectId)
            if (projectIndex > -1) {
                tempDocflows[projectIndex].docflows = action.docflows
                tempDocflows[projectIndex].loading = false
            } else {
                tempDocflows.push({
                    projectId: action.projectId,
                    docflows: action.docflows,
                    loading: false
                })
            }
            return { ...state, ...{ projectDocflows: tempDocflows } }

        default:
            return state
    }
}