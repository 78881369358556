import React, { Component } from 'react'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { EventActions } from '../actions/EventActions'
import { UserActions } from '../actions/UserActions'

export class Calendar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            dateContext: moment(),
            today: moment(),
            todayDay: moment().format("DD"),
            todayMonth: moment().format("MMMM"),
            todayYear: moment().format('YYYY'),
            weekdaysShort: moment.weekdaysShort(),
            eventsThisMonth: [],
            eventsThisMonthTooltips: [],
            currentTz: '',
            eventsBack: 6,
            eventsForward: 6,
            viewMonth: false,
            viewYear: false,
            currentSelectedDay: null,

            setStartDate: false
        }

        this.calendarRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        var weekdaysIos = this.state.weekdaysShort
        var weekdaysIsoShorter = []
        weekdaysIos.push(weekdaysIos.shift())
        weekdaysIos.forEach(day => {
            weekdaysIsoShorter.push(day.slice(0, -1))
        })
        this.setState({
            weekdaysShort: weekdaysIsoShorter,
            currentTz: moment.tz.guess()
        })

        this.getThisMonthEvents(moment())


        if (this.props.projectId && this.props.projectId !== 'none') {
            //console.log("getting events from: " +  moment().subtract(6, 'month').format("YYYY-MM-DD") + " to: " + moment().add(6, 'month').format("YYYY-MM-DD"))
            if (!this.props.projectEventsLoaded.includes(this.props.projectId) && !this.props.projectEventsLoading.includes(this.props.projectId)) {


                let filter = {
                    projectId: this.props.projectId,
                    eventId: -1,
                    startDate: moment(this.state.dateContext).subtract(6, 'month').format('YYYYMMDD HHmm'),
                    endDate: moment(this.state.dateContext).add(6, 'month').format('YYYYMMDD HHmm'),
                }
                //this.props.getProjectEvents(filter, this.props.projectId)
            }
        }


        const { startDate, selectedDay } = this.props

        if (startDate && moment(startDate).isValid() && !this.state.setStartDate) {
            this.setState({
                dateContext: moment(startDate),
                setStartDate: true
            })
        }

        if (selectedDay && selectedDay.value) {
            this.setState({
                currentSelectedDay: selectedDay.value
            })
        }
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        if ((this.props.projectId && prevProps.projectEvents.length === 0 && this.props.projectEvents.length !== 0) || (this.props.projectId && this.props.projectId !== prevProps.projectId)) {
            this.getThisMonthEvents(moment(this.state.dateContext))
        }

        if (this.props.events.length !== prevProps.events.length || this.props.events !== prevProps.events) {
            this.getThisMonthEvents(moment(this.state.dateContext))
        }

        const { startDate, selectedDay } = this.props
        const { currentSelectedDay } = this.state

        if (startDate && moment(startDate).isValid() && !this.state.setStartDate) {
            this.setState({
                dateContext: moment(startDate),
                setStartDate: true
            })
        }

        if (prevProps.selectedDay !== selectedDay) {
            if (selectedDay && selectedDay.value && currentSelectedDay !== selectedDay.value) {
                this.setState({
                    currentSelectedDay: selectedDay.value
                })
            } else if (selectedDay && !selectedDay.value && currentSelectedDay) {
                this.setState({
                    currentSelectedDay: null
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        if (this.props.sendDate) {
            this.props.sendDate(this.props.datePickerDay.display, this.props.datePickerDay.value)
        }
    }

    handleClick = (event) => {
        if (this.calendarRef && !this.calendarRef.current.contains(event.target)) {
            this.setState({
                viewMonth: false,
                viewYear: false
            })
        } else {
            if (this.state.viewMonth && !this.hasAncestor(event.target, 'selectMonthContainer')) {
                this.setState({
                    viewMonth: false
                })
            } else if (this.state.viewYear && !this.hasAncestor(event.target, 'selectYearContainer')) {
                this.setState({
                    viewYear: false
                })
            }
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }


    year = () => {
        return this.state.dateContext.format("Y");
    }
    month = () => {
        return this.state.dateContext.format("MMMM");
    }
    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    }

    daysInPrevMonth = (date) => {
        var prevMonth = moment(date).subtract(1, "month")
        return prevMonth.daysInMonth()
    }


    currentDate = () => {
        return this.state.dateContext.get("date");
    }
    currentDay = () => {
        return this.state.dateContext.format("D");
    }
    firstDayOfMonth = () => {
        var index = moment(this.state.dateContext).startOf('month').format('d')
        return index - 1 < 0 ? 6 : index - 1
    }

    nextMonth = () => {
        this.getThisMonthEvents(moment(this.state.dateContext).add(1, "month"))

        if (this.props.changeMonth) {
            this.props.changeMonth(moment(this.state.dateContext).add(1, "month"))
        }

        let monthDiff = moment(this.state.today).diff(moment(this.state.dateContext).add(1, "month"), 'months', true)

        if (Math.round(monthDiff * -1) > this.state.eventsForward - 3) {

            if (this.props.projectId && this.props.projectId !== 'none') {
                //console.log("getting events from: " +  moment(this.state.dateContext).add(3, 'month').format("YYYY-MM-DD") + " to: " + moment(this.state.dateContext).add(9, 'month').format("YYYY-MM-DD"))
                let filter = {
                    projectId: this.props.projectId,
                    eventId: -1,
                    startDate: moment(this.state.dateContext).add(3, 'month').format('YYYYMMDD HHmm'),
                    endDate: moment(this.state.dateContext).add(9, 'month').format('YYYYMMDD HHmm'),
                }

                this.props.getProjectEvents(filter, this.props.projectId)
            } else if (this.props.projectId !== 'none') {
                let filter = {
                    projectId: -1,
                    eventId: -1,
                    startDate: moment(this.state.dateContext).add(3, 'month').format('YYYYMMDD HHmm'),
                    endDate: moment(this.state.dateContext).add(9, 'month').format('YYYYMMDD HHmm'),

                }

                this.props.getUserEvents(filter)
            }

            this.setState({
                eventsForward: Math.round(monthDiff * -1) + 6
            })
        }

        const { dateContext } = this.state
        this.setState({
            dateContext: moment(dateContext).add(1, "month")
        })

        if (!this.props.dashboard && this.props.selectFirstDayOnMonthChange) {
            this.props.selectDay(moment(dateContext).add(1, "month").set('date', 1))
            this.setState({
                currentSelectedDay: moment(dateContext).add(1, "month").set('date', 1)
            })
        }

        if (this.props.projectId !== 'none') {
            this.props.setCurrentMonth(moment(this.state.dateContext).add(1, "month"))
        }
    }

    prevMonth = () => {
        this.getThisMonthEvents(moment(this.state.dateContext).subtract(1, "month"))
        if (this.props.changeMonth) {
            this.props.changeMonth(moment(this.state.dateContext).subtract(1, "month"))
        }
        let monthDiff = moment(this.state.today).diff(moment(this.state.dateContext).subtract(1, "month"), 'months', true)
        if (Math.round(monthDiff) > this.state.eventsBack - 3) {

            if (this.props.projectId && this.props.projectId !== 'none') {
                //console.log("getting events from: " +  moment(this.state.dateContext).subtract(3, 'month').format("YYYY-MM-DD") + " to: " + moment(this.state.dateContext).subtract(9, 'month').format("YYYY-MM-DD"))
                let filter = {
                    projectId: this.props.projectId,
                    eventId: -1,
                    startDate: moment(this.state.dateContext).subtract(3, 'month').format('YYYYMMDD HHmm'),
                    endDate: moment(this.state.dateContext).subtract(9, 'month').format('YYYYMMDD HHmm'),
                }
                this.props.getProjectEvents(filter, this.props.projectId)
            } else if (this.props.projectId !== 'none') {
                let filter = {
                    projectId: -1,
                    eventId: -1,
                    startDate: moment(this.state.dateContext).subtract(3, 'month').format('YYYYMMDD HHmm'),
                    endDate: moment(this.state.dateContext).subtract(9, 'month').format('YYYYMMDD HHmm'),
                }
                this.props.getUserEvents(filter)
            }

            this.setState({
                eventsBack: Math.round(monthDiff) + 6
            })
        }

        const { dateContext } = this.state
        this.setState({
            dateContext: moment(dateContext).subtract(1, "month"),
        })

        if (!this.props.dashboard && this.props.selectFirstDayOnMonthChange) {
            this.props.selectDay(moment(dateContext).subtract(1, "month").set('date', 1))
            this.setState({
                currentSelectedDay: moment(dateContext).subtract(1, "month").set('date', 1)
            })
        }

        if (this.props.projectId !== 'none') {
            this.props.setCurrentMonth(moment(this.state.dateContext).subtract(1, "month"))
        }
    }

    toggleMonth = monthNumber => {
        const { dateContext } = this.state
        this.setState({
            dateContext: moment(dateContext).set('month', monthNumber),
            viewMonth: false
        })
        if (!this.props.dashboard) {
            if (this.props.selectFirstDayOnMonthChange) {
                this.setState({
                    currentSelectedDay: moment(dateContext).set('month', monthNumber).set('date', 1)
                })
                this.props.selectDay(moment(dateContext).set('month', monthNumber).set('date', 1))
            }
        } else {
            this.props.changeMonth(moment(dateContext).set('month', monthNumber))
        }
    }

    toggleYear = yearNumber => {
        const { dateContext } = this.state
        this.setState({
            dateContext: moment(dateContext).set('year', yearNumber),
            viewYear: false
        })
        if (!this.props.dashboard) {
            if (this.props.selectFirstDayOnMonthChange) {
                this.props.selectDay(moment(dateContext).set('year', yearNumber).set('date', 1))
                this.setState({
                    currentSelectedDay: moment(dateContext).set('year', yearNumber).set('date', 1)
                })
            }
        } else {
            this.props.changeMonth(moment(dateContext).set('year', yearNumber))
        }
    }

    toggleDay = (i, month) => {
        var date = moment(this.state.dateContext)
        if (month) {
            if (month > 0) date = date.add(1, 'month')
            else date = date.subtract(1, 'month')
        }
        date.set("date", i);
        this.setState({
            currentSelectedDay: date,
            dateContext: date,
        })
        if (!this.props.dashboard) {
            if (this.props.selectedDay.display === date.format('LL')) {
                this.props.unselectDay()
            } else {
                this.props.selectDay(date)
            }
        } else {
            this.props.toogleDayForTooltipCalendar(date)
            if (this.state.currentSelectedDay && this.state.currentSelectedDay.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                this.setState({
                    currentSelectedDay: null,
                })
            }
        }
    }

    getThisMonthEvents = (dateContext) => {
        const { events, projectEvents, projectId, eventTooltipHeight } = this.props
        const currentMonth = moment(dateContext).format("YYYY-MM")
        if (!projectId) {
            var eventsThisMonth = []

            events.forEach(event => {
                if (currentMonth === moment(event.start_date).format("YYYY-MM")) {
                    eventsThisMonth.push({
                        date: moment(event.start_date).format("YYYY-MM-DD"),
                        hour: moment(event.start_date).format("HH:mm"),
                        name: event.name,
                        description: event.note
                    })
                }
            })
            this.setState({
                eventsThisMonth: eventsThisMonth,
            })
        } else if (projectEvents && projectId && projectId !== 'none') {
            var eventsThisMonth = []
            var tooltipArray = []
            var daysThisMonth = dateContext.daysInMonth();
            projectEvents.forEach(event => {
                if (event.project_id === projectId && currentMonth === moment(event.start_date).format("YYYY-MM")) {
                    eventsThisMonth.push({
                        date: moment(event.start_date).format("YYYY-MM-DD"),
                        hour: moment(event.start_date).utc().format("HH:mm"),
                        name: event.name,
                        description: event.note && event.note.length > 100 ? event.note.substring(0, 100) + '...' : event.note
                    })
                }
            })
            this.setState({
                eventsThisMonth: eventsThisMonth,
                eventsThisMonthTooltips: tooltipArray,
            })
        } else {
            return
        }
    }

    toggleViewMonth = () => {
        this.setState({
            viewMonth: !this.state.viewMonth
        })
    }

    toggleViewYear = () => {
        this.setState({
            viewYear: !this.state.viewYear
        })
    }

    render() {
        const { projectId, inactiveFuture, inactiveWeekends } = this.props
        const { dateContext, currentSelectedDay, eventsThisMonth, eventsThisMonthTooltips, today, todayDay, todayMonth, todayYear, viewMonth, viewYear } = this.state
        const currentMonth = this.month()
        const currentYear = this.year()

        let weekdays = this.state.weekdaysShort.map((day) => {
            return (
                <span className={`day-shortname`} key={day}>{this.context.t(day)}</span>
            )
        });

        let blanks = [];
        let blanksAfter = [];
        let daysInCurrentMonth = this.daysInMonth()
        let daysInPrevMonth = this.daysInPrevMonth(dateContext)
        let firstDayOfCurrMonth = this.firstDayOfMonth()

        for (let i = 1; i <= firstDayOfCurrMonth % 7; i++) {
            blanks.push(
                <span className="day-cell prev" key={"empty" + i} onClick={() => this.toggleDay(daysInPrevMonth - firstDayOfCurrMonth + i, -1)}>{daysInPrevMonth - firstDayOfCurrMonth + i}</span>
            );
        }

        for (let i = 1; i <= 42 - firstDayOfCurrMonth - daysInCurrentMonth; i++) {
            blanksAfter.push(
                <span className="day-cell next" key={"empty-next" + i} onClick={() => this.toggleDay(i, 1)}>{i}</span>
            );
        }

        let daysInMonth = [];
        for (let i = 1; i <= daysInCurrentMonth; i++) {
            if (eventsThisMonth && eventsThisMonth.filter(e => e.date === moment(dateContext).set("date", i).format('YYYY-MM-DD')).length > 0) {
                daysInMonth.push(
                    <span
                        data-tip
                        data-for={"day" + i + "project" + projectId}
                        key={"day" + i + currentMonth + currentYear}
                        className={`day-cell has-event ${currentSelectedDay && moment(dateContext).set("date", i).format('LL') === currentSelectedDay.format('LL') ? 'selected' : ''} ${parseInt(i.toString()) === parseInt(todayDay.toString()) && currentMonth.toString() === todayMonth.toString() && currentYear.toString() === todayYear.toString() ? 'today' : ''} ${this.props.dashboard ? 'cursor-normal' : ''} ${(inactiveWeekends && moment(dateContext).set("date", i).day() % 6 === 0) || (inactiveFuture && moment(dateContext).set("date", i) > moment()) ? 'inactive' : ''}`}
                        onClick={(e) => this.toggleDay(i)}
                    >
                        {i}
                    </span>
                );
            } else {
                daysInMonth.push(
                    <span
                        key={"day" + i + currentMonth + currentYear}
                        className={`day-cell ${currentSelectedDay && moment(dateContext).set("date", i).format('LL') === currentSelectedDay.format('LL') ? 'selected' : ''} ${parseInt(i.toString()) === parseInt(todayDay.toString()) && currentMonth.toString() === todayMonth.toString() && currentYear.toString() === todayYear.toString() ? 'today' : ''} ${this.props.dashboard ? 'cursor-normal' : ''} ${(inactiveWeekends && moment(dateContext).set("date", i).day() % 6 === 0) || (inactiveFuture && moment(dateContext).set("date", i) > moment()) ? 'inactive' : ''}`}
                        onClick={() => this.toggleDay(i)}
                    >
                        {i}
                    </span>
                );
            }
        }

        return (
            <div className="calendar-content" ref={this.calendarRef}>
                <div className="calendar-header">
                    {/* <div className={`small-calendar-nav ${arrowsOnLeft ? 'show' : ''}`}> */}
                    {/* </div> */}
                    <div className="prev-month" onClick={() => this.prevMonth()}></div>

                    <div className="calendar-select-date">
                        <span className="select-month" id="selectMonthContainer" onClick={() => this.toggleViewMonth()}>
                            <h3 onClick={() => this.toggleViewMonth()}>{this.context.t(this.month())}</h3>
                            <span className={`calendar-vector ${viewMonth ? 'show' : ''}`} onClick={() => this.toggleViewMonth()}></span>
                            {
                                viewMonth ? (
                                    <div className="month-list">
                                        <ul>
                                            <li onClick={() => this.toggleMonth(0)}>{this.context.t("January")}</li>
                                            <li onClick={() => this.toggleMonth(1)}>{this.context.t("February")}</li>
                                            <li onClick={() => this.toggleMonth(2)}>{this.context.t("March")}</li>
                                            <li onClick={() => this.toggleMonth(3)}>{this.context.t("April")}</li>
                                            <li onClick={() => this.toggleMonth(4)}>{this.context.t("May")}</li>
                                            <li onClick={() => this.toggleMonth(5)}>{this.context.t("June")}</li>
                                            <li onClick={() => this.toggleMonth(6)}>{this.context.t("July")}</li>
                                            <li onClick={() => this.toggleMonth(7)}>{this.context.t("August")}</li>
                                            <li onClick={() => this.toggleMonth(8)}>{this.context.t("September")}</li>
                                            <li onClick={() => this.toggleMonth(9)}>{this.context.t("October")}</li>
                                            <li onClick={() => this.toggleMonth(10)}>{this.context.t("November")}</li>
                                            <li onClick={() => this.toggleMonth(11)}>{this.context.t("December")}</li>
                                        </ul></div>
                                ) : null
                            }
                        </span>
                        {/* onClick={e => this.changeYear(e)} value={this.year()} */}
                        <span className="select-year" id="selectYearContainer" onClick={() => this.toggleViewYear()}>
                            <h3 onClick={() => this.toggleViewYear()}>{this.year()}</h3>
                            <span className={`calendar-vector ${viewYear ? 'show' : ''}`} onClick={() => this.toggleViewYear()}></span>
                            {
                                viewYear ? (
                                    <div className="year-list">
                                        <ul>
                                            <li onClick={() => this.toggleYear(2026)}>2026</li>
                                            <li onClick={() => this.toggleYear(2025)}>2025</li>
                                            <li onClick={() => this.toggleYear(2024)}>2024</li>
                                            <li onClick={() => this.toggleYear(2023)}>2023</li>
                                            <li onClick={() => this.toggleYear(2022)}>2022</li>
                                            <li onClick={() => this.toggleYear(2021)}>2021</li>
                                            <li onClick={() => this.toggleYear(2020)}>2020</li>
                                            <li onClick={() => this.toggleYear(2019)}>2019</li>
                                            <li onClick={() => this.toggleYear(2018)}>2018</li>
                                            <li onClick={() => this.toggleYear(2017)}>2017</li>
                                            <li onClick={() => this.toggleYear(2016)}>2016</li>
                                            <li onClick={() => this.toggleYear(2015)}>2015</li>
                                            <li onClick={() => this.toggleYear(2014)}>2014</li>
                                        </ul>
                                    </div>
                                ) : null
                            }
                        </span>
                    </div>

                    <div className="next-month" onClick={() => this.nextMonth()}></div>

                    {/* <div className={`calendar-nav ${arrowsOnLeft ? 'hide' : ''}`}>
                        <div className="prev-month" onClick={() => this.prevMonth()}></div>
                        <div className="next-month" onClick={() => this.nextMonth()}></div>
                    </div> */}
                </div>
                <div className="calendar-body">
                    <div className="weekdays">
                        {weekdays}
                    </div>
                    <div className="days">
                        {blanks}{daysInMonth}{blanksAfter}
                    </div>
                </div>
                {eventsThisMonthTooltips}
            </div>
        )
    }
}


Calendar.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    projectEvents: [...state.Event.events.filter(e => e.project_id === ownProps.projectId)],
    events: [...state.Event.events],
    projectEventsLoaded: [...state.Event.projectEventsLoaded],
    projectEventsLoading: [...state.Event.projectEventsLoading],
    datePickerDay: state.Event.datePickerDay,
})

const mapDispatchToProps = {
    getProjectEvents: EventActions.getProjectEvents,
    getUserEvents: EventActions.getEvents,
    setCurrentMonth: EventActions.setCurrentMonth,

    toogleDayForTooltipCalendar: UserActions.toogleDayForTooltipCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
