const notelProjects = [
    13,     //TP1
    573,    //Notel
    574,    //nowy
]

const notelLevel1 = [
    {
        "description": "Koszty ogólne",
        "code": "MPK1",
        "nextLevel": [
            {
                "description": "Koszty biura",
                "code": "MPK 1.1"
            },
            {
                "description": "Flota",
                "code": "MPK 1.2",
                "nextLevel": [
                    {
                        "description": "Leasing",
                        "code": "MPK 1.2.1"
                    },
                    {
                        "description": "Paliwo",
                        "code": "MPK 1.2.2"
                    },
                    {
                        "description": "Koszty serwisu",
                        "code": "MPK 1.2.3"
                    }
                ]
            },
            {
                "description": "Rachunki - media",
                "code": "MPK 1.3"
            },
            {
                "description": "Materiały biurowe",
                "code": "MPK 1.4"
            }
        ]
    }, , {
        "description": "Wynagrodzenia",
        "code": "MPK2"
    }, {
        "description": "Wydatki pozostałe",
        "code": "MPK3",
        "nextLevel": [
            {
                "description": "Wydatki bezfakturowe",
                "code": "MPK 3.1"
            },
            {
                "description": "Opłaty skarbowe",
                "code": "MPK 3.2"
            },
            {
                "description": "Podatki",
                "code": "MPK 3.3"
            },
            {
                "description": "Koszty delegacji",
                "code": "MPK 3.4"
            }
        ]
    }, {
        "description": "Budowa1",
        "code": "MPK10",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 4.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 4.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 4.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 4.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 4.4"
            }
        ]
    }, {
        "description": "Budowa2",
        "code": "MPK11",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 4.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 4.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 4.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 4.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 4.4"
            }
        ]
    }, {
        "description": "Budowa3",
        "code": "MPK12",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 4.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 4.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 4.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 4.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 4.4"
            }
        ]
    }
]

const notelLevel2 = [
    {
        "description": "Przygotowanie",
        "code": "Przygotowanie"
    },
    {
        "description": "Projektowanie",
        "code": "Projektowanie"
    },
    {
        "description": "Realizacja",
        "code": "Realizacja"
    }
]

const notelMpkSettings = {
    levelsCount: 3,
    hasDependentSublevels: true,
    level1List: notelLevel1,
    level2List: [],
    level3List: [],
    level4List: [],
}


module.exports = {
    notelProjects,
    notelMpkSettings
};