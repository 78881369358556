import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Dots } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import TagsComponent from './TagsComponent';
import Calendar from '../Calendar';
import parseDate from '../../helpers/dateParser'
import isIterable from "../../helpers/isIterable"

import '../../css/OcrPage.css'

import { FileActions } from '../../actions/FileActions';

const REQUIRED_FIELDS = ['issueDate', 'saleDate', 'buyerName', 'buyerVatId']
export class OcrPageProtocolForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showProtocolTypesList: false
        }

        this.protocolTypeWrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick)
    }

    handleClick = (event) => {
        if (this.protocolTypeWrapperRef && !this.protocolTypeWrapperRef.current.contains(event.target)) {
            this.setState({
                showProtocolTypesList: false
            })
        } else {
        }
    }

    getClassNameForOcrData = (propName, text, newCounterparty) => {
        const { dataReadWithOcrFrame } = this.props

        var classes = ''
        var hide = false
        var half = false

        if (propName === 'dueDate' || propName === 'issueDate' || propName === 'date' || propName === 'protocolType') {
            half = true
        }

        if (hide) classes += ' hide'
        if (half) classes += ' half'
        if (dataReadWithOcrFrame.includes(propName)) classes += ' finished-ocr-indication'
        if (REQUIRED_FIELDS.includes(propName) && !text) classes += ' required-field'

        return classes.trim()
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    getTitle = name => {
        if (name === 'employee') {
            return this.context.t('Employee')
        } else if (name === 'protocolType') {
            return this.context.t('Protocol type')
        } else if (name === 'date') {
            return this.context.t('Date')
        } else if (name === 'description') {
            return this.context.t('Description')
        }
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        const { counterpartiesList } = this.props
        var counterpartiesCount = counterpartiesList.length

        if (!text) {
            return false
        }

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true
            }
        }

        return false
    }

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false
        }

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true
        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true
        }

        return false
    }

    getInputValue = prop => {
        if (prop.name === 'protocolType') {
            if (prop.text === 'TRANSMISSION') return this.context.t('Transmission protocol')
            if (prop.text === 'ACCEOTANCE') return this.context.t('Acceptance protocol')

            return ''
        } else return prop.text
    }

    selectProtocolType = (fileId, type) => {
        this.props.selectProtocolType(fileId, type)
        this.setState({
            showProtocolTypesList: false
        })
    }

    render() {
        const { showProtocolTypesList } = this.state
        const { currentInvoiceImageData, returnedOCRData, currentProjectRole, calcHeight, focusedTextArea, posUpDatePicker, posYDatePicker, tags, showTagsList, tagsList, tagsCreationLimitation, dataConfirmCheckbox, user, counterpartiesList, showCounterpartiesList, processingData, documentDescription } = this.props

        return (
            <>
                <div className="by-hand-wrapper">
                    <div className="custome-simple-bar-wrapper" ref={this.props.setFormRef}>
                        <SimpleBar style={{ height: calcHeight, width: 'calc(100% + 20px)', marginLeft: '-10px', paddingLeft: '10px', marginRight: '-10px', paddingRight: '10px' }}>
                            {
                                (returnedOCRData?.protocolProps || []).map((prop, i) => {
                                    return (
                                        <div
                                            className={`ocr-data ${prop.name} ${this.getClassNameForOcrData(prop.name, prop.text, returnedOCRData.newCounterparty)}`} key={i}
                                            ref={prop.name === 'protocolType' ? this.protocolTypeWrapperRef : `${prop.name}PropRef`}
                                        >
                                            <label className={prop.name}>
                                                {this.getTitle(prop.name)}
                                                {
                                                    processingData && processingData.find(e => e === prop.name) ? (
                                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                    ) : null
                                                }
                                            </label>
                                            <TextareaAutosize
                                                key={`prop-field-${prop.name}`}
                                                id={`prop-field-${prop.name}`}
                                                className={`${prop.name} ${focusedTextArea === prop.name ? 'focused' : ''}`}
                                                value={this.getInputValue(prop)}
                                                onChange={(e) => this.props.onInputChangeProtocol(e, currentInvoiceImageData.dbId, prop.name)}
                                                onClick={e => this.props.showDatePicker(e, prop.name, prop.possition)}
                                                onFocus={e => {
                                                    if (prop.name === 'protocolType') {
                                                        this.setState({
                                                            showProtocolTypesList: true
                                                        })
                                                    }
                                                }}
                                                onBlur={e => {
                                                    this.props.focusOutAreaForProtocol(e, currentInvoiceImageData.dbId);
                                                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, prop.name, prop.text, 'protocol');
                                                }}
                                                name={prop.name}
                                                maxRows={10}
                                                readOnly={prop.name === 'protocolType'}
                                            />
                                            {
                                                prop.name === 'date' ? (
                                                    <span className="calendar-icon" onClick={(e) => this.props.showDatePicker(e, prop.name)}></span>
                                                ) : (
                                                    null
                                                )
                                            }
                                            {
                                                prop.name === 'date' && focusedTextArea === 'date' ? (
                                                    <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                        <Calendar
                                                            selectDay={this.props.onCalendarSelect}
                                                            unselectDay={this.props.onCalendarUnselecet}
                                                            selectedDay={
                                                                this.checkDate(prop.text) ? {
                                                                    display: moment(parseDate(prop.text), 'DD-MM-YYYY').format('LL'),
                                                                    value: moment(prop.text, 'DD-MM-YYYY')
                                                                } : {
                                                                    display: null,
                                                                    value: null
                                                                }
                                                            }
                                                            projectId={'none'}
                                                            startDate={this.checkDate(prop.text) ? moment(parseDate(prop.text), 'DD-MM-YYYY') : moment()}
                                                        />
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }
                                            {
                                                prop.name === 'protocolType' ? (
                                                    <div
                                                        className={`extend-icon ${showProtocolTypesList ? 'extended' : ''}`}
                                                        onClick={
                                                            e => this.setState({ showProtocolTypesList: !showProtocolTypesList })
                                                        }
                                                    ></div>
                                                ) : null
                                            }
                                            {
                                                prop.name === 'protocolType' && showProtocolTypesList ? (
                                                    <div className="counterparties-list">
                                                        <div className="header">
                                                            {this.context.t('Select protocol type:')}
                                                        </div>
                                                        <ul>
                                                            <li
                                                                tabIndex={"0"}
                                                                className={`item counterparty-item`}
                                                                onClick={() => this.selectProtocolType(currentInvoiceImageData.dbId, 'TRANSMISSION')}
                                                            >{this.context.t('Transmission protocol')}</li>
                                                            <li
                                                                tabIndex={"0"}
                                                                className={`item counterparty-item`}
                                                                onClick={() => this.selectProtocolType(currentInvoiceImageData.dbId, 'ACCEOTANCE')}
                                                            >{this.context.t('Acceptance protocol')}</li>
                                                        </ul>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    )
                                })
                            }
                             <div className={`ocr-data`}>
                                        <label>{this.context.t('Document description')}</label>
                                        <TextareaAutosize
                                            className={``}
                                            value={documentDescription}
                                            name='doc_description'
                                            onChange={(e) => this.props.setDocComment(e)}
                                            maxRows={10}
                                            onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                        />
                            </div>
                            <TagsComponent
                                currentTags={tags}
                                tagsList={tagsList}
                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                setTags={this.props.setTags}
                                maxHeight={190}
                            />
                        </SimpleBar>
                    </div>

                    <div className="action-buttons" ref={this.props.setButtonsRef}>
                        {
                            currentProjectRole !== 'USER1' ? (
                                <div className="checkmark" onClick={() => this.props.confirmData()}>
                                    {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                                    <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        {
                            currentProjectRole === 'USER1' ? (
                                <button type="button" className={`button`} onClick={e => this.props.savePartialProtocol()}>{this.context.t("Send for approval")}</button>
                            ) : (
                                <button type="button" className={`button`} onClick={() => this.props.saveProtocol(true)}>{(currentProjectRole === 'USER2' || currentProjectRole === 'USER3') ? this.context.t("Accept and Save") : this.context.t("Send for approval")}</button>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

OcrPageProtocolForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,

    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: isIterable(state.File.processingData) ? [...state.File.processingData] : [],
    processedData: isIterable(state.File.processedData) ? [...state.File.processedData] : [],
    dataReadWithOcrFrame: isIterable(state.File.dataReadWithOcrFrame) ? [...state.File.dataReadWithOcrFrame] : []
})

const mapDispatchToProps = {
    autoSaveOcr: FileActions.autoSaveOcr,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageProtocolForm)