import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import { AlertActions } from '../../../actions/AlertActions'

const AVAILABLE_DEDLINES = [
    {
        id: '1d',
        label: '1 day after the end of the previous stage',
        uploadLabel: '1 day after document upload',
    },
    {
        id: '2d',
        label: '2 days after the end of the previous stage',
        uploadLabel: '2 days after document upload',
    },
    {
        id: '3d',
        label: '3 days after the end of the previous stage',
        uploadLabel: '3 days after document upload',
    },
    {
        id: '5d',
        label: '4 days after the end of the previous stage',
        uploadLabel: '5 days after document upload',
    },
    {
        id: '7d',
        label: '7 days after the end of the previous stage',
        uploadLabel: '7 days after document upload',
    },
    {
        id: '10d',
        label: '10 days after the end of the previous stage',
        uploadLabel: '10 days after document upload',
    },
    {
        id: '14d',
        label: '14 days after the end of the previous stage',
        uploadLabel: '14 days after document upload',
    }
]

const COSTS_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'paymentStatus',
        label: 'Payment status'
    },
    {
        id: 'paymentType',
        label: 'Payment type'
    },
    {
        id: 'priority',
        label: 'Priority'
    },
    {
        id: 'sellerVatId',
        label: 'Seller VatID'
    },
    {
        id: 'sellerName',
        label: 'Seller name'
    },
    {
        id: 'buyerVatId',
        label: 'Buyer VatID'
    },
    {
        id: 'buyerName',
        label: 'Buyer name'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'saleDate',
        label: 'Sale date'
    },
    {
        id: 'DueDate',
        label: 'Due date'
    },
    {
        id: 'netAmount',
        label: 'Net amount'
    },
    {
        id: 'grossAmount',
        label: 'Gross amount'
    },
    {
        id: 'amountToPay',
        label: 'Amount to pay'
    },
    {
        id: 'currency',
        label: 'Currency',
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const EXPENSES_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'priority',
        label: 'Priority'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'amountToPay',
        label: 'Amount to pay'
    },
    {
        id: 'currency',
        label: 'Currency',
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const OTHER_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const AGREEMENT_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    }
]

const CORRESPONDENCE_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'title',
        label: 'Title'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    }
]

const CORPORATE_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const PROTOCOL_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'employee',
        label: 'Employee'
    },
    {
        id: 'protocolType',
        label: 'Protocol type'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

export class DocflowStageEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            itemName: '',
            editingItemName: false,
            selectedUsers: [],
            showUsersList: false,
            requiredUsersCount: 1,
            itemDeadlineId: '',
            itemDeadlineLabel: '',
            canSelectRequiredFields: true,
            requiredFields: [],
            availableFields: [],
            itemDescription: '',
            notifyAboutOvberdue: false,
            canBeRevoked: false,
            canBeSkipped: false,
        }

        this.settingsWindowWrapperRef = React.createRef()
        this.usersWrapperRef = React.createRef()
        this.deadlineWrapperRef = React.createRef()
        this.requiredFieldsWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { docflowDocTypes, editedDocflowItem } = this.props
        this.setState({
            ...editedDocflowItem,
            itemName: this.context.t(editedDocflowItem.title),
        })
        if (editedDocflowItem.itemType === 'verification') {
            if (docflowDocTypes.length === 1) {
                var availableFields = []
                switch (docflowDocTypes[0]?.docType) {
                    case 'COST':
                        availableFields = [...COSTS_AVAILABLE_FIELDS]
                        break;
                    case 'EXPENSE':
                        availableFields = [...EXPENSES_AVAILABLE_FIELDS]
                        break;
                    case 'AGREEMENT':
                        availableFields = [...AGREEMENT_AVAILABLE_FIELDS]
                        break;
                    case 'CORRESPONDENCE':
                        availableFields = [...CORRESPONDENCE_AVAILABLE_FIELDS]
                        break;
                    case 'CORPORATE':
                        availableFields = [...CORPORATE_AVAILABLE_FIELDS]
                        break;
                    case 'OTHER':
                        availableFields = [...OTHER_AVAILABLE_FIELDS]
                        break;
                    case 'PROTOCOL':
                        availableFields = [...PROTOCOL_AVAILABLE_FIELDS]
                        break;
                    default:
                        availableFields = [{
                            id: 'all',
                            label: 'All fields'
                        }]
                }

                console.log(availableFields)

                this.setState({
                    availableFields: availableFields
                })
            } else {
                this.setState({
                    canSelectRequiredFields: false,
                    requiredFields: [{
                        id: 'all',
                        label: 'All fields'
                    }]
                })
            }
        }
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        // if (this.settingsWindowWrapperRef && !this.settingsWindowWrapperRef?.current?.contains(event.target)) {
        //     this.props.close()
        // } else
        if (this.state.showUsersList && this.usersWrapperRef && !this.usersWrapperRef.current.contains(event.target)) {
            this.setState({
                showUsersList: false
            })
        } else if (this.state.showDeadlinesList && this.deadlineWrapperRef && !this.deadlineWrapperRef.current.contains(event.target)) {
            this.setState({
                showDeadlinesList: false
            })
        } else if (this.state.showRequiredFieldsList && this.requiredFieldsWrapperRef && !this.requiredFieldsWrapperRef.current.contains(event.target)) {
            this.setState({
                showRequiredFieldsList: false
            })
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleUsersList = () => {
        const { showUsersList } = this.state
        this.setState({
            showUsersList: !showUsersList
        })
    }

    selectDocflowUser = user => {
        const { selectedUsers } = this.state
        var tempSelectedUsers = [...selectedUsers]

        if (tempSelectedUsers.find(u => u.id === user.id)) {
            tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)
        } else {
            tempSelectedUsers.push(user)
        }


        this.setState({
            selectedUsers: tempSelectedUsers
        })
    }

    removeSelectedUser = user => {
        const { selectedUsers } = this.state
        var tempSelectedUsers = [...selectedUsers]

        tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)

        this.setState({
            selectedUsers: tempSelectedUsers,
            showUsersList: false
        })
    }

    toggleDeadlinesList = () => {
        const { showDeadlinesList } = this.state
        this.setState({
            showDeadlinesList: !showDeadlinesList
        })
    }

    selectDeadline = deadline => {
        const { editedDocflowItem } = this.props

        this.setState({
            itemDeadlineId: deadline.id,
            itemDeadlineLabel: editedDocflowItem.index === 0 ? deadline.uploadLabel : deadline.label,
            showDeadlinesList: false
        })
    }

    toggleRequiredFieldsList = () => {
        const { showRequiredFieldsList, canSelectRequiredFields } = this.state
        this.setState({
            showRequiredFieldsList: canSelectRequiredFields && !showRequiredFieldsList
        })
    }

    selectRequiredField = field => {
        const { requiredFields, availableFields } = this.state
        var tempRequiredFields = [...requiredFields]

        if (field.id === 'all') {
            tempRequiredFields = [...availableFields]
            tempRequiredFields = tempRequiredFields.filter(f => f.id !== 'all')
        } else {
            if (tempRequiredFields.find(f => f.id === field.id)) {
                tempRequiredFields = tempRequiredFields.filter(f => f.id !== field.id)
            } else {
                tempRequiredFields.push(field)
            }
        }


        this.setState({
            requiredFields: tempRequiredFields
        })
    }

    removeSelectedRequiredField = fieldId => {
        const { requiredFields } = this.state
        var tempRequiredFields = [...requiredFields]

        tempRequiredFields = tempRequiredFields.filter(f => f.id !== fieldId)

        this.setState({
            requiredFields: tempRequiredFields,
            showRequiredFieldsList: false
        })
    }

    saveItem = () => {
        const {
            itemName, selectedUsers, itemDeadlineId, itemDeadlineLabel, requiredFields, availableFields, canSelectRequiredFields, itemDescription,
            notifyAboutOvberdue, canBeRevoked, canBeSkipped
        } = this.state
        const { editedDocflowItem } = this.props
        var isValid = true

        if (selectedUsers.length === 0) {
            isValid = false
            this.props.alertWarn('Add users responsible for the stage')
        }

        if (editedDocflowItem.itemType === 'verification' && requiredFields.length === 0) {
            isValid = false
            this.props.alertWarn('Add required fields for the stage')
        }

        if (isValid) {
            var tempDocflowItem = { ...editedDocflowItem }
            tempDocflowItem.title = itemName
            tempDocflowItem.selectedUsers = selectedUsers
            tempDocflowItem.itemDeadlineId = itemDeadlineId
            tempDocflowItem.itemDeadlineLabel = itemDeadlineLabel
            tempDocflowItem.requiredFields = requiredFields
            tempDocflowItem.itemDescription = itemDescription
            tempDocflowItem.notifyAboutOvberdue = notifyAboutOvberdue
            tempDocflowItem.canBeRevoked = canBeRevoked
            tempDocflowItem.canBeSkipped = canBeSkipped

            this.props.save(tempDocflowItem)
        }
    }

    render() {
        const { availableDocflowUsers, editedDocflowItem } = this.props
        const {
            itemName, selectedUsers, itemDeadlineId, itemDeadlineLabel, requiredFields, availableFields, canSelectRequiredFields, itemDescription,
            notifyAboutOvberdue, canBeRevoked, canBeSkipped,
            editingItemName, showUsersList, showDeadlinesList, showRequiredFieldsList
        } = this.state

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow" ref={this.settingsWindowWrapperRef}>
                    <h4>
                        {
                            editingItemName ? (
                                <>
                                    <input type="text" name="itemName" value={itemName} onChange={this.onInputChange} autoComplete="off" />
                                    <div className="icon accept-button" onClick={() => this.setState({ editingItemName: false })}></div>
                                </>
                            ) : (
                                <>
                                    <div>{itemName}</div>
                                    <div className="icon edit-button" onClick={() => this.setState({ editingItemName: true })}></div>
                                </>
                            )
                        }
                    </h4>
                    <h6>{this.context.t('Stage settings')}</h6>
                    <div className="settings-section stage-settings">
                        <div className={`form-group ${showUsersList ? 'in-front' : ''}`} ref={this.usersWrapperRef}>
                            <div className="selected-elements">
                                {
                                    selectedUsers.map(user => {
                                        return <div className="element">
                                            {user.name}
                                            <span className="remove" onClick={() => this.removeSelectedUser(user.id)}></span>
                                        </div>
                                    })
                                }
                            </div>
                            <label htmlFor="docflowDocTypes" onClick={this.toggleUsersList}>{this.context.t('Users/s responsible for the stage')}</label>
                            <span className={`expend-icon ${showUsersList ? 'expended' : ''}`} onClick={this.toggleUsersList}></span>

                            {
                                showUsersList ? (
                                    <ul>
                                        {
                                            editedDocflowItem.itemType === 'verification' ? (
                                                <li className={`${selectedUsers.includes(u => u.id === 0) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser({
                                                    id: 0,
                                                    name: this.context.t('User who added the document')
                                                })}>
                                                    {this.context.t('User who added the document')}
                                                </li>
                                            ) : null
                                        }
                                        {
                                            availableDocflowUsers.map(user => {
                                                return (
                                                    <li className={`${selectedUsers.includes(u => u.id === user.id) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser(user)}>
                                                        {user.name}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>

                        <div className={`form-group ${showDeadlinesList ? 'in-front' : ''}`} ref={this.deadlineWrapperRef}>
                            <input type="text" name="itemDeadlineLabel" id="itemDeadlineLabel" value={this.context.t(itemDeadlineLabel)} onClick={this.toggleDeadlinesList} autoComplete="off" />
                            <label htmlFor="itemDeadlineLabel" onClick={this.toggleDeadlinesList}>{this.context.t('Stage deadline')}</label>
                            <span className={`expend-icon ${showDeadlinesList ? 'expended' : ''}`} onClick={this.toggleDeadlinesList}></span>

                            {
                                showDeadlinesList ? (
                                    <ul>
                                        {
                                            AVAILABLE_DEDLINES.map(d => {
                                                return (
                                                    <li className={`${itemDeadlineId === d.id ? 'selected' : ''}`} onClick={() => this.selectDeadline(d)}>
                                                        {
                                                            editedDocflowItem.index === 0 ? (
                                                                this.context.t(d.uploadLabel)
                                                            ) : (
                                                                this.context.t(d.label)
                                                            )
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>

                        {
                            editedDocflowItem.itemType === 'verification' ? (
                                <div className={`form-group ${showRequiredFieldsList ? 'in-front' : ''}`} ref={this.requiredFieldsWrapperRef}>
                                    <div className="selected-elements">
                                        {
                                            requiredFields.map(field => {
                                                return <div className="element">
                                                    {this.context.t(field.label)}
                                                    {
                                                        canSelectRequiredFields ? (
                                                            <span className="remove" onClick={() => this.removeSelectedRequiredField(field.id)}></span>
                                                        ) : null
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    <label htmlFor="docflowDocTypes" onClick={this.toggleRequiredFieldsList}>{this.context.t('Document fields required in this stage')}</label>
                                    {
                                        canSelectRequiredFields ? (
                                            <span className={`expend-icon ${showRequiredFieldsList ? 'expended' : ''}`} onClick={this.toggleRequiredFieldsList}></span>
                                        ) : null
                                    }

                                    {
                                        showRequiredFieldsList ? (
                                            <ul>
                                                {
                                                    availableFields.map(field => {
                                                        return (
                                                            <li className={`${requiredFields.includes(f => f.id === field.id) ? 'selected' : ''}`} onClick={() => this.selectRequiredField(field)}>
                                                                {this.context.t(field.label)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : null
                                    }
                                </div>
                            ) : null
                        }

                        <div className="form-group">
                            <TextareaAutosize type="text" name="itemDescription" id="itemDescription" value={itemDescription} onChange={this.onInputChange} autoComplete="off" />
                            <label htmlFor="itemDescription">{this.context.t('Stage description')}</label>
                        </div>

                        <div className="checkbox-group first">
                            <div className={`checkbox ${notifyAboutOvberdue ? 'checked' : ''}`} onClick={() => this.setState({ notifyAboutOvberdue: !notifyAboutOvberdue })}></div>
                            <div className="label" onClick={() => this.setState({ notifyAboutOvberdue: !notifyAboutOvberdue })}>{this.context.t("Notify about delay")}</div>
                        </div>

                        <div className="checkbox-group">
                            <div className={`checkbox ${canBeRevoked ? 'checked' : ''}`} onClick={() => this.setState({ canBeRevoked: !canBeRevoked })}></div>
                            <div className="label" onClick={() => this.setState({ canBeRevoked: !canBeRevoked })}>{this.context.t("Stage can be rejected")}</div>
                        </div>

                        <div className="checkbox-group">
                            <div className={`checkbox ${canBeSkipped ? 'checked' : ''}`} onClick={() => this.setState({ canBeSkipped: !canBeSkipped })}></div>
                            <div className="label" onClick={() => this.setState({ canBeSkipped: !canBeSkipped })}>{this.context.t("Stage must be completed before the next one can begin")}</div>
                        </div>

                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className="button save" onClick={() => this.saveItem()}>
                                {this.context.t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowStageEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowStageEditWindow)